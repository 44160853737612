import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import GenerateReports from "../components/generateReports";
import { generateReport } from "../services/generateReportSlice";
import { GENERATEREPORTDT } from "../services/types";

interface StateProps {
  loading_generateReport: boolean;
  success_generateReport: boolean;
  error_generateReport: boolean;
  generateReportDetails: null | string;
}

interface DispatchProps {
  GenerateReportRequest(body: GENERATEREPORTDT): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error_generateReport: state.generateReport.error_generateReport,
    loading_generateReport: state.generateReport.loading_generateReport,
    success_generateReport: state.generateReport.success_generateReport,
    generateReportDetails: state.generateReport.generateReportDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GenerateReportRequest: (data) => {
      dispatch(generateReport(data));
    },
  };
};
export interface GenerateReportsProps extends StateProps, DispatchProps {}
export const GenerateReportsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateReports);
