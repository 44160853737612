export const RoleAccess =  {
    "staff": {
      "csvUpload_Staff": false,
      "Create_New_Staff": false,
      "Update_Staff_Detail": false,
      "Get_Staff_Detail_ById": false,
      "Get_Staff_Detail_By_Group_Id": false
    },
    "auth":{
      "Login":false,
      "Refresh_Token":false,
      "Logout":false
    },
    "User":{
      "Get_All_Users_Detail":false,
      "Create_New_User":false,
      "Get_User_Detail_By_Id":false,
      "UpdateUserDetail":false,
      "Change_Password":false,
      "MapUserRole":false,
      "Remove_User_Role":false
    },
    "Role":{
      "Create_New_Role":false,
      "Update_Role_Detail":false,
      "Get_Role_Detail":false,
      "Get_All_Role_Details":false,
      "Get_All_Role_Route_Mapping":false,
      "Get_All_Role_Route_Mapping_By_Role_Id":false
    },
    "Route":{
      "Refresh_Routes":false,
      "Get_All_Routes":false
    },
    "Profile":{
      "MyProfile_Details":false
    },
    "Bank":{
      "Create_New_Bank":true,
      "Update_Bank_Detail":false,
      "Get_Bank_Detail_By_Id":false,
      "Get_All_Bank_Details":false
    },
    "Group":{
      "Create_New_Group":false,
      "Update_Group_Detail":false,
      "Get_Group_Detail_By_Id":false,
      "Get_All_Group_Details":false
    },
    "OperationCatagory":{
      "Create_New_Operation_Catagory":false,
      "Update_Operation_Catagory_Detail":false,
      "Get_Operation_Catagory_Detail_By_Id":false,
      "Get_All_Operation_Catagory":false
      
    },
    "Operation":{
      "Get_All_Operation":false,
      "Get_Operation_Detail_By_Id":false,
      "Create_New_Operation":false,
      "Update_Operation_Detail":false
    },
    "Material":{
      "Create_New_Material":false,
      "Update_Material_Detail":false,
      "Get_Material_Detail_By_Id":false,
      "Get_All_Material_Details":false
    },
    "Shift":{
      "Create_New_Shift":false,
      "Update_Shift_Detail":false,
      "Revise_Shift_Detail":false,
      "Revise_Edit/Update_Shift_Detail":false,
      "Get_Shift_Detail_By_Id":false,
      "Get_All_Shift":false,
      "Get_All_Shift_Details":false
    },
    "Holiday":{
      "Create_New_Holiday":false,
      "Update_Holiday_Detail":false,
      "Get_Holiday_Detail_By_Id":false,
      "Get_All_Holiday_Details":false
    },
    "HolidayConfig":{
      "Create_New_Holiday_Configuration":false,
      "Update_Holiday_Configuration_Detail":false,
      "Get_Holiday_Configuration_Detail_By_Id":false,
      "Get_All_Holiday_Configuration_Details":false
    },
    "Incentive":{
      "Create_New_Incentive":false,
      "Update_Incentive_Detail":false,
      "Get_Incentive_Detail_By_Id":false,
      "Get_All_Incentive_Details":false
    },
    "WageComponent":{
      "Create_New_Wage_Component":false,
      "Update_Wage_Component_Detail":false,
      "Get_Wage_Component_DetailById":false,
      "Get_All_Wage_Component_Details":false
    },
    "Create_New_Wage_EPF":{
      "Create_New_Wage_EPF":false,
      "Update_Wage_EPF":false,
      "Get_Wage_EPF_Detail_BYId":false,
      "Get_All_Wage_EPF_Details":false
    },
    "WageESI":{
      "Create_New_Wage_ESI":false,
      "Update_Wage_ESI_Detail":false,
      "Get_Wage_ESI_Detail_ById":false,
      "Get_All_Wage_ESI_Details":false
    },
    "get_all_staff":{
      "Create_New_Worklog":false,
      "Update_Worklog_Detail":false,
      "Get_Worklog_Detail_ById":false,
      "Get_Worklog_Shift_Detail":false,
      "Get_All_Worklog_Details":false,
      "Change_Worklog_Status":false
    },
    "Attendance":{
      "Create_New_Attendance":false,
      "Update_Attendance_Detail":false,
      "Get_Attendance_Detail_ById":false,
      "Delete_Attendance_Detail":false,
      "Get_All_Attendance_Details":false,
      "Get_All_Shift_Attendance":false,
      "Get_All_Present_Attendance":false
    },
    "Report":{
      "GetAllAttendanceReport":false,
      "getAllEsiReports":false,
      "getAllEpfReports":false,
      "GetAllBankReport":false,
      "GetAllSalarySlipReport":false,
      "GetAllMusterReport":false
    }
  }
