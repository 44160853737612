import { RoleFunDTO } from "pages/auth/signin/service/types";
import { getRole } from "pages/auth/signin/service/userSlice";
import { connect } from "react-redux";
import { TypedDispatch } from "../../../../redux/store";
import Attendance from "../component/staffAttendance";
import {
  fetchAllAttendance,
  fetchAllAttendanceById,
  fetchAllGroupShift,
  fetchAllGroups,
  fetchAllShift,
  fetchAllStaff,
  fetchCreateAttendance,
  fetchDeleteAttendance,
  fetchUpdateAttendance,
  generateOffDay,
} from "../service/attendanceSlice";
import {
  AllStaffDetailsDTO,
  AttedanceListDTO,
  AttendanceDetailsresponseDTO,
  CreateAttendanceDTO,
  FilteredDataRequestedDTO,
  GETGROUPSHIFTDTO,
  GetAllGroupResponse,
  GetAllStaffRequestDTO,
  GetElementByIdRequest,
  UpdateAttendanceRequestDTO,
} from "../service/types";

interface StateProps {
  error_getAttendance: string | boolean | undefined;
  loading_getAttendance: boolean;
  success_getAttendance: boolean;
  loading_getAttendanceById: boolean;
  success_getAttendanceById: boolean;
  error_getAttendanceById: string | boolean | undefined;
  error_createAttendance: string | boolean | undefined;
  loading_createAttendance: boolean;
  success_createAttendance: boolean;
  error_updateAttendance: string | boolean | undefined;
  loading_updateAttendance: boolean;
  success_updateAttendance: boolean;
  error_deleteAttendance: string | boolean | undefined;
  loading_deleteAttendance: boolean;
  success_deleteAttendance: boolean;
  loading_getGroup: boolean;
  success_getGroup: boolean;
  error_getGroup: string | boolean | undefined;
  loading_getShift: boolean;
  success_getShift: boolean;
  error_getShift: string | boolean | undefined;
  loading_getStaff: boolean;
  success_getStaff: boolean;
  error_getStaff: string | boolean | undefined;
  loading_getGroupShift: boolean;
  success_getGroupShift: boolean;
  error_getGroupShift: string | boolean | undefined;
  attendanceList: AttedanceListDTO[];
  attendanceDetails: AttendanceDetailsresponseDTO;
  allGroupShifts: GetAllGroupResponse;
  allStaffList: AllStaffDetailsDTO;
  role_function: RoleFunDTO | undefined;
  loading_generateOffDay: boolean;
  success_generateOffDay: boolean;
  error_generateOffDay: boolean;
  details_generateOffDay: [];
}

interface DispatchProps {
  getAllAttendance(data: FilteredDataRequestedDTO): void;
  getAttendanceById(id: GetElementByIdRequest): void;
  createAllAttendance(data: CreateAttendanceDTO): void;
  updateAttendance(data: UpdateAttendanceRequestDTO): void;
  deleteAttendance(id: number): void;
  getAllGroups(): void;
  getAllStaff(id: GetAllStaffRequestDTO): void;
  getAllShift(): void;
  getAllGroupsShiftByDt(value: GETGROUPSHIFTDTO): void;
  getRoleFuncn(): void;
  GenerateOffDay(data: string | {}): void;
}

const mapStateToProps = (state: any): StateProps => {
  return {
    error_getGroup: state.groups.error_getGroup,
    loading_getGroup: state.groups.loading_getGroup,
    success_getGroup: state.groups.success_getGroup,
    error_getShift: state.shift.loading_getShift,
    loading_getShift: state.shift.loading_getShift,
    success_getShift: state.shift.success_getShift,
    error_getAttendance: state.attendance.error_getAttendance,
    loading_getAttendance: state.attendance.loading_getAttendance,
    success_getAttendance: state.attendance.success_getAttendance,
    loading_getAttendanceById: state.attendance.loading_getAttendanceById,
    success_getAttendanceById: state.attendance.success_getAttendanceById,
    error_getAttendanceById: state.attendance.error_getAttendanceById,
    attendanceDetails: state.attendance.attendanceDetails,
    attendanceList: state.attendance.attendanceList,
    loading_createAttendance: state.attendance.loading_createAttendance,
    error_createAttendance: state.attendance.error_createAttendance,
    success_createAttendance: state.attendance.success_createAttendance,
    loading_updateAttendance: state.attendance.loading_updateAttendance,
    error_updateAttendance: state.attendance.error_updateAttendance,
    success_updateAttendance: state.attendance.success_updateAttendance,
    loading_deleteAttendance: state.attendance.loading_deleteAttendance,
    error_deleteAttendance: state.attendance.error_deleteAttendance,
    success_deleteAttendance: state.attendance.success_deleteAttendance,
    loading_getGroupShift: state.attendance.loading_getGroupShift,
    success_getGroupShift: state.attendance.success_getGroupShift,
    error_getGroupShift: state.attendance.error_getGroupShift,
    allGroupShifts: state.attendance.allGroupShifts,
    error_getStaff: state.attendance.error_getStaff,
    loading_getStaff: state.attendance.loading_getStaff,
    success_getStaff: state.attendance.success_getStaff,
    allStaffList: state.attendance.allStaffList,
    role_function: state.userLogin.role_function,
    loading_generateOffDay: state.attendance.loading_generateOffDay,
    success_generateOffDay: state.attendance.success_generateOffDay,
    error_generateOffDay: state.attendance.error_generateOffDay,
    details_generateOffDay: state.attendance.details_generateOffDay,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    getAllAttendance: (data) => {
      dispatch(fetchAllAttendance(data));
    },

    getAttendanceById: (id) => {
      dispatch(fetchAllAttendanceById(id));
    },

    createAllAttendance: (data) => {
      dispatch(fetchCreateAttendance(data));
    },
    updateAttendance: (data) => {
      dispatch(fetchUpdateAttendance(data));
    },
    deleteAttendance: (id) => {
      dispatch(fetchDeleteAttendance(id));
    },
    getAllGroups: () => {
      dispatch(fetchAllGroups());
    },
    getAllStaff: (id) => {
      dispatch(fetchAllStaff(id));
    },
    getAllShift: () => {
      dispatch(fetchAllShift());
    },
    getAllGroupsShiftByDt: (value) => {
      dispatch(fetchAllGroupShift(value));
    },
    getRoleFuncn: () => {
      dispatch(getRole());
    },
    GenerateOffDay(data) {
      dispatch(generateOffDay(data));
    },
  };
};

export interface AttendanceProps extends StateProps, DispatchProps {}
export const AttendanceComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Attendance);
