import strings from "translation";

// export const ATTENDANCELABEL = strings["ATTENDANCE.REPORT.LABEL"];
export const HOMEPATH = strings["ATTENDANCE.REPORT.HOMEPATH"];
export const CURRENTPATH = strings["GENERATE.REPORT.CURRENTPATH"];

// export const GENERATEREPORT = strings["ATTENDANCE.REPORT.GENERATE"];
// export const CLOSE = strings["ATTENDANCE.REPORT.CLOSE"];

// export const EMPLOYEE = strings["ATTENDANCE.REPORT.TABLE.EMPLOYEE"];
// export const UNION = strings["ATTENDANCE.REPORT.TABLE.UNION"];
// export const TTL = strings["ATTENDANCE.REPORT.TABLE.TTL"];
// export const OFF = strings["ATTENDANCE.REPORT.TABLE.OFF"];
// export const NODATA = strings["ATTENDANCE.REPORT.TABLE.NODATA"];
