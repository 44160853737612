import strings from "translation";

export const HOMEPATHLABEL = strings["HEADER.WORKLOG.HOMEPATHLABEL"];
export const CURRENTPATH = strings["HEADER.WORKLOG.CURRENTPATH"];

export const PAGEHEADING = strings["HEADER.WORKLOG.HEADING"];

export const TBLHEAD = strings["HEADER.WORKLOG.TBLHEAD"];
export const DATEBTNLABEL = strings["HEADER.WORKLOG.DATEBTNLABEL"];
export const SHIFTBTNLABEL = strings["HEADER.WORKLOG.SHIFTBTNLABEL"];
export const GROUPBTNLABEL = strings["HEADER.WORKLOG.GROUPBTNLABEL"];

export const TBLGANGLABEL = strings["HEADER.WORKLOG.TBLGANGLABEL"];
export const TBLBONUSLABEL = strings["HEADER.WORKLOG.TBLBONUSLABEL"];
export const TBLPARTIALPAYLABEL = strings["HEADER.WORKLOG.TBLPARTIALPAYLABEL"];
export const TBLOPERATIONLABEL = strings["HEADER.WORKLOG.TBLOPERATIONLABEL"];

export const SWALSUCEETITLE = strings["ADD.SUCEESS.TITLE"];
export const SWALSUCEETEXT = strings["ADD.SUCEESS.TEXT"];
export const SWALUPDATESUCEESTITLE = strings["UPDATE.SUCEESS.TITLE"];
export const SWALUPDATESUCEESTEXT = strings["UPDATE.SUCEESS.TEXT"];
export const SWALDRAFTSUCEESTITLE = strings["DRAFT.SUCEESS.TITLE"];
export const SWALDRAFTSUCEESTEXT = strings["DRAFTSUCEESS.TEXT"];

export const SWALSTATUSSUCEESTITLE = strings["STATUS.SUCEESS.TITLE"];
export const SWALSTATUSUCEESTEXT = strings["STATUSESUCEESS.TEXT"];

export const STATUSCHANGEHEAD = strings["STATUS.CHANGE.PERMISSION.HEADING"];
export const STATUSCHANGESUBHEAD = strings["STATUS.CHANGE.PERMISSION.SUBHEADING"];
export const STATUSCHANGEPERMISSIONYES = strings["STATUS.CHANGE.PERMISSION.YES"];
export const STATUSCHANGEPERMISSIONNO = strings["STATUS.CHANGE.PERMISSION.NO"];

export const SAVEBTNLABEL = strings["HEADER.WORKLOG.SAVEBTN"];
export const UPDATEBTNLABEL = strings["HEADER.WORKLOG.UPDATEBTN"];
export const DRAFTBTNLABEL = strings["HEADER.WORKLOG.DRAFTBTN"];

export const SEARCH = strings["WORKLOG.TABLE.SEARCH"];
export const CLEAR = strings["WORKLOG.TABLE.CLEAR"];
export const ADDWORKLOG = strings["WORKLOG.ADD.WOKRLOG"];
export const WORKLOGDATE = strings["WORKLOG.TABLE.DATE"];
export const WORKLOGSHIFT = strings["WORKLOG.TABLE.SHIFT"];
export const WORKLOGWORKINGSTATUS = strings["WORKLOG.TABLE.WORKING.STATUS"];
export const WORKLOGSTATUS = strings["WORKLOG.TABLE.STATUS"];
export const WORKLOGACTION = strings["WORKLOG.TABLE.ACTION"];
export const REMOVEROW = strings["WORKLOG.TABLE.REMOVE.ROW"];
export const ADDROW = strings["WORKLOG.TABLE.ADD.ROW"];


