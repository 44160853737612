import { InputBaseComponentProps } from '@mui/material';
import React from 'react'
interface Props {
    id: string;
    className?: string
    value: string
    onChange: (val: string)=> void
    

}
const addNumbersInArray = (val: string[], oldVal: number) => {
    let sum = 0;
    for (let i = 0; i < val.length; i++) {
      const num = val[i].trim() ? Number(val[i]) : 0;
      sum = sum + num;
    }
    return sum + oldVal;
  };
export default function WorkLogOperationInput(props: Props) {
    const { id, className='', value='', onChange } = props;
    const [inpvalue, setValue] = React.useState<string>(value);
    const [placeholder, setPlaceholder] = React.useState("");
    const [oldValue, setOldValue] = React.useState(0);
    const handleOnChnage = (event: InputBaseComponentProps) => {
        const val = event.target.value??'';
        setValue(val);
    };
    React.useEffect(() => {
        if (oldValue === 0) {
            onChange(value);
            setPlaceholder(value);
        }else {
            const val = String(oldValue)
            setPlaceholder(val);
            onChange(val);
        }
    }, [oldValue, value]);
    const evalExpression = (val: string, setValue?: (data: string)=> void | undefined) => {
        const valueInNumber = !isNaN(Number(val)) ? Number(val) : 0;
        if (val.includes("+")) {
        const splitedValue = val.split("+");
        setOldValue(addNumbersInArray(splitedValue, oldValue));
        setValue && setValue(String(addNumbersInArray(splitedValue, oldValue)));
        } else {
        setOldValue(oldValue + valueInNumber);
        setValue && setValue(String(oldValue + valueInNumber));
        }
    };
    const handleOnKeyDown = (e: InputBaseComponentProps) => {
        const val = e.target.value;
        if (e.key === "Escape") {
            setValue("0");
            setOldValue(0);
            setPlaceholder('0');
            onChange('0');
        }
        if ((e.key === "Enter" || e.key === "Tab") && val) {
            if(parseInt(val) !== 0){
                evalExpression(val);
                setValue("");
            }
            
        }
    };
    const handleBlur = (e: InputBaseComponentProps) => {
        const val = e.target.value;
        evalExpression(val, setValue);
        if (oldValue === 0) {
            setOldValue(!isNaN(parseInt(inpvalue)) ? parseInt(inpvalue): 0)
        }
        if(parseInt(val) === 0){
            setOldValue(0);
            setValue('0');
            onChange('0');
        }
    };
    const handleOnFocus = () => {
        setOldValue(0);
    };
    // const key = Math.random() + Math.random() * Math.random()
    return (
        <input
            id={id}
            min={0}
            // key={key}
            className={className}
            onChange={handleOnChnage}
            onKeyDown={handleOnKeyDown}
            value={inpvalue}
            onBlur={handleBlur}
            onFocus={handleOnFocus}
            placeholder={placeholder}
        />
    );
};
