import { axios } from "api/setup.intersepter"
import { GetRequestDTO } from "./types"


export const getAllGroup = () => {
    return axios({
        method: "GET",
        url:'/master/groups'
    })
}
export const getEpfReport = (body: GetRequestDTO) => {
    return axios({
        method: "GET",
        url:`/report/epf?month=${body.month}&year=${body.year}&group_id=${body.group_id}`
    })
} 