import { axios } from "api/setup.intersepter"
import { GetAllBankReportRequestDTO } from "./types"
export const getAllBanks = () => {
    return axios({
        method: "GET",
        url: '/master/banks'
    })
}

export const getAllGroup = () => {  
    return axios({
        method: "GET",
        url:'/master/groups'
    })
}

export const getAllBankReport = (body: GetAllBankReportRequestDTO) => {
    return axios({
        method: "GET",
        url: `/report/bank?month=${body.month}&year=${body.year}&bank_id=${body.bank_id}&group_id=${body.group_id}`
    })
}
