import { axios } from "api/setup.intersepter"
import { GetAllESIReportRequestDTO } from "./types"


export const getAllGroup = () => {
    return axios({
        method: "GET",
        url:'/master/groups'
    })
}
export const getEsiReport = (body: GetAllESIReportRequestDTO) => {
    return axios({
        method: "GET",
        url:`/report/esi?month=${body.month}&year=${body.year}&group_id=${body.group_id}`
    })
} 