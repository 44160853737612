import {
  Button,
  FormControl,
  MenuItem,
  Select as SelectDropdown,
  TablePagination,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";
import moment from "moment";
import { DNLDPDF } from "pages/app/epfReport/container/epfReportString";
import { DNLDCSV } from "pages/app/esiReport/container/esiReportString";
import { GROUPGETDTO } from "pages/app/mustorRole/service/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AttendanceReportProps } from "../container/attendanceReportContainer";
import {
  CLOSE,
  CURRENTPATH,
  EMPLOYEE,
  GENERATEREPORT,
  HOMEPATH,
  NODATA,
  OFF,
  TTL,
  UNION,
} from "../container/attendanceReportString";
import { reset } from "../service/attendanceReportSlice";
import { GroupItems, ListDays } from "../service/types";
const paths = [
  {
    name: HOMEPATH,
    path: "",
  },
  {
    name: CURRENTPATH,
  },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#434343",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff ",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AttendanceReport = (props: AttendanceReportProps) => {
  const {
    getAllGroups,
    loading_getAttendanceReport,
    loading_getGroup,
    getAttendanceReport,
    attendanceReportList,
    success_getAttendanceReport,
    getRoleFuncn,
    role_function,
  } = props;
  const date = new Date();
  const [selectGroup, setSelectGroup] = useState("");

  const [startDate, setStartDate] = useState(
    moment(date).subtract(1, "month").format("YYYY-MM-DD")
  );
  const [allData, setAllData] = useState<any>([]);
  const [screen, setScreen] = useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isLoader, setLoader] = useState(false);
  const [showCust, setShowCust] = useState(false);

  const currentDate = moment();
  const maxSelectableMonth = currentDate.subtract(1, "month");

  const dispatch = useDispatch();
  useEffect(() => {
    getAllGroups();
  }, []);
  useEffect(() => {
    getRoleFuncn();
  }, []);
  const groupLists = useSelector((state: any) => state.groups.groupList);
  const masterGroups = groupLists?.map((ele: GROUPGETDTO) => ({
    key: Math.random() + 8,
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
  }));
  const filterGroups = masterGroups?.filter((ele: GROUPGETDTO) => {
    return ele.is_active === 1;
  });
  const handleCustShow = () => {
    setShowCust(true);
  };
  const handleChangeDate = (value: any) => {
    setSelectGroup("");
    setStartDate(value);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleReportGenerate = () => {
    const selectYear = moment(startDate).format("YYYY");
    const selectMonth = moment(startDate).format("MM");
    let newDate = moment(startDate).toDate();
    const totalDays = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    ).getDate();
    let list_days: ListDays = [];
    for (let i = 1; i <= totalDays; i++) {
      list_days.push(i);
    }
    const data = {
      group_id: selectGroup,
      month: parseInt(selectMonth),
      year: parseInt(selectYear),
    };
    getAttendanceReport(data);
  };
  const handleReportGenerateAllReport = () => {
    const selectYear = moment(startDate).format("YYYY");
    const selectMonth = moment(startDate).format("MM");

    const data = {
      group_id: selectGroup,
      month: parseInt(selectMonth),
      year: parseInt(selectYear),
    };
    getAttendanceReport(data);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleClose = () => {
    dispatch(reset());
    setSelectGroup("");
    setStartDate(moment(date).subtract(1, "month").format("YYYY-MM-DD"));
    setScreen(1);
  };
  let reportDisable = selectGroup === "";

  useEffect(() => {
    if (success_getAttendanceReport) {
      setAllData(attendanceReportList);
      // const pdfUrl = `${BASE_URL}/${attendanceReportList?.Pdf}`;
      const pdfUrl = `${attendanceReportList?.Pdf}`;
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = attendanceReportList && pdfUrl;
      link.target = "_blank"; // Open the PDF in a new tab/window

      // Trigger the download
      selectGroup === "All" && link.click();
      selectGroup === "All" ? setScreen(1) : setScreen(2);
    }
  }, [success_getAttendanceReport]);

  useEffect(() => {
    const isLoading = loading_getAttendanceReport;
    setLoader(isLoading);
  }, [loading_getAttendanceReport, success_getAttendanceReport]);
  return (
    <>
      {isLoader && <Loader />}
      <Breadcrumb pathList={paths} />
      <div>
        {screen === 1 ? (
          <div className="generate_paper">
            <ToastContainer />

            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MM/yyyy"
                    views={["year", "month"]}
                    label="Year and Month"
                    maxDate={maxSelectableMonth.toDate()}
                    value={startDate}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <SelectDropdown
                  fullWidth
                  value={selectGroup}
                  onChange={(event) => setSelectGroup(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    selectGroup !== "" ? undefined : () => "Select Group"
                  }
                >
                  <MenuItem key={Math.random() + 22} value={"All"}>
                    All
                  </MenuItem>
                  {filterGroups &&
                    filterGroups.map((ele: GroupItems) => (
                      <MenuItem key={Math.random() + 9} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                </SelectDropdown>
              </FormControl>
            </div>
            <div className="form_btn_generate">
              <div className="">
                {selectGroup === "All" ? (
                  // <a target="_blank" href={`${BASE_URL}/${allData.Pdf}`}>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerateAllReport}
                  >
                    {GENERATEREPORT}
                  </Button>
                ) : (
                  // </a>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerate}
                  >
                    {GENERATEREPORT}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100">
            <div className="d-flex justify-content-between mb-2">
              <div>Reports</div>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <a target="_blank" href={`${allData?.Excel}`}>
                    <button className="CSVdownload">{DNLDCSV}</button>
                  </a>
                  <a target="_blank" href={`${allData?.Pdf}`}>
                    <button className="CSVdownload">{DNLDPDF}</button>
                  </a>
                </div>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  {CLOSE}
                </button>
              </div>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="customized table table-striped">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{EMPLOYEE}</StyledTableCell>
                      <StyledTableCell align="left">{UNION}</StyledTableCell>
                      {[...Array(31)].map((_, index) => (
                        <StyledTableCell key={index + 1}>
                          {index + 1}
                        </StyledTableCell>
                      ))}

                      <StyledTableCell align="left">{TTL}</StyledTableCell>
                      <StyledTableCell align="left">{OFF}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allData?.attendanceReport?.length ? (
                      allData.attendanceReport
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => (
                          <>
                            <StyledTableRow key={Math.random() + 6}>
                              <StyledTableCell
                                align="left"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {row.Name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Union_Serial_Number}
                              </StyledTableCell>

                              {[...Array(31)].map((_, index) => (
                                <StyledTableCell key={index + 1}>
                                  {row[String(index + 1)]}
                                </StyledTableCell>
                              ))}

                              <StyledTableCell align="left">
                                {row.Duty}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.OffDay}
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ))
                    ) : (
                      <StyledTableCell
                        className="NoRecordFound"
                        align="center"
                        colSpan={23}
                      >
                        {NODATA}
                      </StyledTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <div className="tpagination">
                  <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={10}
                    count={allData?.attendanceReport?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </TableContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AttendanceReport;
