import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import strings from "translation";
import { GenerateReportRequest } from "./generateReport.request";
import { GENERATEREPORTDT, GenerateReportInitDTO } from "./types";

export const initialState: GenerateReportInitDTO = {
  loading_generateReport: false,
  success_generateReport: false,
  error_generateReport: false,
  generateReportDetails: null,
};

export const generateReport = createAsyncThunk(
  "report/generateReportRequest",
  async (body: GENERATEREPORTDT) => {
    return GenerateReportRequest(body)
      .then((response) => {
        if (response.data.code === 200) {
          throw toast(response?.data.message);
        }
      })
      .catch((error) => {
        throw toast(error.response.data);
      });
  }
);
export const generateReportSlice = createSlice({
  name: "generateReport",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(generateReport.pending, (state) => {
      state.loading_generateReport = true;
      state.success_generateReport = false;
      state.error_generateReport = false;
    });
    builder.addCase(
      generateReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading_generateReport = false;
        state.error_generateReport = false;
        state.success_generateReport = true;
        state.generateReportDetails = action.payload;
      }
    );
    builder.addCase(generateReport.rejected, (state, action: AnyAction) => {
      state.loading_generateReport = false;
      state.error_generateReport =
        action?.error?.message || strings["ERROR.SOMETHINGWENTWRONG"];
      state.success_generateReport = false;
    });
  },
});
export default generateReportSlice.reducer;
export const { reset } = generateReportSlice.actions;
