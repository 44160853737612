import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import SummaryReports from "../components/SummaryReports";
import { reset, summaryReport } from "../services/summarySlice";
import { ReportsRes, SUMMARYREPORTDT } from "../services/types";

interface StateProps {
  loading_summaryReport: boolean;
  success_summaryReport: boolean;
  error_summaryReport: boolean;
  summaryReportDetails: ReportsRes | null;
}

interface DispatchProps {
  SummaryReportRequest(body: SUMMARYREPORTDT): void;
  Reset(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error_summaryReport: state.summaryReport.error_summaryReport,
    loading_summaryReport: state.summaryReport.loading_summaryReport,
    success_summaryReport: state.summaryReport.success_summaryReport,
    summaryReportDetails: state.summaryReport.summaryReportDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    SummaryReportRequest: (data) => {
      dispatch(summaryReport(data));
    },
    Reset() {
      dispatch(reset());
    },
  };
};
export interface SummaryReportsProps extends StateProps, DispatchProps {}
export const SummaryReportsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryReports);
