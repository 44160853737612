import React from 'react'
import errorimginr from 'assets/images/errorimginr.png'
const Pagenotfoundinner = () => {
  return (
      <div>
        <div className="errorimg">
        <img src={errorimginr} />
      </div>
    </div>
  )
}

export default Pagenotfoundinner