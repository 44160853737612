import React, { SyntheticEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MdClose } from 'react-icons/md';
import { FormProps, ISubmitResult } from './types';
import FormTextField from 'components/common/textField/textField';
import { ADDNEWLABEL, UPDATELABEL, SAVEBTN, UPDATEBTN, DATEERROR, DATELABEL, DATEPLACEHOLDER, CANCELBTN, CAPPLACEHOLDER, CAPLABEL, CAPERROR, GROSSPLACEHOLDER, GROSSLABEL, GROSSERROR } from './esiString';
import moment from 'moment';
import "../style.scss"

const EsiForm = (props: FormProps) => {
    const {
        initialValue = {},
        handleFormData,
        onHandleClose,
        clickedBtn,
        errorMessage
    } = props;

    const [grossSalary, setGrossSalary] = useState<ISubmitResult>({
        value: initialValue.esi_percent
            ? initialValue.esi_percent : "",
        error: false,
        success: false,
    });

    const [effectDate, setEffectDate] = useState<ISubmitResult>({
        value: initialValue.effective_from
            ? moment(initialValue.effective_from).format('YYYY-MM-DD') : "",
        error: false,
        success: false,
    });
    const [capAt, setCapAt] = useState<ISubmitResult>({
        value: initialValue
            ? initialValue?.cap_at : 0,
        error: false,
        success: false,
    });

    const [arrearEndDate, setArrearEndDate] = useState<ISubmitResult>({
        value: initialValue.arrear_end_date
          ? moment(initialValue.arrear_end_date).format("YYYY-MM-DD")
          : "",
        error: false,
        success: false,
      });
    
    const [isArriear, setIsArriear] = React.useState<boolean>(
        initialValue ? (initialValue.is_arrear  === 1 ? true : false) : false
    );
    let initarrearEndDate = initialValue.arrear_end_date? initialValue.arrear_end_date : ""
    let initArriear = initialValue?.is_arrear === 1 ? true : false;

    let editDisable =
        grossSalary.value === initialValue.esi_percent &&
        effectDate.value === initialValue.effective_from &&
        capAt.value === initialValue.cap_at&&
        arrearEndDate.value === initarrearEndDate &&
        isArriear === initArriear;
  
    let disable =
        grossSalary.error ||
        effectDate.error ||
        grossSalary.value === "" ||
        effectDate.value === "" ||
        (grossSalary.success === false &&
            effectDate.success === false )

    const handleChangeGross = (value: ISubmitResult) => {
        setGrossSalary(value);
    };

    const handleChangeDate = (value: ISubmitResult) => {
        setEffectDate(value);
    };
    const handleChangeCap = (value: ISubmitResult) => {
        setCapAt(value);
    };

    const handleChangeArrearDate = (value: ISubmitResult) => {
        setArrearEndDate(value);
    };
    const handleCheckArrear = (
        event: SyntheticEvent<Element, Event>,
        checked: boolean
      ) => {
        setIsArriear(checked);
    };
    const handleSubmitForm = () => {
        const data = {
            is_arrear: isArriear,
            arrear_end_date:arrearEndDate.value,
            esi_percent: parseFloat(grossSalary.value as string),
            effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
            cap_at: capAt.value? parseFloat(capAt.value as string):0,
            is_active: initialValue?.is_active
        }
        handleFormData(data)
    }

    return (
        <Card sx={{ boxShadow: "none" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={onHandleClose}>
                        <MdClose />
                    </IconButton>
                }
                title={clickedBtn === "add" ? ADDNEWLABEL : UPDATELABEL}
            />
            {errorMessage ?<span className='error_msg'>{errorMessage}</span>:null}
            <CardContent>
                <Box
                    component="form"
                    sx={{
                        width: '100%',
                        '& .MuiTextField-root': { mb: 2 },
                    }}
                    className={"formResponsiveHeight"}
                    noValidate={true}
                >
                    <FormTextField
                        type="price"
                        placeholder={GROSSPLACEHOLDER}
                        label={GROSSLABEL}
                        Value={grossSalary.value}
                        onChangeText={handleChangeGross}
                        Required={true}
                        CustomErrorLine={GROSSERROR}
                    />
                    <FormTextField
                        type="Edate"
                        hidden={clickedBtn === "edit" ? !isArriear : false}
                        placeholder={DATEPLACEHOLDER}
                        label={DATELABEL}
                        Value={effectDate.value}
                        onChangeText={handleChangeDate}
                        Required={true}
                        CustomErrorLine={DATEERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={CAPPLACEHOLDER}
                        label={CAPLABEL}
                        Value={capAt.value}
                        onChangeText={handleChangeCap}
                        Required={false}
                        CustomErrorLine={CAPERROR}
                    />
                   <FormTextField
                        type="Edate"
                        hidden={!isArriear}
                        placeholder={"Arrear End Date"}
                        label={"Arrear End Date"}
                        Value={arrearEndDate.value}
                        onChangeText={handleChangeArrearDate}
                        Required={false}
                        CustomErrorLine={DATEERROR}
                    />
                   <FormControlLabel
                        sx={{ mb: 1 }}
                        value={isArriear}
                        control={<Checkbox checked={isArriear} />}
                        label="Is arrear"
                        labelPlacement="end"
                        onChange={handleCheckArrear}
                    />
                </Box>
            </CardContent>
            <CardActions className='d-flex dust justify-content-end'>
                <Button size="large" variant="outlined" onClick={onHandleClose}>{CANCELBTN}</Button>
                <Button size="large" variant="contained" disabled={clickedBtn === "add" ? disable : editDisable} onClick={handleSubmitForm}>{clickedBtn === "add" ? SAVEBTN : UPDATEBTN}</Button>
            </CardActions>
        </Card>
    );
}

export default EsiForm;
