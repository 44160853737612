import { AttendanceComponent } from "pages/app/attendance/container/attendanceContainer";
import { BanksComponent } from "pages/app/bank/container/banksContainer";
import { EpfComponent } from "pages/app/epf/container/epfContainer";
import { EsiComponent } from "pages/app/esi/container/esiContainer";
import { GroupComponent } from "pages/app/group/container/groupContainer";
import { HolidayComponent } from "pages/app/holiday/container/holidayContainer";
import { HolidayConfigComponent } from "pages/app/holidaysConfig/container/holidayConfigContainer";
import { IncentiveComponent } from "pages/app/incentive/container/incentiveContainer";
import { MaterialComponent } from "pages/app/material/container/materialContainer";
import { OperationComponent } from "pages/app/operation/container/operationContainer";
import { OperationCateComponent } from "pages/app/operationCategory/container/operationCateContainer";
import Pagenotfoundinner from "pages/app/pagenotfound/component/pagenotfoundinner";
import { RolesComponent } from "pages/app/role/container/rolesContainer";
import { ShiftComponent } from "pages/app/shift/container/shiftContainer";
import { StaffComponent } from "pages/app/staff/container/staffContainer";
import { WageComponent } from "pages/app/wage/container/wageContainer";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
// import EsiReport from 'pages/app/esiReport/component/esiReportb'
import { AccountComponent } from "pages/app/Account/container/accountContainer";
import { AttendanceReportComponent } from "pages/app/attendanceReport/container/attendanceReportContainer";
import { BankReportComponent } from "pages/app/bankStatement/container/bankReportContainer";
import { EpfReportComponent } from "pages/app/epfReport/container/epfReportContainer";
import { EsiReportComponent } from "pages/app/esiReport/container/esiReportContainer";
import { GenerateReportsComponent } from "pages/app/gererateReports/container/generateReportContainer";
import { MusterRollComponent } from "pages/app/mustorRole/container/musterrollContainer";
import { RoleFunctionMapComponent } from "pages/app/roleFunctionMap/container/roleFunctionMapContainer";
import { SummaryReportsComponent } from "pages/app/summaryReport/container/summaryContainer";
import { UsersComponent } from "pages/app/users/container/userContainer";
import { WageSlipComponent } from "pages/app/wageSlip/container/wageslipContainer";
import { WorklogComponent } from "pages/app/worklog/container/worklogContainer";

const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/bank" element={<BanksComponent />} />
        <Route path="/role" element={<RolesComponent />} />
        <Route path="/group" element={<GroupComponent />} />
        <Route path="/material" element={<MaterialComponent />} />
        <Route path="/shift" element={<ShiftComponent />} />
        <Route path="/optioncategory" element={<OperationCateComponent />} />
        <Route path="/operation" element={<OperationComponent />} />
        <Route path="/staff" element={<StaffComponent />} />
        <Route path="/revisepf" element={<EpfComponent />} />
        <Route path="/revisesi" element={<EsiComponent />} />
        <Route path="/holiday" element={<HolidayComponent />} />
        <Route path="/holidayconfig" element={<HolidayConfigComponent />} />
        <Route path="/wage" element={<WageComponent />} />
        <Route path="/incentive" element={<IncentiveComponent />} />
        <Route path="/attendance" element={<AttendanceComponent />} />
        <Route path="/bankstatement" element={<BankReportComponent />} />
        <Route path="/worklog" element={<WorklogComponent />} />
        <Route
          path="/attendancereport"
          element={<AttendanceReportComponent />}
        />
        <Route
          path="/generate-reports"
          element={<GenerateReportsComponent />}
        />
        <Route path="/summary-reports" element={<SummaryReportsComponent />} />
        <Route path="/users" element={<UsersComponent />} />
        <Route path="/rolefunctionmap" element={<RoleFunctionMapComponent />} />
        <Route path="/account" element={<AccountComponent />} />

        <Route path="/" element={<AttendanceComponent />} />

        <Route path="/epfreport" element={<EpfReportComponent />} />
        <Route path="/esireport" element={<EsiReportComponent />} />
        <Route path="/wageslip" element={<WageSlipComponent />} />
        <Route path="/mustorrole" element={<MusterRollComponent />} />

        <Route path="*" element={<Pagenotfoundinner />} />
        <Route path="/forbidden" element={<h2>No Access...</h2>} />
      </Route>
    </Routes>
  );
};

export default Router;
