import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";

import { Button, FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useCallback, useState } from "react";
import { ToastContainer } from "react-toastify";
import { GenerateReportsProps } from "../container/generateReportContainer";
import { CURRENTPATH, HOMEPATH } from "../container/generateReportString";

const paths = [
  {
    name: HOMEPATH,
    path: "",
  },
  {
    name: CURRENTPATH,
  },
];
export default function GenerateReports(props: GenerateReportsProps) {
  const {
    GenerateReportRequest,
    error_generateReport,
    generateReportDetails,
    loading_generateReport,
    success_generateReport,
  } = props;
  const date = new Date();
  const currentDate = moment();

  const [startDate, setStartDate] = useState(
    moment(date).subtract(1, "month").format("YYYY-MM-DD")
  );
  const maxSelectableMonth = currentDate.subtract(1, "month");
  const handleChangeDate = (value: any) => {
    setStartDate(value);
    console.log(value);
  };
  const handleReportGenerate = useCallback(() => {
    const date = new Date(startDate);
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    GenerateReportRequest({ month: month, year: year });
  }, [GenerateReportRequest, startDate]);

  return (
    <>
      {loading_generateReport && <Loader />}
      <Breadcrumb pathList={paths} />
      <div className="">
        <div className="generate_paper">
          <ToastContainer />

          <div className="form_div_generate">
            <FormControl size="small" fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="MM/yyyy"
                  views={["year", "month"]}
                  label="Year and Month"
                  maxDate={maxSelectableMonth.toDate()}
                  value={startDate}
                  onChange={handleChangeDate}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} size="small" />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </div>

          <div className="form_btn_generate">
            <div className="">
              <Button
                variant="contained"
                className="report-btn"
                onClick={handleReportGenerate}
                disabled={loading_generateReport}
              >
                {loading_generateReport ? "Generating..." : "Generate Report"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
