import strings from "translation";

export const ADDNEWLABEL = strings["FORM.HOLIDAYCONFIG.ADDNEWLABEL"];
export const UPDATELABEL = strings["FORM.HOLIDAYCONFIG.UPDATELABEL"];
export const SAVEBTN = strings["FORM.HOLIDAYCONFIG.SAVEBTN"];
export const UPDATEDBTN = strings["FORM.HOLIDAYCONFIG.UPDATEDBTN"];

export const OFFDAYLABEL = strings["FORM.HOLIDAYCONFIG.OFFDAYLABEL"];
export const OFFELIGLABEL = strings["FORM.HOLIDAYCONFIG.OFFELIGLABEL"];
export const WDAYLABEL = strings["FORM.HOLIDAYCONFIG.WDAYLABEL"];
export const WDAYBTABSENTLABEL = strings["FORM.HOLIDAYCONFIG.WDAYBTABSENTLABEL"];

export const SHIFTLABEL = strings["FORM.HOLIDAYCONFIG.SHIFTLABEL"];
export const BASICLABEL = strings["FORM.HOLIDAYCONFIG.BASICLABEL"];
export const DALABEL = strings["FORM.HOLIDAYCONFIG.DALABEL"];
export const HRALABEL = strings["FORM.HOLIDAYCONFIG.HRALABEL"];
export const CANLABEL = strings["FORM.HOLIDAYCONFIG.CANLABEL"];

export const SHIFTPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.PALCEHOLDER.SHIFTPLACEHOLDER"];
export const BASICPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.PALCEHOLDER.BASICLABEL"];
export const DAPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.PALCEHOLDER.DALABEL"];
export const HRAPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.PALCEHOLDER.HRALABEL"];
export const CANPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.PALCEHOLDER.CANLABEL"];

export const XTRABASIC = strings["FORM.HOLIDAYCONFIG.XTRABASIC"];
export const NAMEPLACEHOLDER = strings["FORM.HOLIDAYCONFIG.NAMEPLACEHOLDER"];
export const NAMELABEL = strings["FORM.HOLIDAYCONFIG.NAMELABEL"];
export const NAMEERROR = strings["FORM.HOLIDAYCONFIG.NAMEERROR"];

export const CONFIGNAMEPLACEHOLDER = strings["FORM.CONFIG.CONFIGNAMEPLACEHOLDER"];
export const CONFIGNAMELABEL = strings["FORM.CONFIG.CONFIGNAMELABEL"];
export const CONFIGNAMEERROR = strings["FORM.CONFIG.CONFIGNAMEERROR"];





export const DATEPLACEHOLDER = strings["FORM.CONFIG.DATEPLACEHOLDER"];
export const DATELABEL = strings["FORM.CONFIG.DATELABEL"];
export const DATEERROR = strings["FORM.CONFIG.DATEERROR"];




