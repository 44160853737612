import {
  Box,
  FormControl,
  Paper,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";

import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";
import StatusChip from "components/common/chip/chip";
import { ArrowLeft, Edit2, Trash } from "iconsax-react";
import _ from "lodash";
import moment from "moment";
import { NODATAFOUND } from "pages/app/attendance/container/attendanceString";
import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { WorklogProps as Iprops } from "../container/worklogContainer";
import {
  ADDROW,
  ADDWORKLOG,
  CLEAR,
  CURRENTPATH,
  DRAFTBTNLABEL,
  HOMEPATHLABEL,
  PAGEHEADING,
  SAVEBTNLABEL,
  SEARCH,
  STATUSCHANGEHEAD,
  STATUSCHANGEPERMISSIONNO,
  STATUSCHANGEPERMISSIONYES,
  STATUSCHANGESUBHEAD,
  SWALDRAFTSUCEESTEXT,
  SWALDRAFTSUCEESTITLE,
  SWALSTATUSSUCEESTITLE,
  SWALSTATUSUCEESTEXT,
  SWALSUCEETEXT,
  SWALSUCEETITLE,
  SWALUPDATESUCEESTEXT,
  SWALUPDATESUCEESTITLE,
  TBLBONUSLABEL,
  TBLGANGLABEL,
  TBLOPERATIONLABEL,
  TBLPARTIALPAYLABEL,
  UPDATEBTNLABEL,
  WORKLOGACTION,
  WORKLOGDATE,
  WORKLOGSHIFT,
  WORKLOGSTATUS,
  WORKLOGWORKINGSTATUS,
} from "../container/worklogString";
import {
  CURRENTWORKLOGDTO,
  MEMBERDTO,
  OPERATIONDT,
  ShiftListDTO,
  WORKLOGDTO,
  allShiftListDTO,
  worklogListDTO,
} from "../service/types";
import { reset } from "../service/worklogSlice";
import "./worklogstyles.scss";

import WorkLogOperationInput from "components/AppSpecific/WorkLogOperationInput/WorkLogOperationInput";
import {
  GROUPDETAILDT,
  GROUPDROPDOWN,
  GROUPSTATEDT,
  SHIFTDETAILDT,
  SHIFTSTATEDT,
} from "pages/app/attendance/service/types";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#434343",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff ",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface WorklogProps extends Iprops {
  worklogList: worklogListDTO[];
  shiftList: ShiftListDTO[];
  allShiftList: allShiftListDTO[];
}
interface SelectedGroupData {
  key: number;
  id: number;
  value: number;
  label: string;
  is_active: number;
  effective_from: Date;
}
interface OperationData {
  id: number;
  name: string;
  operation_catagory_id: number;
  is_active: number;
  created_at: string;
  updated_at: string | null;
  created_by: number;
  updated_by: number | null;
  operation_catagory: {
    id: number;
    name: string;
    datum: number;
    effective_from: string;
    is_active: number;
    per_bag_amount: number;
    is_arrear: number;
    arrear_end_date: string | null;
    created_at: string;
    updated_at: string;
    created_by: number;
    updated_by: number;
  };
}

const desiredOrder = ["STACK", "STW", "DTW", "STT", "RESTACK", "RTP"];

const reorderOperations = (
  data: OperationData[],
  order: string[]
): OperationData[] => {
  return data
    .slice()
    .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
};

const Worklog = (props: WorklogProps) => {
  const {
    getShiftByDate,
    getAllStaffByShift,
    getAllOperation,
    getAllShift,
    loading_getAllShift,
    success_getAllShift,
    loading_getShift,
    success_getShift,
    getAllStaff,
    loading_getStaff,
    success_getStaff,
    loading_getOperation,
    success_getOperation,
    getWorklogs,
    loading_getWorklog,
    success_getWorklog,
    createAllWorklog,
    loading_createtWorklog,
    success_createtWorklog,
    error_createtWorklog,
    createDraftAllWorklog,
    loading_createtDraftWorklog,
    success_createtDraftWorklog,
    error_createtDraftWorklog,
    updateAllWorklog,
    loading_updatetWorklog,
    success_updatetWorklog,
    error_updatetWorklog,
    updateStatusWorklog,
    loading_updatetStatusWorklog,
    success_updatetStatusWorklog,
    allShiftList,
    shiftList,
    allStaffList,
    operationList,
    worklogList,
    role_function,
    getRoleFuncn,
    getAllGroupsShiftByDt,
    loading_getGroupShift,
    error_getGroupShift,
    success_getGroupShift,
    getAllGroups,
    allGroupShifts,
    getWorklogsById,
    worklogDetailsById,
    deleteWorklogsById,
    error_worklogDelete,
    loading_worklogDelete,
    success_worklogDelete,
  } = props;

  const [isImportFormOpen, setImportFormOpen] = useState(false);
  const [importErrorMsg, setImportErrorMsg] = useState("");
  const [importSuccessMsg, setImportSuccessMsg] = useState("");
  const handleImportFormOpen = () => setImportFormOpen(true);
  const handleImportFormClose = () => {
    setImportFormOpen(false);
    setImportErrorMsg("");
  };
  const [importForDate, setImportForDate] = useState("");
  const [importFile, setImportFile] = useState<File | null>(null);

  const handleImportFormSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_ENDPOINT}/bulk/upload/worklog`;
    console.log(process.env.NODE_ENV, url);
    const formData = new FormData();
    formData.append("file", importFile as Blob);
    formData.append("date", importForDate);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    setLoader(true);
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        handleChangeEdit(response.data.worklog);
        setImportSuccessMsg(`${response.data.success}`);
        setImportFormOpen(false);
        setImportForDate("");
        setImportFile(null);
        Swal.fire({
          title: SWALSUCEETITLE,
          text: response.data.success.message,
          icon: "success",
        });
      })
      .catch((e) => {
        setLoader(false);
        setImportForDate("");
        setImportFile(null);
        setImportErrorMsg(
          e?.response?.data?.error?.message ?? "Something went wrong"
        );
        console.log(
          e?.response?.data?.error?.message ?? "Something went wrong"
        );
      });
  };

  const paths = [
    {
      name: HOMEPATHLABEL,
      path: "",
    },
    {
      name: CURRENTPATH,
    },
  ];
  const date = new Date();
  const [isLoader, setLoader] = useState(false);
  const [clickedBtn, setClickedBtn] = useState("add");
  const [allOperation, setAllOperation] = useState<any>([]);
  const [workLogs, setWorklogs] = useState<any[]>([]);
  const [currentType, setCurrentType] = useState<CURRENTWORKLOGDTO | null>();
  const [rows, setRows] = useState<worklogListDTO[]>(worklogList);
  const [shiftOption, setShiftOption] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [allShift, setAllShift] = useState([]);
  const [allStaffs, setAllStaffs] = useState([]);
  const [errorMessage, setErrorMessage] = useState<
    boolean | string | undefined
  >();
  const [selectShifts, setSelectShifts] = useState<any>();
  const [screen, setScreen] = useState(1);
  const [selectYear, setSelectYear] = useState<string | Date>(
    moment(date).format("YYYY-MM-DD")
  );
  const [selectMonth, setSelectMonth] = useState<string | Date>(
    moment(date).format("YYYY-MM-DD")
  );
  const [selectDay, setSelectDay] = useState<string | Date>(
    moment(date).format("YYYY-MM-DD")
  );
  const [sarchShift, setSearchShift] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedGroupData, setSelectedGroupData] = useState<
    SelectedGroupData[]
  >([]);
  const [masterGrp, setMasterGrp] = useState<GROUPDROPDOWN[]>([]);
  const [masterShft, setMasterShift] = useState<SHIFTDETAILDT[]>([]);
  const [tasks, setTasks] = useState<any>({
    present_list: [],
  });

  const [selectGroupList, setSelectGroupList] = useState<any>({
    value: null,
    error: false,
    success: false,
  });

  const selectedGroupDataName = selectedGroupData.map((obj) => obj.value);
  const presentListData = [...tasks.present_list];
  const dispatch = useDispatch();
  const addRowTable = () => {
    const data = {
      gang_member: [],
      operation: allOperation,
      bonus: "0",
      partial_pay: "0",
      gang_number: " ",
    };
    setWorklogs([...workLogs, data]);
  };
  const removeRowTable = (ele: number) => {
    const removeData = [...workLogs];
    removeData.splice(ele, 1);
    setWorklogs(removeData);
  };
  const handleClickAddScreen = () => {
    setScreen(2);
  };
  useEffect(() => {
    getRoleFuncn();
  }, []);
  const disabled = selectShifts?.value === undefined;
  const handleClickBackscreen = () => {
    setWorklogs([
      {
        gang_member: [],
        operation: allOperation,
        bonus: "0",
        partial_pay: "0",
        gang_number: " ",
      },
    ]);
    setSelectShifts("");
    setErrorMessage("");
    setCurrentType(null);
    setStartDate("");
    setClickedBtn("add");
    setScreen(1);
  };
  useEffect(() => {
    if (success_createtWorklog) {
      setErrorMessage("");
      dispatch(reset());
      Swal.fire({
        title: SWALSUCEETITLE,
        text: SWALSUCEETEXT,
        icon: "success",
      });
      fetchData();
      handleClickBackscreen();
    } else if (error_createtWorklog) {
      setErrorMessage(error_createtWorklog);
    } else if (success_updatetWorklog) {
      setErrorMessage("");
      dispatch(reset());
      Swal.fire({
        title: SWALUPDATESUCEESTITLE,
        text: SWALUPDATESUCEESTEXT,
        icon: "success",
      });
      fetchData();
      handleClickBackscreen();
    } else if (error_updatetWorklog) {
      setErrorMessage(error_updatetWorklog);
    } else if (success_createtDraftWorklog) {
      setErrorMessage("");
      dispatch(reset());
      Swal.fire({
        title: SWALDRAFTSUCEESTITLE,
        text: SWALDRAFTSUCEESTEXT,
        icon: "success",
      });
      fetchData();
      handleClickBackscreen();
    } else if (error_createtDraftWorklog) {
      setErrorMessage(error_createtDraftWorklog);
    } else if (success_updatetStatusWorklog) {
      setErrorMessage("");
      dispatch(reset());
      Swal.fire({
        title: SWALSTATUSSUCEESTITLE,
        text: SWALSTATUSUCEESTEXT,
        icon: "success",
      });
      fetchData();
    } else if (success_worklogDelete) {
      setErrorMessage("");
      dispatch(reset());
      Swal.fire("Deleted!", "Your worklog has been deleted.", "success");

      fetchData();
    }
  }, [
    success_createtDraftWorklog,
    error_createtDraftWorklog,
    success_createtWorklog,
    error_createtWorklog,
    success_updatetWorklog,
    success_updatetStatusWorklog,
    error_updatetWorklog,
    success_worklogDelete,
    dispatch,
    handleClickBackscreen,
  ]);
  useEffect(() => {
    if (operationList.length) {
      setWorklogs([
        {
          gang_member: [],
          operation: allOperation,
          bonus: "0",
          partial_pay: "0",
          gang_number: " ",
        },
      ]);
    }
  }, [allOperation, operationList]);

  useEffect(() => {
    const isLoading =
      loading_getAllShift ||
      loading_getWorklog ||
      loading_createtWorklog ||
      loading_createtDraftWorklog ||
      loading_updatetWorklog ||
      loading_updatetStatusWorklog ||
      loading_getStaff ||
      loading_getOperation ||
      loading_getShift ||
      loading_worklogDelete;
    setLoader(isLoading);
  }, [
    loading_getAllShift,
    loading_getWorklog,
    loading_createtWorklog,
    loading_createtDraftWorklog,
    loading_updatetWorklog,
    loading_updatetStatusWorklog,
    loading_getOperation,
    loading_getStaff,
    loading_getShift,
    loading_worklogDelete,
  ]);
  useEffect(() => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page,
    };
    getWorklogs(filterData);
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (clickedBtn === "add") {
      if (startDate && selectGroupList.length > 0) {
        const allGrpIDs = selectedGroupData.map((ele) => ele.id);
        let present_list: any[] = [];
        allGrpIDs.forEach((groupId) => {
          const filteredByGroupId = allStaffList?.filter(
            (ele: any) => ele.group_id === groupId
          );
          const sortedFilteredByGroupId = _.sortBy(
            filteredByGroupId,
            (ele) => parseInt(ele.name),
            ["asc"]
          );
          present_list = [...present_list, ...sortedFilteredByGroupId];
        });
        setTasks({
          present_list: present_list,
        });
      }
    }
  }, [startDate, selectGroupList, success_getStaff, selectedGroupData]);

  useEffect(() => {
    if (clickedBtn === "add") {
      if (selectGroupList.length > 0) {
        let group_ids: any = [];
        selectGroupList?.map((ele: GROUPDROPDOWN) => {
          group_ids.push(ele.id);
        });
        let groupDisable = group_ids.length !== 0;
        groupDisable && getAllStaff(group_ids);
      }
    }
  }, [selectGroupList]);

  useEffect(() => {
    if (startDate !== "") {
      let value = { date: startDate };
      getAllGroupsShiftByDt(value);
    }
  }, [startDate]);
  const absentData =
    worklogDetailsById &&
    worklogDetailsById[0].gangData?.absent?.map(
      (item: { union_serial_number: number; group_id: number }) => ({
        value: item.union_serial_number,
        label: item.union_serial_number,
        group_id: item.group_id,
      })
    );

  // const operationData = worklogDetailsById && worklogDetailsById[0].gangData?.sd?.map((item) =>console.log("item",item));
  // // const groupDetail = worklogDetailsById[0].groupDetails.map((group: { id: number }) => group.id)

  const groupLists = useSelector(
    (state: GROUPSTATEDT) => state.groups.groupList
  );
  const masterGroup = groupLists?.map((ele: GROUPDETAILDT) => ({
    key: Math.random() + 2,
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
    effective_from: new Date(ele.effective_from),
  }));
  const masterGroups = masterGroup?.filter((ele) => {
    return (
      ele.is_active === 1 && dateDiffInDays(date, new Date(ele.effective_from))
    );
  });

  const newshiftLists = useSelector(
    (state: SHIFTSTATEDT) => state.shift.shiftList
  );

  const masterShifts = allShiftList?.map((ele: SHIFTDETAILDT) => ({
    key: Math.random() + 3,
    id: ele.id,
    value: ele.id,
    label: ele.name,
    amount: ele.amount,
    is_active: ele.is_active,
  }));
  const masterShift = masterShifts?.filter((ele: SHIFTDETAILDT) => {
    return ele.is_active === 1;
  });

  useEffect(() => {
    if (success_getGroupShift) {
      if (allGroupShifts) {
        let groupArr = allGroupShifts?.map((ele) => {
          return ele.attendance_groups[0]?.groupId;
        });
        let newGroupArr = groupArr?.join(",").split(",");
        const result = masterGroups.filter((ele) =>
          newGroupArr.every((id: number | string) => id != ele.id)
        );
        setMasterGrp(result);
        let shiftArr = allGroupShifts?.map((ele) => {
          return ele.shift?.id;
        });
        const resultShift = masterShift.filter((ele) =>
          shiftArr.every((id: number | string) => id !== ele.id)
        );
        setMasterShift(resultShift);
      }
    }
  }, [success_getGroupShift]);

  const fetchData = () => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page + 1,
    };
    getWorklogs(filterData);
  };

  const handleFilter = () => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page,
    };
    getWorklogs(filterData);
  };
  const handleClearFilter = () => {
    setSelectYear(date);
    setSelectMonth(date);
    setSelectDay(date);
    setSearchShift("");
    let clearData = {
      year: moment(date).format("YYYY"),
      month: moment(date).format("MM"),
      day: moment(date).format("DD"),
      shift_id: null,
      limit: rowsPerPage,
      page: page,
    };
    getWorklogs(clearData);
  };
  const handleChangeYear = (event: any) => {
    setSelectYear(event);
  };
  const handleChangeMonth = (event: any) => {
    setSelectMonth(event);
  };
  const handleChangeDates = (event: any) => {
    setSelectDay(event);
  };
  const handleSearchShift = (value: any) => {
    setSearchShift(value);
  };
  useEffect(() => {
    if (success_getWorklog) {
      let resultWorklogData = worklogList.map((ele) => {
        return {
          id: ele.id,
          is_active: ele.is_active,
          date: ele.date,
          shift: ele.shift,
          status: ele.status,
          worklog_gangs: ele.work_log_uuid,
        };
      });
      setRows(resultWorklogData);
    }
  }, [success_getWorklog]);

  useEffect(() => {
    getAllGroups();
    getAllShift();
  }, []);

  function dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    const diffDay = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    return true ? diffDay < 0 : false;
  }

  // const getStaffList = useSelector((state: any) => state.staff.staffList);
  // const staffListallData = allStaffList?.map((ele: any) => {
  //   return {
  //     key:Math.random()+33,
  //     id: ele.union_serial_number,
  //     value: ele.union_serial_number,
  //     name: ele.union_serial_number,
  //     label: ele.union_serial_number,
  //     group_id: ele.group_id.id,
  //   };
  // });

  // const staffListData = [...staffListallData]

  // const sortfilterdata = _.sortBy(filterStaff, ele=> parseInt(ele.value), ['asc'])
  // const sortfilterdatabyGroupID: any = _.sortBy(sortfilterdata, ele => parseInt(ele.group_id), ['asc'])

  useEffect(() => {
    if (success_getAllShift) {
      let result: any = allShiftList?.map((ele) => {
        return {
          id: ele.id,
          value: ele.id,
          label: ele.name,
        };
      });
      setShiftOption(result);
    }
  }, [success_getAllShift]);

  useEffect(() => {
    getAllOperation();
    getAllShift();
  }, []);

  useEffect(() => {
    if (success_getOperation && clickedBtn === "add") {
      const reorderedData = reorderOperations(operationList, desiredOrder);

      if (reorderedData.length) {
        // let filterOpertion = reorderedData.map((ele: OPERATIONDT) => {
        //   return (
        //     {
        //     id: ele.id,
        //     label: ele.name,
        //     value: 0,
        //   })
        // })
        const filterOpertion = reorderedData?.filter((ele: OPERATIONDT) => {
          return (
            ele.is_active === 1 && {
              id: ele.id,
              label: ele.name,
              value: 0,
            }
          );
        });

        let filterstatusOpertion = filterOpertion.map((ele: OPERATIONDT) => {
          return {
            id: ele.id,
            label: ele.name,
            value: 0,
          };
        });
        setAllOperation(filterstatusOpertion);
      }
    }
  }, [clickedBtn, operationList, success_getOperation]);

  useEffect(() => {
    if (startDate !== "") {
      let queryData = { date: startDate };
      getShiftByDate(queryData);
    }
  }, [startDate]);
  useEffect(() => {
    if (success_getShift && clickedBtn === "add") {
      if (shiftList.length > 0) {
        let filterShift: any = shiftList.map((ele) => {
          return {
            id: ele.shift?.id,
            value: ele.shift?.id,
            label: ele.shift?.name,
          };
        });
        setAllShift(filterShift);
      } else {
        setAllShift([]);
      }
    }
  }, [success_getShift, startDate]);

  useEffect(() => {
    if (selectShifts !== undefined) {
      let queryStaff = {
        date: startDate,
        shift_id: selectShifts.value,
      };
      getAllStaffByShift(queryStaff);
    }
  }, [selectShifts]);

  useEffect(() => {
    if (success_getStaff) {
      if (allStaffList?.length > 0) {
        const filterStaff = allStaffList.map((ele: any) => {
          return {
            value: ele.union_serial_number,
            label: ele.union_serial_number,
            group_id: ele.group_id.id,
          };
        });
        const sortfilterdata = _.sortBy(
          filterStaff,
          (ele) => parseInt(ele.value),
          ["asc"]
        );
        const sortfilterdatabyGroupID: any = _.sortBy(
          sortfilterdata,
          (ele) => parseInt(ele.group_id),
          ["asc"]
        );
        setAllStaffs(sortfilterdatabyGroupID);
      }
    }
  }, [success_getStaff]);

  useEffect(() => {
    if (absentData?.length > 0) {
      const filterStaff = absentData.map((ele: any) => {
        return {
          value: ele.value,
          label: ele.label,
          group_id: ele.group_id,
        };
      });
      const sortfilterdata = _.sortBy(
        filterStaff,
        (ele) => parseInt(ele.value),
        ["asc"]
      );
      const sortfilterdatabyGroupID: any = _.sortBy(
        sortfilterdata,
        (ele) => parseInt(ele.group_id),
        ["asc"]
      );
      setAllStaffs(sortfilterdatabyGroupID);
    }
  }, [worklogDetailsById]);

  const handleChangeGroups = (data: any) => {
    // if absent list data is present in present list data don't empytu absent lis else empty absent list

    setSelectedGroupData(data);
    if (presentListData) {
      const allGroupId = data.map((ele: { id: number }) => ele.id);
      setTasks({
        present_list: [],
      });
    } else {
      setTasks({
        present_list: [],
      });
    }
    setSelectGroupList(data);
  };

  const handleChangeShift = (data: SetStateAction<any>) => {
    setSelectShifts(data);
  };
  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTasks({
      present_list: [],
    });
    setStartDate(event.target.value);
  };
  const handleSelectMembers = (
    data: any,
    id: number,
    event: any,
    currRow: any
  ) => {
    if (event.action === "select-option") {
      let filterAllStaffs: any = allStaffs.filter(
        (x: any) => !data.filter((y: any) => y.value === x.value).length
      );
      setAllStaffs(filterAllStaffs);
    } else {
      let trigggerData = event.removedValue;
      let newArray: any = [...allStaffs, trigggerData];
      // setAllStaffs(newArray);
    }
    const addResultStaff = workLogs.map((item, index: number) => {
      if (index + 1 === id) {
        return { ...item, gang_member: data };
      } else {
        return item;
      }
    });

    setWorklogs(addResultStaff);
  };

  const handleChangeOperation = (
    worklogIndex: number,
    objId: number,
    val: string
  ) => {
    let updateOperations = workLogs;
    let foundObj = workLogs.find((ele, ind: number) => {
      return ind === worklogIndex;
    });
    const UpdatedOperation = foundObj.operation.map(
      (ele: { id: number }, index: number) => {
        return ele.id === objId ? { ...ele, value: val } : ele;
      }
    );
    foundObj.operation = UpdatedOperation;
    setWorklogs(
      updateOperations.map((ele, ind: number) => {
        return ind === worklogIndex ? { ...foundObj } : ele;
      })
    );
  };
  const handleChangeBags = (val: string, index: number) => {
    let updateBags = workLogs.map((ele, index2: number) =>
      index2 === index ? { ...ele, bonus: val } : ele
    );
    setWorklogs(updateBags);
  };
  const handleChangePay = (val: string, index: number) => {
    let updatePartialPay = workLogs.map((ele: WORKLOGDTO, index2: number) =>
      index2 === index ? { ...ele, partial_pay: val } : ele
    );
    setWorklogs(updatePartialPay);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const parsedWorkLog =
      worklogDetailsById &&
      Array.isArray(worklogDetailsById) &&
      worklogDetailsById.length > 0 &&
      worklogDetailsById[0].gangData?.gangWorkDetail &&
      worklogDetailsById[0].gangData?.gangWorkDetail.length > 0
        ? worklogDetailsById[0].gangData?.gangWorkDetail.map(
            (gangWorkDetail_data: any) => {
              //Gang Mambers
              const gang_member =
                gangWorkDetail_data &&
                gangWorkDetail_data.work_log_gang &&
                gangWorkDetail_data.work_log_gang.length > 0
                  ? gangWorkDetail_data.work_log_gang?.map(
                      (gang_member_data: any) => {
                        return {
                          value: gang_member_data.union_serial_number,
                          label: gang_member_data.union_serial_number,
                          group_id: gang_member_data.group_id,
                        };
                      }
                    )
                  : [];
              //Operation
              const operation =
                gangWorkDetail_data &&
                gangWorkDetail_data.work_log_details &&
                gangWorkDetail_data.work_log_details.length > 0
                  ? gangWorkDetail_data.work_log_details?.map(
                      (work_log_details_data: any) => {
                        return {
                          id: work_log_details_data.operation_id,
                          label: work_log_details_data.bag_count,
                          value: work_log_details_data.bag_count,
                        };
                      }
                    )
                  : [];
              return {
                id: worklogDetailsById[0].id,
                shift_id: worklogDetailsById[0].shift_id,
                gang_member: gang_member,
                partial_pay: gangWorkDetail_data.partial_pay_amount,
                bonus: gangWorkDetail_data.bonus_bag,
                // gang_number:
                operation: operation,
              };
            }
          )
        : [];
    setWorklogs(parsedWorkLog);

    const newOperationList = _.cloneDeep(allOperation);

    // let filterWorklogsData =transformedArray && transformedArray.map((elem: INITWORKLOGDTO) => {
    //   return {
    //     id: elem.id,
    //     shift_id: elem.shift_id,
    //     gang_member: elem.gang_member,
    //     partial_pay: String(elem.partial_pay),
    //     bonus: elem.bonus,
    //     gang_number: elem.gang_number,
    //     operation: newOperationList.map((a: OPERATIODTO) => {
    //       const exists = elem.operation.find(
    //         (b: { id: number }) => a.id == b.id,
    //       )
    //       return exists ? ((a.value = exists.value), a) : a
    //     }),
    //   }
    // })
    // console.log({ filterWorklogsData })
  }, [worklogDetailsById]);

  const handleChangeEdit = (value: any) => {
    getWorklogsById(value.id);
    // let resultData = value.worklog_gangs.map((ele: any) => {
    //   return {
    //     id: value.id,
    //     shift_id: value.shift.id,
    //     gang_member: ele.work_log_gang.map(
    //       (emp: { union_serial_number: string }) => {
    //         return {
    //           value: emp.union_serial_number,
    //           label: emp.union_serial_number,
    //         }
    //       },
    //     ),
    //     partial_pay: String(ele.partial_pay_amount),
    //     bonus: ele.bonus_bag,
    //     gang_number: ele.gang_uuid,
    //     operation: ele.work_log_gang[0]?.work_log_details.map(
    //       (opt: { operation_id: number; name: string; bag_count: number }) => {
    //         return {
    //           id: opt.operation_id,
    //           label: opt.name,
    //           value: opt.bag_count,
    //         }
    //       },
    //     ),
    //   }
    // })

    // const newOperationList = _.cloneDeep(allOperation)
    // let filterWorklogsData = resultData.map((elem: INITWORKLOGDTO) => {
    //   return {
    //     id: elem.id,
    //     shift_id: elem.shift_id,
    //     gang_member: elem.gang_member,
    //     partial_pay: String(elem.partial_pay),
    //     bonus: elem.bonus,
    //     gang_number: elem.gang_number,
    //     operation: newOperationList.map((a: OPERATIODTO) => {
    //       const exists = elem.operation.find(
    //         (b: { id: number }) => a.id == b.id,
    //       )
    //       return exists ? ((a.value = exists.value), a) : a
    //     }),
    //   }
    // })

    // console.log("filterWorklogsData",filterWorklogsData)

    setCurrentType(value);
    setStartDate(moment(value.date).format("YYYY-MM-DD"));
    setSelectShifts({
      id: value?.shift.id,
      value: value?.shift.id,
      label: value?.shift.name,
    });
    setClickedBtn("edit");
    setScreen(2);
  };
  const handleChangeDelete = (value: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWorklogsById(value.id);
      }
    });
  };

  worklogDetailsById?.attendanceDetail?.report?.map((IDData: any) => {});

  const handleSaveWorklog = () => {
    const filterGangs = workLogs.filter(
      (ele: { gang_member: MEMBERDTO[] }) => ele.gang_member.length > 0
    );

    const filterData = filterGangs.map((ele) => {
      const filterOpertions = ele.operation?.filter((item: { value: null }) => {
        return item.value !== null;
      });
      return { ...ele, operation: filterOpertions };
    });
    const filterGangData = filterData.map((ele) => {
      let datas = ele.gang_member.map((elem: any) => {
        return {
          usn: elem.label,
          group_id: elem.group_id,
        };
      });
      return { ...ele, gang_member: datas };
    });
    const filterCreateData = filterGangData.map((e, index: number) => {
      return {
        bonus_bag: e.bonus === null ? 0 : parseFloat(e.bonus),
        gang_member: e.gang_member,
        operation: e.operation?.map((ele: { id: number; value: number }) => {
          return {
            id: ele.id,
            value: ele.value.toString(),
          };
        }),
        partial_pay_amount: parseFloat(e.partial_pay),
      };
    });
    if (clickedBtn === "add") {
      const createFormData: any = {
        status: "Published",
        shift_id: selectShifts.value,
        group_Id: selectedGroupDataName,
        date: startDate,
        worklog: filterCreateData,
      };
      createAllWorklog(createFormData);
    } else if (clickedBtn === "edit") {
      const updateFormData: any = {
        id: currentType?.id,
        status: "Published",
        shift_id: selectShifts.value,
        group_Id: worklogDetailsById && worklogDetailsById[0].groupIds,
        date: currentType?.date,
        worklog: filterCreateData,
      };

      updateAllWorklog(updateFormData);
    }
  };
  const handleDraftSaveWorklog = () => {
    const filterGangMembers = workLogs.filter(
      (ele: { gang_member: MEMBERDTO[] }) => ele.gang_member.length > 0
    );
    const filterDraftData = filterGangMembers.map((ele) => {
      const filterDraftOpertions = ele.operation?.filter(
        (item: { value: null }) => {
          return item.value !== null;
        }
      );
      return { ...ele, operation: filterDraftOpertions };
    });
    const filterDraftGangData = filterDraftData.map((ele) => {
      let datas = ele.gang_member.map((elem: any) => {
        return {
          usn: elem.label,
          group_id: elem.group_id,
        };
      });
      return { ...ele, gang_member: datas };
    });
    const filterDraftCreateData = filterDraftGangData.map(
      (e, index: number) => {
        return {
          bonus_bag: e.bonus === null ? 0 : parseFloat(e.bonus),
          gang_member: e.gang_member,
          operation: e.operation?.map((ele: { id: number; value: number }) => {
            return {
              id: ele.id,
              value: ele.value,
            };
          }),
          partial_pay_amount: parseInt(e.partial_pay),
          gang_number: index + 1,
        };
      }
    );
    const createDraftFormData = {
      status: "Draft",
      shift_id: selectShifts.value,
      group_Id: selectedGroupDataName,
      date: startDate,
      worklog: filterDraftCreateData,
    };

    createDraftAllWorklog(createDraftFormData);
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const handleActionStatus = (id: number) => {
    updateStatusWorklog(id);
  };
  const handleStatusChange = (id: number) => {
    swalWithBootstrapButtons
      .fire({
        title: STATUSCHANGEHEAD,
        text: STATUSCHANGESUBHEAD,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: STATUSCHANGEPERMISSIONYES,
        cancelButtonText: STATUSCHANGEPERMISSIONNO,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleActionStatus(id);
        }
      });
  };

  const order = [2, 3, 1, 5, 4, 6];

  // Function to sort the "operation" array based on the custom order
  const sortOperationArray = (operationArray: any, order: any) => {
    return operationArray.sort((a: any, b: any) => {
      const aIndex = order.indexOf(a.id);
      const bIndex = order.indexOf(b.id);
      return aIndex - bIndex;
    });
  };

  // Sort the "operation" array within each shift object
  workLogs.forEach((shift) => {
    shift.operation = sortOperationArray(shift.operation, order);
  });

  // const columnSums: { [key: string]: number } = {};
  // workLogs.forEach((item: any) => {
  //   item.operation.forEach((op: any) => {
  //     if (columnSums[op.id.toString()]) {
  //       columnSums[op.id.toString()] += parseInt(op.value);
  //     } else {
  //       columnSums[op.id.toString()] = parseInt(op.value);
  //     }
  //   });
  // });

  // // Create table headers based on operation IDs

  // // Create table rows with sums
  // const rowss = (
  //   <>
  //     <td className="operation-value">
  //       {Object.keys(columnSums).map((id) => (
  //         <input value={columnSums[id]} disabled className="operationField" />
  //       ))}
  //     </td>
  //   </>
  // );
  // console.log(Object.keys(columnSums));
  const columnSums: { [key: string]: number } = {};
  workLogs.forEach((item: any) => {
    item.operation.forEach((op: any) => {
      if (columnSums[op.id.toString()]) {
        columnSums[op.id.toString()] += parseInt(op.value);
      } else {
        columnSums[op.id.toString()] = parseInt(op.value);
      }
    });
  });

  // Custom order
  const customOrder = [2, 3, 1, 5, 4, 6];

  // Sort the keys based on custom order
  const sortedKeys = customOrder.map((id) => id.toString());
  const totalPartialPay = workLogs.reduce(
    (accumulator, item) => accumulator + parseInt(item.partial_pay),
    0
  );
  const totalBonusPay = workLogs.reduce(
    (accumulator, item) => accumulator + parseInt(item.bonus),
    0
  );

  console.log(workLogs);
  // Create table rows with sums in the custom order
  const rowss = (
    <>
      <td className="operation-value">
        {sortedKeys.map((id) => (
          <input
            key={id}
            value={columnSums[id]}
            disabled
            className="operationField"
          />
        ))}
      </td>
    </>
  );

  console.log(sortedKeys);
  return (
    <div>
      {isLoader && <Loader />}
      <Breadcrumb pathList={paths} />

      {screen === 1 ? (
        <div>
          <div id="messageBody" className="header-toolbar row mb-4 ">
            <div className="col-lg-10 col-md-8  col-sm-8 col-xs-12">
              <FormControl size="small">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="date_style"
                    inputFormat="yyyy"
                    views={["year"]}
                    label="Year"
                    value={selectYear}
                    onChange={handleChangeYear}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl size="small">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="date_style"
                    inputFormat="MM"
                    label="Month"
                    views={["month"]}
                    value={selectMonth}
                    onChange={handleChangeMonth}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl size="small">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="date_style"
                    inputFormat="dd"
                    views={["day"]}
                    label="Date"
                    value={selectDay}
                    onChange={handleChangeDates}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl size="small" className="shift-btn  me-3">
                <Select
                  className="basic-single"
                  classNamePrefix="Select shift"
                  defaultValue={"Shift "}
                  isSearchable={true}
                  name="color"
                  options={shiftOption}
                  onChange={handleSearchShift}
                  value={sarchShift}
                  placeholder="Select shift"
                />
              </FormControl>

              <button className="btn btn-success me-2" onClick={handleFilter}>
                {SEARCH}
              </button>
              <button
                className="btn btn-secondary me-2"
                onClick={handleClearFilter}
              >
                {CLEAR}
              </button>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 text-end">
              {role_function?.Worklog.Create_New_Worklog && (
                <Button
                  variant="contained"
                  className="attendance-btn"
                  onClick={handleClickAddScreen}
                >
                  {ADDWORKLOG}
                </Button>
              )}
            </div>
          </div>

          <div>
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{WORKLOGDATE}</StyledTableCell>
                      <StyledTableCell align="left">
                        {WORKLOGSHIFT}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {WORKLOGWORKINGSTATUS}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {WORKLOGSTATUS}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {WORKLOGACTION}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell component="th" scope="row">
                                {row.date}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.shift.name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.status}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <StatusChip value={row.is_active} />
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleChangeEdit(row)}
                                >
                                  <Edit2
                                    size="22"
                                    color="#565e64"
                                    variant="Outline"
                                  />
                                </span>

                                {role_function?.Worklog
                                  .Update_Worklog_Detail && (
                                  <span style={{ cursor: "pointer" }}>
                                    <Tooltip
                                      arrow
                                      placement="right"
                                      title="Status"
                                    >
                                      <Switch
                                        checked={
                                          row.is_active === 1 ? true : false
                                        }
                                        onChange={() =>
                                          handleStatusChange(row.id)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleChangeDelete(row)}
                                  className="mx-2"
                                >
                                  <Trash
                                    size="22"
                                    color="#565e64"
                                    variant="Outline"
                                  />
                                </span>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          {NODATAFOUND}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  className="pagination-table-bg"
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>
          </div>
        </div>
      ) : (
        workLogs && (
          <div>
            <div className="header-bar align-items-start">
              <div>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                  {PAGEHEADING}
                </Typography>
              </div>

              <span className="error_msg">
                <ToastContainer />
              </span>
              <div className="header-toolbar align-items-center mb-5">
                <div>
                  <Button
                    variant="contained"
                    className="excel-import-btn"
                    onClick={handleImportFormOpen}
                  >
                    Import
                  </Button>
                </div>
                <TextField
                  disabled={clickedBtn === "edit" ? true : false}
                  size="small"
                  className="mx-2"
                  id="outlined"
                  type="date"
                  value={startDate}
                  defaultValue={startDate}
                  onChange={handleChangeDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment(date).format("YYYY-MM-DD"),
                  }}
                />
                <FormControl
                  size="small"
                  className="shift-btn"
                  style={{
                    display: clickedBtn === "edit" ? "none" : "block",
                  }}
                >
                  <Select
                    isDisabled={clickedBtn === "edit" ? true : false}
                    defaultValue={selectGroupList.value}
                    placeholder={"Select Group"}
                    isMulti
                    name="colors"
                    options={masterGrp}
                    className="basic-multi-select"
                    classNamePrefix="Select groups"
                    onChange={handleChangeGroups}
                  />
                </FormControl>

                <FormControl size="small" className="shift-btn">
                  <Select
                    className="basic-single"
                    classNamePrefix="Select shift"
                    placeholder={"Select Shift"}
                    isSearchable={true}
                    name="colors"
                    options={masterShft}
                    onChange={handleChangeShift}
                    isDisabled={clickedBtn === "edit" ? true : false}
                    value={selectShifts}
                  />
                </FormControl>

                <div>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    className="attendance-btn"
                    onClick={handleSaveWorklog}
                  >
                    {clickedBtn === "add" ? SAVEBTNLABEL : UPDATEBTNLABEL}
                  </Button>
                  <Button
                    hidden={clickedBtn === "edit" ? true : false}
                    variant="contained"
                    disabled={disabled}
                    className="attendance-btn"
                    onClick={handleDraftSaveWorklog}
                  >
                    {DRAFTBTNLABEL}
                    {/* <TextBlock size="25" color="#fff" /> */}
                  </Button>
                </div>

                <div>
                  <Button className="back-btn" onClick={handleClickBackscreen}>
                    <ArrowLeft size="22" color="#484848" variant="Outline" />
                  </Button>
                </div>
              </div>
            </div>

            <Paper sx={{ marginBottom: 3 }}>
              <div className="table-responsive overflow-visible">
                <table className="table table-striped bg-red worklog_table">
                  <thead>
                    <tr>
                      <th style={{ verticalAlign: "top", background: "#fff" }}>
                        {TBLGANGLABEL}
                      </th>
                      <th style={{ width: "30%", background: "#fff" }}>
                        {TBLOPERATIONLABEL}
                        <td className="mainOperation">
                          {allOperation.length
                            ? allOperation.map((op: any, objIndex: number) => {
                                return (
                                  <span
                                    className="operationName"
                                    key={objIndex}
                                  >
                                    {op.label}
                                  </span>
                                );
                              })
                            : null}
                        </td>
                      </th>

                      <th style={{ verticalAlign: "top", background: "#fff" }}>
                        {TBLBONUSLABEL}
                      </th>
                      <th style={{ verticalAlign: "top", background: "#fff" }}>
                        {TBLPARTIALPAYLABEL}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {workLogs?.length &&
                    Array.isArray(workLogs) &&
                    workLogs.length > 0
                      ? workLogs?.map((obj, index: number) => {
                          const key = index;
                          return (
                            <tr key={key}>
                              <td className="gang_member-select">
                                <Select
                                  isClearable={false}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  name="label"
                                  options={allStaffs}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(data, event) =>
                                    handleSelectMembers(
                                      data,
                                      index + 1,
                                      event,
                                      obj
                                    )
                                  }
                                  value={obj.gang_member}
                                />
                              </td>
                              <td className="operation-value">
                                {obj?.operation?.length
                                  ? obj?.operation.map((op: any) => {
                                      <>{console.log(obj?.operation)}</>;
                                      return (
                                        <WorkLogOperationInput
                                          className="operationField"
                                          key={`operation_${obj.id}_${op.id}`}
                                          id={`operation_${obj.id}_${op.id}`}
                                          value={String(op.value)}
                                          onChange={(val) => {
                                            handleChangeOperation(
                                              index,
                                              op.id,
                                              val
                                            );
                                          }}
                                        />
                                      );
                                    })
                                  : null}
                              </td>
                              <td className="bonus-bag">
                                {/* <input
                                min={0}
                                type="number"
                                className="operationField"
                                onChange={(event) =>
                                  handleChangeBags(event, index)
                                }
                                value={obj.bonus}
                              /> */}
                                <WorkLogOperationInput
                                  className="operationField"
                                  key={obj.id}
                                  id={obj.id}
                                  value={obj.bonus}
                                  onChange={(val) => {
                                    handleChangeBags(val, index);
                                  }}
                                />
                              </td>
                              <td className="partial-pay">
                                <WorkLogOperationInput
                                  className="operationField"
                                  key={obj.id}
                                  id={obj.id}
                                  value={obj.partial_pay}
                                  onChange={(val) => {
                                    handleChangePay(val, index);
                                  }}
                                />
                              </td>
                              <td>
                                <button
                                  className="btn me-3"
                                  onClick={() => removeRowTable(index)}
                                >
                                  <Trash
                                    size="22"
                                    color="red"
                                    variant="Outline"
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                    <tr>
                      <td className="gang_member-select"></td>
                      {rowss}
                      <td className="bonus-bag">
                        <input
                          value={totalBonusPay}
                          disabled
                          className="operationField"
                        />
                      </td>
                      <td className="partial-pay">
                        <input
                          value={totalPartialPay}
                          disabled
                          className="operationField"
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Paper>
            <button className="btn btn-success" onClick={addRowTable}>
              {ADDROW}
            </button>
            <Dialog open={isImportFormOpen} onClose={handleImportFormClose}>
              <DialogTitle>Import worklog via excel.</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To import a worklog, please provide date and excel file.
                </DialogContentText>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {importErrorMsg ? (
                      <Grid xs={12}>
                        <div style={{ color: "red" }}>{importErrorMsg}</div>
                      </Grid>
                    ) : null}
                    <Grid xs={12}>
                      <TextField
                        size="small"
                        className="date-input"
                        id="outlined"
                        type="date"
                        defaultValue={importForDate}
                        value={importForDate}
                        onChange={(e) => {
                          setImportForDate(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ max: moment(date).format("YYYY-MM-DD") }}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <input
                        type="file"
                        accept=".xls, .xlsx"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e?.target?.files?.[0]) {
                            setImportFile(e.target.files[0]);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleImportFormClose}>Cancel</Button>
                <Button
                  type="submit"
                  onClick={handleImportFormSubmit}
                  disabled={importForDate === "" || importFile === null}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )
      )}
    </div>
  );
};
export default Worklog;
