import React, { SyntheticEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MdClose } from 'react-icons/md';
import { FormProps, ISubmitResult } from './types';
import FormTextField from 'components/common/textField/textField';
import { ADDNEWLABEL, UPDATELABEL, SAVEBTN, UPDATEBTN, DATEERROR, DATELABEL, DATEPLACEHOLDER, CANCELBTN, EEERROR, EEPLACEHOLDER, EELABEL, EPSERROR, EPSLABEL, EPSPLACEHOLDER, ERERROR, ERLABEL, ERPLACEHOLDER, CAPPLACEHOLDER, CAPLABEL, CAPERROR } from './epfString';
import moment from 'moment';
import "../style.scss"
const EpfForm = (props: FormProps) => {
    const {
        initialValue = {},
        handleFormData,
        onHandleClose,
        clickedBtn,
        errorMessage
    } = props;

    const [percentageEr, setPercentageEr] = useState<ISubmitResult>({
        value: initialValue.er_percent
            ? initialValue.er_percent : "",
        error: false,
        success: false,
    });

    const [percentageEps, setPercentageEps] = useState<ISubmitResult>({
        value: initialValue.eps_percent
            ? initialValue.eps_percent : "",
        error: false,
        success: false,
    });
    const [percentageEE, setPercentageEE] = useState<ISubmitResult>({
        value: initialValue.ee_percent
            ? initialValue.ee_percent : "",
        error: false,
        success: false,
    });
    const [effectDate, setEffectDate] = useState<ISubmitResult>({
        value: initialValue.effective_from
            ? moment(initialValue.effective_from).format('YYYY-MM-DD') : "",
        error: false,
        success: false,
    });
    const [capHold, setCapHold] = useState<ISubmitResult>({
        value: initialValue
            ? initialValue?.cap_at : 0,
        error: false,
        success: false,
    }); 

    const [arrearEndDate, setArrearEndDate] = useState<ISubmitResult>({
        value: initialValue.arrear_end_date
          ? moment(initialValue.arrear_end_date).format("YYYY-MM-DD")
          : "",
        error: false,
        success: false,
      });
    
    const [isArriear, setIsArriear] = React.useState<boolean>(
        initialValue ? (initialValue.is_arrear  === 1 ? true : false) : false
      );
      let initarrearEndDate = initialValue.arrear_end_date? initialValue.arrear_end_date : ""
    let initArriear = initialValue?.is_arrear === 1 ? true : false;
    
    let editDisable =
        percentageEr.value == initialValue.er_percent &&
        percentageEps.value === initialValue.eps_percent &&
        percentageEE.value === initialValue.ee_percent &&
        effectDate.value === initialValue.effective_from &&
        capHold.value === initialValue.cap_at&&
        arrearEndDate.value === initarrearEndDate &&
        isArriear === initArriear;
    
    let disable =
        percentageEr.error ||
        percentageEps.error ||
        percentageEE.error ||
        effectDate.error ||
        percentageEr.value === "" ||
        percentageEps.value === "" ||
        percentageEE.value === "" ||
        effectDate.value === "" ||
        (percentageEr.success === false &&
            percentageEps.success === false &&
            percentageEE.success === false &&
            effectDate.success === false ) 

    const handleChangeEr = (value: ISubmitResult) => {
        setPercentageEr(value);
    };
    const handleChangeEps = (value: ISubmitResult) => {
        setPercentageEps(value);
    };
    const handleChangeEe = (value: ISubmitResult) => {
        setPercentageEE(value);
    };
    const handleChangeDate = (value: ISubmitResult) => {
        setEffectDate(value);
    };
    const handleChangeCap = (value: ISubmitResult) => {
        setCapHold(value);
    };
    const handleChangeArrearDate = (value: ISubmitResult) => {
        setArrearEndDate(value);
    };
    
    const handleCheckArrear = (
        event: SyntheticEvent<Element, Event>,
        checked: boolean
      ) => {
        setIsArriear(checked);
      };

    const handleSubmitForm = () => {
    let data
        if (!isArriear && clickedBtn === 'edit') {
            data = {
                is_arrear: isArriear,
                er_percent: parseFloat(percentageEr.value),
                eps_percent: parseFloat(percentageEps.value),
                ee_percent: parseFloat(percentageEE.value),
                effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
                cap_at:capHold.value ? parseFloat(capHold.value) : 0,
                is_active: initialValue?.is_active
            }
        } else {
            data = {
                is_arrear: isArriear,
                arrear_end_date:arrearEndDate.value,
                er_percent: parseFloat(percentageEr.value),
                eps_percent: parseFloat(percentageEps.value),
                ee_percent: parseFloat(percentageEE.value),
                effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
                cap_at: capHold.value ? parseFloat(capHold.value) : 0,
                is_active: initialValue?.is_active
            }
}
        handleFormData(data)
    }

    return (
        <Card sx={{ boxShadow: "none" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={onHandleClose}>
                        <MdClose />
                    </IconButton>
                }
                title={clickedBtn === "add" ? ADDNEWLABEL : UPDATELABEL}
            />
            {errorMessage ?<span className='error_msg'>{errorMessage}</span>:null}
            <CardContent>
                <Box
                    component="form"
                    sx={{                                                                                                                                                                                                       
                        '& .MuiTextField-root': { mb: 2 },
                    }}
                    className={"formResponsiveHeight"}
                    noValidate={true}
                >
                    <FormTextField
                        type="price"
                        placeholder={ERPLACEHOLDER}
                        label={ERLABEL}
                        Value={percentageEr.value}
                        onChangeText={handleChangeEr}
                        Required={true}
                        CustomErrorLine={ERERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={EPSPLACEHOLDER}
                        label={EPSLABEL}
                        Value={percentageEps.value}
                        onChangeText={handleChangeEps}
                        Required={true}
                        CustomErrorLine={EPSERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={EEPLACEHOLDER}
                        label={EELABEL}
                        Value={percentageEE.value}
                        onChangeText={handleChangeEe}
                        Required={true}
                        CustomErrorLine={EEERROR}
                    />
                    <FormTextField
                        type="Edate"
                        hidden={clickedBtn === "edit" ? !isArriear : false}
                        placeholder={DATEPLACEHOLDER}
                        label={DATELABEL}
                        Value={effectDate.value}
                        onChangeText={handleChangeDate}
                        Required={true}
                        CustomErrorLine={DATEERROR}
                    />

                    <FormTextField
                        type="price"
                        placeholder={CAPPLACEHOLDER}
                        label={CAPLABEL}
                        Value={capHold.value}
                        onChangeText={handleChangeCap}
                        Required={true}
                        CustomErrorLine={CAPERROR}
                    />

                    <FormTextField
                        hidden={!isArriear}
                        type="Edate"
                        placeholder={"Arrear End Date"}
                        label={"Arrear End Date"}
                        Value={arrearEndDate.value}
                        onChangeText={handleChangeArrearDate}
                        Required={false}
                        CustomErrorLine={DATEERROR}
                    />
                    
                    <FormControlLabel
                        sx={{ mb: 1 }}
                        value={isArriear}
                        control={<Checkbox checked={isArriear} />}
                        label="Is arrear"
                        labelPlacement="end"
                        onChange={handleCheckArrear}
                    />

                </Box>
            </CardContent>
            <CardActions className='d-flex dust justify-content-end'>
                <Button size="large" variant="outlined" onClick={onHandleClose}>{CANCELBTN}</Button>
                <Button size="large" variant="contained" disabled={clickedBtn === "add" ? disable : editDisable} onClick={handleSubmitForm}>{clickedBtn === "add" ? SAVEBTN : UPDATEBTN}</Button>
            </CardActions>
        </Card>
    );
}

export default EpfForm;
