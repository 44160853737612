import strings from "translation";

export const ADDBTNLABEL = strings["FORM.INCENTIVE.ADDBTNLABEL"];
export const UPDATEBTNLABEL = strings["FORM.INCENTIVE.UPDATEBTNLABEL"];
export const SLABPLACEHOLDER = strings["FORM.INCENTIVE.SLABPLACEHOLDER"];
export const SLABLABEL = strings["FORM.INCENTIVE.SLABLABEL"];
export const SLABCUSTOMERR = strings["FORM.INCENTIVE.SLABCUSTOMERR"];
export const HIKEPLACEHOLDER = strings["FORM.INCENTIVE.HIKEPLACEHOLDER"];
export const HIKELABEL = strings["FORM.INCENTIVE.HIKELABEL"];
export const HIKECUSTOMERR = strings["FORM.INCENTIVE.HIKECUSTOMERR"];
export const DATEPLACEHOLDER = strings["FORM.INCENTIVE.DATEPLACEHOLDER"];
export const DATELABEL = strings["FORM.INCENTIVE.DATELABEL"];
export const DATECUSTOMERR = strings["FORM.INCENTIVE.DATECUSTOMERR"];
export const OPERATIONPLACEHOLDER = strings["FORM.INCENTIVE.OPERATIONPLACEHOLDER"];
export const OPERATIONLABEL = strings["FORM.INCENTIVE.OPERATIONLABEL"];;
export const OPERATIONCUSTOMERR = strings["FORM.INCENTIVE.OPERATIONCUSTOMERR"];

export const RANGEPLACEHOLDER = strings["FORM.INCENTIVE.RANGEPLACEHOLDER"];
export const RANGELABEL = strings["FORM.INCENTIVE.RANGELABEL"];
export const RANGECUSTOMERR = strings["FORM.INCENTIVE.RANGECUSTOMERR"];

export const SAVEBTN = strings["FORM.INCENTIVE.SAVEBTN"];
export const UPDATEBTN = strings["FORM.INCENTIVE.UPDATEBTN"];
export const CANCELBTN = strings["FORM.INCENTIVE.CANCELBTN"];