import { axios } from "api/setup.intersepter";
import { RoleRouteRequestDTO } from "./types";

export const getAllRoles = () => {
  return axios({
    method: "GET",
    url: "/auth/roles",
  });
};

export const getAllFunctions = () => {
  return axios({
    method: "GET",
    url: "/auth/routes",
  });
};

export const getAllRoleFunctionMap = () => {
  return axios({
    method: "GET",
    url: `/auth/roles/role_route`,
  });
};

export const createRoleFunctionMap = (body: RoleRouteRequestDTO) => {
  return axios({
    method: "PUT",
    url: `auth/roles/routes`,
    data: { role_route_map: [body] },
  });
};


