import React, { SyntheticEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MdClose } from 'react-icons/md';
import { FormProps, ISubmitResult } from './types';
import FormTextField from 'components/common/textField/textField';
import { ADDHEADER, CANCELBTN, DATEERROR, DATELABEL, DATEPLACEHOLDER, CATEGORYERROR, CATEGORYLABEL, CATEGORYPLACEHOLDER, SAVEBTN, UPDATEBTN, UPDATEHEADER, DATTUMLABEL, DATTUMPLACEHOLDER, DATTUMERROR } from './categoryString';
import moment from 'moment';

const OperaionCatForm = (props: FormProps) => {
    const {
        initialValue = {},
        handleFormData,
        onHandleClose,
        clickedBtn,
        errorMessage
    } = props;


    
    
    const [category, setCategory] = useState<ISubmitResult>({
        value: initialValue.name
            ? initialValue.name : "",
        error: false,
        success: false,
    });

    const [dattum, setDattum] = useState<ISubmitResult>({
        value: initialValue.datum
            ? initialValue.datum : "",
        error: false,
        success: false,
    });

    const [pagePerAmount, setPagePerAmount] = useState<ISubmitResult>({
        value: initialValue.per_bag_amount
            ? initialValue.per_bag_amount : "",
        error: false,
        success: false,
    });


    const [effectDate, setEffectDate] = useState<ISubmitResult>({
        value: initialValue.effective_from
            ? moment(initialValue.effective_from).format('YYYY-MM-DD') : "",
        error: false,
        success: false,
    });

    const [arrearEndDate, setArrearEndDate] = useState<ISubmitResult>({
        value: initialValue.arrear_end_date
          ? moment(initialValue.arrear_end_date).format("YYYY-MM-DD")
          : "",
        error: false,
        success: false,
      });
    
    const [isArriear, setIsArriear] = React.useState<boolean>(
        initialValue ? (initialValue.is_arrear  === 1 ? true : false) : false
      );
      let initarrearEndDate = initialValue.arrear_end_date? initialValue.arrear_end_date : ""
      let initArriear = initialValue?.is_arrear === 1 ? true : false;
    
    
    let editDisable =
        category.value === initialValue.name &&
        dattum.value === initialValue.datum &&
        effectDate.value === initialValue.effective_from &&
        arrearEndDate.value === initarrearEndDate &&
        pagePerAmount.value === initialValue.per_bag_amount &&
        isArriear === initArriear;
    
    let disable =
        category.error ||
        dattum.error ||
        effectDate.error ||
        pagePerAmount.error ||
        category.value === "" ||
        dattum.value === "" ||
        effectDate.value === "" ||
        pagePerAmount.value === "" ||
        (category.success === false &&
        dattum.success === false &&
        effectDate.success === false &&
        pagePerAmount.success === false)

    const handleChangeRoleName = (value: ISubmitResult) => {
        setCategory(value);
    };
    const handleChangeDattum = (value: ISubmitResult) => {
        setDattum(value);
    };
    const handleChangepagePerAmount= (value: ISubmitResult) => {
        setPagePerAmount(value);
    };
    const handleChangeDescription = (value: ISubmitResult) => {
        setEffectDate(value)
    }
    const handleChangeArrearDate = (value: ISubmitResult) => {
        setArrearEndDate(value);
    };
    
    const handleCheckArrear = (
        event: SyntheticEvent<Element, Event>,
        checked: boolean
      ) => {
        setIsArriear(checked);
      };

    
    const handleSubmitForm = () => {
        const data = {
            is_arrear: isArriear,
            arrear_end_date:arrearEndDate.value,
            name: category.value,
            effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
            datum: parseInt(dattum.value),
            per_bag_amount:pagePerAmount.value
        }
        handleFormData(data)
    }

    return (
        <Card sx={{ boxShadow: "none" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={onHandleClose}>
                        <MdClose />
                    </IconButton>
                }
                title={clickedBtn === "add" ? ADDHEADER : UPDATEHEADER}
            />
            {errorMessage?<span className='error_msg'>{errorMessage}</span>:null}
            <CardContent>
                <Box
                    component="form"
                    sx={{
                        width: '100%',
                        '& .MuiTextField-root': { mb: 2 },
                    }}
                    noValidate={true}
                >
                    <FormTextField
                        type="onlyAlphaMax_50"
                        placeholder={CATEGORYPLACEHOLDER}
                        label={CATEGORYLABEL}
                        Value={category.value}
                        onChangeText={handleChangeRoleName}
                        Required={true}
                        CustomErrorLine={CATEGORYERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={DATTUMPLACEHOLDER}
                        label={DATTUMLABEL}
                        Value={dattum.value}
                        onChangeText={handleChangeDattum}
                        Required={true}
                        CustomErrorLine={DATTUMERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={"Per Page Amount"}
                        label={"Per Page Amount"}
                        Value={pagePerAmount.value}
                        onChangeText={handleChangepagePerAmount}
                        Required={true}
                        CustomErrorLine={DATTUMERROR}
                    />
                    <FormTextField
                        // hidden={clickedBtn === "edit" ? !isArriear : false}
                        type="Edate"
                        placeholder={DATEPLACEHOLDER}
                        label={DATELABEL}
                        Value={effectDate.value}
                        onChangeText={handleChangeDescription}
                        Required={true}
                        CustomErrorLine={DATEERROR}
                    />
                    {clickedBtn === "edit" ? <FormTextField
                        hidden={!isArriear}
                        type="Edate"
                        placeholder={"Arrear End Date"}
                        label={"Arrear End Date"}
                        Value={arrearEndDate.value}
                        onChangeText={handleChangeArrearDate}
                        Required={false}
                        CustomErrorLine={DATEERROR}
                    />:null }

                    {clickedBtn === "edit" ? <FormControlLabel
                        sx={{ mb: 1 }}
                        value={isArriear}
                        control={<Checkbox checked={isArriear} />}
                        label="Is arrear"
                        labelPlacement="end"
                        onChange={handleCheckArrear}
                        />:null }
                    
                </Box>
            </CardContent>
            <CardActions className='d-flex dust justify-content-end'>
                <Button size="large" variant="outlined" onClick={onHandleClose}>{CANCELBTN}</Button>
                <Button size="large" variant="contained" disabled={clickedBtn === "add" ? disable : editDisable} onClick={handleSubmitForm}>{clickedBtn === "add" ? SAVEBTN : UPDATEBTN}</Button>
            </CardActions>
        </Card>
    );
}

export default OperaionCatForm;
