import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import "./styles.scss";
import  { ScaleLoader  } from 'react-spinners';
const Loader = () => {
    return (

        <div className="loader-container">
          
       <ScaleLoader  className="spinner" color="#fff" />
        </div>
    );
}

export default Loader;
