import strings from "translation";
export const ADDGROUP = strings["HEADER.GROUP.ADDGROUP"];
export const STAFF = strings["HEADER.GROUP.STAFF"];
export const TBLGRP = strings["HEADER.GROUP.TBLGRP"];
export const TBLDATE = strings["HEADER.GROUP.TBLDATE"];
export const TBLNODATA = strings["HEADER.GROUP.TBLNODATA"];
export const TBLGRPHEAD = strings["HEADER.GROUP.TBLGRPHEAD"];
export const STATUSLABEL = strings["HEADER.GROUP.STATUSLABEL"];
export const HOMEPATH = strings["HEADER.GROUP.HOMEPATH"];
export const CURRENTPATH = strings["HEADER.GROUP.CURRENTPATH"];
export const SWALSUCEETITLE: string = strings["STAFF.ADD.SUCEESS.TITLE"];
export const SWALSUCEETEXT: string = strings["STAFF.ADD.SUCEESS.TEXT"];
export const SWALUPDATESUCEESTITLE: string = strings["STAFF.UPDATE.SUCEESS.TITLE"];
export const SWALUPDATESUCEESTEXT: string = strings["STAFF.UPDATESUCEESS.TEXT"];
export const SWALSTATUSSUCEESTITLE: string = strings["STAFF.STATUS.SUCEESS.TITLE"];
export const SWALSTATUSSUCEESTEXT: string = strings["STAFF.STATUSESUCEESS.TEXT"];
export const CURRENT_SUCCESS: string = strings["SUCCESS.CREATED.SUCCESSFULLY"];
export const CURRENT_SUCCESS_UPDATED: string = strings["SUCCESS.CREATED.SUCCESSFULLY.UPDATED"];
export const CURRENT_SUCCESS_CREATED: string = strings["SUCCESS.CREATED.SUCCESSFULLY.CREATED"];
export const STATUSCHANGEHEAD = strings["STATUS.CHANGE.PERMISSION.HEADING"];
export const STATUSCHANGESUBHEAD = strings["STATUS.CHANGE.PERMISSION.SUBHEADING"];
export const STATUSCHANGEPERMISSIONYES = strings["STATUS.CHANGE.PERMISSION.YES"];
export const STATUSCHANGEPERMISSIONNO = strings["STATUS.CHANGE.PERMISSION.NO"];
export const STATUSCHANGEDELETED = strings["STATUS.CHANGE.DELETED"];
export const STATUSDELETEDSUBHEAD = strings["STATUS.CHANGE.DELETED.SUBHEAD"];
export const STATUSDELETEDSUCCESS = strings["STATUS.CHANGE.DELETED.SUCCESS"];
export const STATUSDELETEDSCANCEL = strings["STATUS.CHANGE.CANCELLED"];
export const STATUSDELETEDSCANCELSHEAD = strings["STATUS.CHANGE.CANCELLED"];
export const STATUSDELETEDSCANCELERROR = strings["STATUS.CHANGE.CANCELLED.ERROR"];

