import { connect } from 'react-redux';
import { RootState, TypedDispatch } from "redux/store";
import { fetchAllEpfReport, fetchAllGroups } from "../service/epfreportslice";
import EpfReport from '../component/epfReport';
import epfReport from '../component/epfReport';
import { RoleFunDTO } from 'pages/auth/signin/service/types';
import { getRole } from 'pages/auth/signin/service/userSlice';
import { EpfReportresponseDTO, GetRequestDTO } from '../service/types';

interface StateProps{
    loading_getGroup: boolean;
    success_getGroup: boolean;
    error_getGroup: string | boolean | undefined;

    loading_getEpfReport : boolean;
    success_getEpfReport : boolean;
    error_getEpfReport : boolean;
    epfReportList?: EpfReportresponseDTO |null|undefined
    role_function: RoleFunDTO | undefined
}

interface DispatchProps{
    getAllGroup(): void;
    getEpfReport(data: GetRequestDTO): void;
    getRoleFuncn(): void
}

const mapStateToProps = (state: RootState):StateProps => {
    return {
        error_getGroup: state.epfreport.error_getGroup,
        loading_getGroup: state.epfreport.loading_getGroup,
        success_getGroup: state.epfreport.success_getGroup,
        loading_getEpfReport: state.epfreport.loading_getEpfReport,
        success_getEpfReport: state.epfreport.success_getEpfReport,
        error_getEpfReport: state.epfreport.error_getEpfReport,
        epfReportList: state.epfreport.epfReportList,
        role_function: state.userLogin.role_function
    };
};


const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
    return {
        getAllGroup: () => {
            dispatch(fetchAllGroups());
        },
        getEpfReport:(data) =>{
            dispatch(fetchAllEpfReport(data));
        },
        getRoleFuncn: () => {
            dispatch(getRole());
          }
    }
};
export interface EpfReportProps extends StateProps, DispatchProps { }
export const EpfReportComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(epfReport);
