import strings from "translation";

export const LOGINHEADING = strings["HEADER.LOGIN"];
export const LOGINSUBHEAD = strings["HEADER.LOGINSUBHEAD"];

export const EMAILPLACEHOLDER = strings["PLACEHOLDER.EMAILPLACEHOLDER"];
export const PASSWORDPLACEHOLDER = strings["PLACEHOLDER.PASSWORDPLACEHOLDER"];
export const FORGOTPASSWORD = strings["LABEL.FORGOTPASSWORD"];
export const SUBMITLOGIN = strings["BTN.SUBMITLOGIN"];

export const LOGINIDREQUIRED = strings["ERROR.USER.LOGIN.REQUIRED"]
export const LOGINPASSWORDREQUIRED = strings["ERROR.USER.PASSWORD.REQUIRED"]
