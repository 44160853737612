import {
  Bag2,
  Bank,
  Calendar,
  Data,
  EmptyWalletAdd,
  Folder,
  Home2,
  Lock1,
  LoginCurve,
  Moneys,
  MoneyTick,
  Moon,
  People,
  Profile2User,
  UserTick,
  Wallet1,
} from "iconsax-react";
import {
  MENU_ATTENDANCE,
  MENU_AUTH,
  MENU_BANK,
  MENU_BANK_REPORT,
  MENU_EPF,
  MENU_ESI,
  MENU_FH_CONFIG,
  MENU_GENERATE_REPORT,
  MENU_GROUP,
  MENU_HOLIDAY,
  MENU_INCENTIVE,
  MENU_MATERIAL,
  MENU_MUSTER_ROLL,
  MENU_OPERATION,
  MENU_OPERATION_CATE,
  MENU_REPORT,
  MENU_REPORT_ATTENDANCE,
  MENU_REVISE_ESI,
  MENU_REVISE_PF,
  MENU_ROLE,
  MENU_ROLE_FUNCTION,
  MENU_SHIFT,
  MENU_STAFF,
  MENU_USER,
  MENU_WAGE,
  MENU_WAGE_COMPONENT,
  MENU_WAGE_SLIP,
  MENU_WORKLOG,
} from "./menuListString";

export const MenuItsmsList: any = [
  // {
  //   id: 1,
  //   name: MENU_DASHBOARD,
  //   keyname:"dashboard",
  //   icon: <Category size="20" color="#53545C" variant="Outline" />,
  //   iconActive: <Category size="20" color="#fff" variant="Bold" />,
  //   route: '/',
  // },
  {
    id: 16,
    name: MENU_ATTENDANCE,
    keyname: "attendance",
    icon: <Calendar size="20" color="#53545C" variant="Outline" />,
    iconActive: <Calendar size="20" color="#fff" variant="Bold" />,
    route: "/",
  },
  {
    id: 2,
    name: MENU_BANK,
    keyname: "bank",
    icon: <Bank size="20" color="#53545C" variant="Outline" />,
    iconActive: <Bank size="20" color="#fff" variant="Bold" />,
    route: "/bank",
  },
  {
    id: 3,
    name: MENU_GROUP,
    keyname: "group",
    icon: <People size="20" color="#53545C" variant="Outline" />,
    iconActive: <People size="20" color="#fff" variant="Bold" />,
    route: "/group",
  },
  {
    id: 4,
    name: MENU_OPERATION_CATE,
    keyname: "operationcategory",
    icon: <Home2 size="20" color="#53545C" variant="Outline" />,
    iconActive: <Home2 size="20" color="#fff" variant="Bold" />,
    route: "/optioncategory",
  },
  {
    id: 5,
    name: MENU_OPERATION,
    keyname: "operation",
    icon: <Profile2User size="20" color="#53545C" variant="Outline" />,
    iconActive: <Profile2User size="20" color="#fff" variant="Bold" />,
    route: "/operation",
  },

  {
    id: 6,
    keyname: "staff",
    name: MENU_STAFF,
    icon: <Bag2 size="20" color="#53545C" variant="Outline" />,
    iconActive: <Bag2 size="20" color="#fff" variant="Bold" />,
    route: "/staff",
  },

  {
    id: 7,
    name: MENU_MATERIAL,
    keyname: "material",
    icon: <Folder size="20" color="#53545C" variant="Outline" />,
    iconActive: <Folder size="20" color="#fff" variant="Bold" />,
    route: "/material",
  },
  {
    id: 8,
    name: MENU_WAGE,
    icon: <Moneys size="20" color="#53545C" variant="Outline" />,
    iconActive: <Moneys size="20" color="#fff" variant="Bold" />,
    sublist: [
      {
        id: 9,
        name: MENU_WAGE_COMPONENT,
        keyname: "wage",
        icon: <Profile2User size="20" color="#53545C" variant="Outline" />,
        route: "/wage",
      },
      {
        id: 10,
        keyname: "revisepf",
        name: MENU_REVISE_PF,
        icon: <UserTick size="20" color="#53545C" variant="Outline" />,
        route: "/revisepf",
      },
      {
        id: 11,
        name: MENU_REVISE_ESI,
        keyname: "revisesi",
        icon: <UserTick size="20" color="#53545C" variant="Outline" />,
        route: "/revisesi",
      },
    ],
  },

  {
    id: 12,
    name: MENU_INCENTIVE,
    keyname: "incentive",
    icon: <MoneyTick size="20" color="#53545C" variant="Outline" />,
    iconActive: <MoneyTick size="20" color="#fff" variant="Bold" />,
    route: "/incentive",
  },
  {
    id: 13,
    name: MENU_HOLIDAY,
    keyname: "holiday",
    icon: <Calendar size="20" color="#53545C" variant="Outline" />,
    iconActive: <Calendar size="20" color="#fff" variant="Bold" />,
    route: "/holiday",
  },
  {
    id: 14,
    name: MENU_FH_CONFIG,
    keyname: "holidayconfig",
    icon: <Home2 size="20" color="#53545C" variant="Outline" />,
    iconActive: <Home2 size="20" color="#fff" variant="Bold" />,
    route: "/holidayconfig",
  },
  {
    id: 15,
    name: MENU_SHIFT,
    keyname: "shift",
    icon: <Moon size="20" color="#53545C" variant="Outline" />,
    iconActive: <Moon size="20" color="#fff" variant="Bold" />,
    route: "/shift",
  },

  {
    id: 17,
    name: MENU_WORKLOG,
    keyname: "worklog",
    icon: <LoginCurve size="20" color="#53545C" variant="Outline" />,
    iconActive: <LoginCurve size="20" color="#fff" variant="Bold" />,
    route: "/worklog",
  },
  {
    id: 18,
    name: MENU_REPORT,
    icon: <Data size="20" color="#53545C" variant="Outline" />,
    iconActive: <Data size="20" color="#fff" variant="Bold" />,
    sublist: [
      {
        id: 19,
        name: MENU_GENERATE_REPORT,
        keyname: "generatereports",
        icon: <Calendar size="20" color="#53545C" variant="Outline" />,
        route: "/generate-reports",
      },
      {
        id: 20,
        name: MENU_REPORT_ATTENDANCE,
        keyname: "attendancereport",
        icon: <Calendar size="20" color="#53545C" variant="Outline" />,
        route: "/attendancereport",
      },
      {
        id: 21,
        name: MENU_EPF,
        keyname: "epfreport",
        icon: <Wallet1 size="20" color="#53545C" variant="Outline" />,
        route: "/epfreport",
      },
      {
        id: 22,
        name: MENU_ESI,
        keyname: "esireport",
        icon: <EmptyWalletAdd size="20" color="#53545C" variant="Outline" />,
        route: "/esireport",
      },
      {
        id: 23,
        name: MENU_BANK_REPORT,
        keyname: "bankstatement",
        icon: <EmptyWalletAdd size="20" color="#53545C" variant="Outline" />,
        route: "/bankstatement",
      },
      {
        id: 24,
        name: MENU_WAGE_SLIP,
        keyname: "wageslip",
        icon: <EmptyWalletAdd size="20" color="#53545C" variant="Outline" />,
        route: "/wageslip",
      },
      {
        id: 25,
        name: MENU_MUSTER_ROLL,
        keyname: "mustorrole",
        icon: <EmptyWalletAdd size="20" color="#53545C" variant="Outline" />,
        route: "/mustorrole",
      },
      {
        id: 26,
        name: "Summary Reports",
        keyname: "summaryreports",
        icon: <EmptyWalletAdd size="20" color="#53545C" variant="Outline" />,
        route: "/summary-reports",
      },
    ],
  },
  {
    id: 26,
    name: MENU_AUTH,
    icon: <Lock1 size="20" color="#53545C" variant="Outline" />,
    iconActive: <Lock1 size="20" color="#fff" variant="Bold" />,
    sublist: [
      {
        id: 27,
        name: MENU_USER,
        keyname: "users",
        icon: <Profile2User size="20" color="#53545C" variant="Outline" />,
        route: "/users",
      },
      {
        id: 28,
        name: MENU_ROLE,
        keyname: "role",
        icon: <UserTick size="20" color="#53545C" variant="Outline" />,
        route: "/role",
      },
      {
        id: 29,
        name: MENU_ROLE_FUNCTION,
        keyname: "rolefunctionmap",
        icon: <UserTick size="20" color="#53545C" variant="Outline" />,
        route: "/rolefunctionmap",
      },
    ],
  },
];
