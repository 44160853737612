import {
  Button,
  FormControl,
  MenuItem,
  Select as SelectDropdown,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";
import moment from "moment";
import { NODATA } from "pages/app/attendanceReport/container/attendanceReportString";
import {
  CLOSE,
  DNLDPDF,
  GENERATE,
  REPORTS,
} from "pages/app/epfReport/container/epfReportString";
import { DNLDCSV } from "pages/app/esiReport/container/esiReportString";
import { MainSTATEDTO } from "pages/auth/signin/service/types";
import React, { SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BankReportProps } from "../container/bankReportContainer";
import { CURRENTPATH, HOMEPATH } from "../container/bankReportString";
import { GROUPSTATEDT } from "../service/types";
import "./styles.scss";

const paths = [
  {
    name: HOMEPATH,
    path: "",
  },
  {
    name: CURRENTPATH,
  },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#434343",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff ",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BankReport = (props: BankReportProps) => {
  const {
    getAllGroup,
    getAllBanks,
    getAllBankReport,
    error_getBankReport,
    loading_getBankReport,
    success_getBankReport,
    bankReportList,
    role_function,
    banksList,
    getRoleFuncn,
  } = props;
  const date = new Date();
  const [startDate, setStartDate] = useState(
    moment(date).subtract(1, "month").format("YYYY-MM-DD")
  );
  const [allData, setAllData] = useState(bankReportList);
  const [screen, setScreen] = useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectBank, setSelectBank] = useState("");
  const [selectGroup, setSelectGroup] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showCust, setShowCust] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const openDownloadModal = () => {
    setOpenModal(true);
  };
  const closeDownloadModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    getAllBanks();
  }, []);
  useEffect(() => {
    getRoleFuncn();
  }, []);
  const bankLists = useSelector((state: MainSTATEDTO) => state.banks.banksList);
  const MasterBanks = bankLists?.map((ele) => ({
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
  }));
  useEffect(() => {
    getAllGroup();
  }, []);

  const groupLists = useSelector(
    (state: GROUPSTATEDT) => state.groups.groupList
  );

  // let reportDisable = selectGroup === "";

  const masterGroups = groupLists?.map((ele) => ({
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
  }));

  const filterGroups = masterGroups?.filter((ele) => {
    return ele.is_active === 1;
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCustClose = () => setShowCust(false);
  const handleChangeDate = (value: SetStateAction<any>) => {
    setSelectBank("");
    setStartDate(value);
  };
  const selectYear = moment(startDate).format("YYYY");
  const selectMonth = moment(startDate).format("MM");
  const SelectMobthData = {
    month: selectMonth,
    year: selectYear,
    group_id: selectGroup,
    bank_id: selectBank,
  };
  const handleReportGenerate = () => {
    getAllBankReport(SelectMobthData);
  };

  const handleReportGenerateAllReport = () => {
    getAllBankReport(SelectMobthData);
  };
  const currentDate = moment();
  const maxSelectableMonth = currentDate.subtract(1, "month");
  const handleClose = () => {
    setSelectBank("");
    setStartDate(moment(date).subtract(1, "month").format("YYYY-MM-DD"));
    setScreen(1);
  };
  const handleCustShow = () => {
    setShowCust(true);
  };
  const handleClickDownload = () => {
    openDownloadModal();
  };
  let reportDisable = selectBank === "";
  useEffect(() => {
    const isLoading = loading_getBankReport;
    setLoader(isLoading);
    if (success_getBankReport) {
      setAllData(bankReportList);
      // const pdfUrl = `${BASE_URL}/${bankReportList?.Pdf}`;
      const pdfUrl = `${bankReportList?.Pdf}`;
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = bankReportList && pdfUrl;
      link.target = "_blank"; // Open the PDF in a new tab/window

      // Trigger the download
      selectBank === "All" && link.click();
      selectBank === "All" ? setScreen(1) : setScreen(2);
    }
  }, [loading_getBankReport, success_getBankReport]);

  return (
    <>
      {isLoader && <Loader />}
      <Breadcrumb pathList={paths} />

      <div>
        {screen === 1 ? (
          <div className="generate_paper">
            <ToastContainer />
            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MM/yyyy"
                    views={["year", "month"]}
                    label="Year and Month"
                    maxDate={maxSelectableMonth.toDate()}
                    value={startDate}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <SelectDropdown
                  fullWidth
                  value={selectBank}
                  onChange={(event) => setSelectBank(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    selectBank !== "" ? undefined : () => "Select a Bank"
                  }
                >
                  {filterGroups && (
                    <MenuItem key={Math.random() + 22} value={"All"}>
                      All
                    </MenuItem>
                  )}
                  {MasterBanks &&
                    MasterBanks.map((ele) => (
                      <MenuItem key={ele.id} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                </SelectDropdown>
              </FormControl>
            </div>

            {/* <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <SelectDropdown
                  fullWidth
                  value={selectGroup}
                  onChange={(event) => setSelectGroup(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    selectGroup !== "" ? undefined : () => "Select Group"
                  }
                >
                  {filterGroups && <MenuItem key={Math.random() + 22} value={"All"}>
                    All
                  </MenuItem>}
                  {filterGroups &&
                    filterGroups.map((ele) => (
                      <MenuItem key={ele.id} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                </SelectDropdown>
              </FormControl>
            </div> */}

            <div className="form_btn_generate">
              <div className="">
                {selectGroup === "All" ? (
                  // <a target="_blank" href={`${BASE_URL}/${allData.Pdf}`}>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerateAllReport}
                  >
                    {GENERATE}
                  </Button>
                ) : (
                  // </a>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerate}
                  >
                    {GENERATE}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100">
            <div className="d-flex justify-content-between mb-2">
              <div>{REPORTS}</div>
              <div>
                <a target="_blank" href={`${allData.Excel}`}>
                  <button className="CSVdownload">{DNLDCSV}</button>
                </a>
                <a target="_blank" href={`${allData.Pdf}`}>
                  <button className="CSVdownload">{DNLDPDF}</button>
                </a>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  {CLOSE}
                </button>
              </div>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="customized table table-striped">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">SL NO</StyledTableCell>
                      <StyledTableCell align="left">UNION NO</StyledTableCell>
                      <StyledTableCell align="left">BANK NAME</StyledTableCell>
                      <StyledTableCell align="left">
                        BANK ACCOUNT NO
                      </StyledTableCell>
                      <StyledTableCell align="left">NET AMOUNT</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allData.bankReport && allData.bankReport.length ? (
                      allData?.bankReport
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any) => (
                          <StyledTableRow key={Math.random() + 2}>
                            <StyledTableCell align="left">
                              {row.Sl_No}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.Union_Serial_Number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.Name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.Bank_Acc_Number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.Net_Amount}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableCell
                        className="NoRecordFound"
                        align="center"
                        colSpan={5}
                      >
                        {NODATA}
                      </StyledTableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <div className="tpagination">
                  <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={10}
                    count={allData?.bankReport && allData?.bankReport.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </TableContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BankReport;
