import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import strings from "translation";
import { SummaryReportRequest } from "./summary.request";
import { SUMMARYREPORTDT, SummaryReportInitDTO } from "./types";

export const initialState: SummaryReportInitDTO = {
  loading_summaryReport: false,
  success_summaryReport: false,
  error_summaryReport: false,
  summaryReportDetails: null,
};

export const summaryReport = createAsyncThunk(
  "report/summaryReportRequest",
  async (body: SUMMARYREPORTDT) => {
    return SummaryReportRequest(body)
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200) {
          // throw toast(response?.data);
          return response.data;
        }
      })
      .catch((error) => {
        throw toast(error.response.data);
      });
  }
);
export const summaryReportSlice = createSlice({
  name: "summaryReport",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(summaryReport.pending, (state) => {
      state.loading_summaryReport = true;
      state.success_summaryReport = false;
      state.error_summaryReport = false;
    });
    builder.addCase(
      summaryReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading_summaryReport = false;
        state.error_summaryReport = false;
        state.success_summaryReport = true;
        state.summaryReportDetails = action.payload;
      }
    );
    builder.addCase(summaryReport.rejected, (state, action: AnyAction) => {
      state.loading_summaryReport = false;
      state.error_summaryReport =
        action?.error?.message || strings["ERROR.SOMETHINGWENTWRONG"];
      state.success_summaryReport = false;
    });
  },
});
export default summaryReportSlice.reducer;
export const { reset } = summaryReportSlice.actions;
