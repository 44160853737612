import strings from "translation";


export const ADDCATEGORY =  strings["OPERATION.CATEGORY.ADDCATEGORY"];
export const CATEGORY =  strings["OPERATION.CATEGORY.CATEGORY"];
export const TBLCATEGORY =  strings["OPERATION.CATEGORY.TBLCATEGORY"];
export const TBLDATTUM =  strings["OPERATION.CATEGORY.TBLDATTUM"];
export const TBLDATE =  strings["OPERATION.CATEGORY.TBLDATE"];
export const TBLNODATA =  strings["OPERATION.CATEGORY.TBLNODATA"];
export const TBLGRPHEAD =  strings["OPERATION.CATEGORY.TBLGRPHEAD"];
export const PATHHOME =  strings["OPERATION.CATEGORY.PATHHOME"];
export const PATHCURRENT =  strings["OPERATION.CATEGORY.PATHCURRENT"];



export const STATUSCHANGEHEAD = strings["STATUS.CHANGE.PERMISSION.HEADING"];
export const STATUSCHANGESUBHEAD = strings["STATUS.CHANGE.PERMISSION.SUBHEADING"];
export const STATUSCHANGEPERMISSIONYES = strings["STATUS.CHANGE.PERMISSION.YES"];