import strings from "translation";

export const HOMEPATH = strings["HEADER.HOLIDAYCONFIG.HOMEPATH"];
export const CURRENTPATH = strings["HEADER.HOLIDAYCONFIG.CURRENTPATH"];
export const TBLHEADER = strings["HEADER.HOLIDAYCONFIG.TBLHEADER"];
export const TBLID = strings["HEADER.HOLIDAYCONFIG.TBLID"];
export const ADDBTNLNAME = strings["HEADER.HOLIDAYCONFIG.ADDBTNLNAME"];
export const CONFIGHEADER = strings["HEADER.HOLIDAYCONFIG.CONFIGHEADER"];
export const EFFECTIVEHEADER = strings["HEADER.HOLIDAYCONFIG.EFFECTIVEHEADER"];
export const STATUSLABEL = strings["HEADER.HOLIDAYCONFIG.STATUSLABEL"];
export const SUCESSSTATUSTITLE = strings["STATUS.SUCEESS.TITLE"];
export const SUCESSSTATUSTEXT = strings["STATUSESUCEESS.TEXT"];
export const SUCESSCREATETITLE = strings["ADD.SUCEESS.TITLE"];
export const SUCESSCREATEXT = strings["ADD.SUCEESS.TEXT"];
export const SUCESSUPDATETITLE = strings["UPDATE.SUCEESS.TITLE"];
export const SUCESSUPDATEXT = strings["UPDATE.SUCEESS.TEXT"];

export const HEADERCONFIG = strings["HEADER.HOLIDAYCONFIG.HEADERCONFIG"];

