import React, { SyntheticEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MdClose } from 'react-icons/md';
import { FormProps, ISubmitResult } from './types';
import FormTextField from 'components/common/textField/textField';
import { ADDNEWLABEL, UPDATELABEL, SAVEBTN, UPDATEBTN, DATEERROR, DATELABEL, DATEPLACEHOLDER, CANCELBTN, WAGEPLACEHOLDER, WAGELABEL, WAGEERROR, DALABEL, DAPLACEHOLDER, DAERROR, HRAPLACEHOLDER, HRALABEL, HRAERROR, CANERROR, CANPLACEHOLDER, CANLABEL, DAAMOUNTPLACEHOLDER, DAAMOUNTLABEL, DAAMOUNTERROR, HRAAMOUNTERROR, HRAAMOUNTPLACEHOLDER, HRAAMOUNTLABEL, CANAMOUNTERROR, CANAMOUNTLABEL, CANAMOUNTPLACEHOLDER } from './wageString';
import moment from 'moment';
import "../style.scss"
const WageForm = (props: FormProps) => {
    const {
        initialValue = {},
        handleFormData,
        onHandleClose,
        clickedBtn,
        errorMessage,
        setErrorMessage
    } = props;

    const [basicAmount, setBasicAmount] = useState<ISubmitResult>({
        value: initialValue.basic_amount
            ? initialValue.basic_amount : "",
        error: false,
        success: false,
    });

    const [daPercent, setDaPercent] = useState<ISubmitResult>({
        value: initialValue.dearness_allowance_percentage
            ? initialValue.dearness_allowance_percentage : "",
        error: false,
        success: false,
    });
    const [hraPercent, setHraPercent] = useState<ISubmitResult>({
        value: initialValue.houserent_allowance_percentage
            ? initialValue.houserent_allowance_percentage : "",
        error: false,
        success: false,
    });

    const [canPercent, setCanPercent] = useState<ISubmitResult>({
        value: initialValue.canteen_allowance_percentage
            ? initialValue.canteen_allowance_percentage : "",
        error: false,
        success: false,
    });

    const [effectDate, setEffectDate] = useState<ISubmitResult>({
        value: initialValue.effective_from
            ? moment(initialValue.effective_from).format('YYYY-MM-DD') : "",
        error: false,
        success: false,
    });

    const [arrearEndDate, setArrearEndDate] = useState<ISubmitResult>({
        value: initialValue.arrear_end_date
          ? moment(initialValue.arrear_end_date).format("YYYY-MM-DD")
          : "",
        error: false,
        success: false,
      });
    
    const [isArriear, setIsArriear] = React.useState<boolean>(
        initialValue ? (initialValue.is_arrear  === 1 ? true : false) : false
    );
    
    let initarrearEndDate = initialValue.arrear_end_date? initialValue.arrear_end_date : ""
      let initArriear = initialValue?.is_arrear === 1 ? true : false;

    let editDisable =
        basicAmount.value === initialValue.basic_amount &&
        daPercent.value === initialValue.dearness_allowance_percentage &&
        hraPercent.value === initialValue.houserent_allowance_percentage &&
        canPercent.value === initialValue.canteen_allowance_percentage &&
        effectDate.value === initialValue.effective_from &&
        isArriear === initArriear &&
        arrearEndDate.value === initarrearEndDate;
    
    let disable =
        basicAmount.error ||
        effectDate.error ||
        basicAmount.value === "" ||
        effectDate.value === "" ||
        (basicAmount.success === false &&
            daPercent.success === false &&
            hraPercent.success === false &&
            canPercent.success === false &&
            effectDate.success === false) 
    
    const handleChangeBasicAmount = (value: ISubmitResult) => {
        setErrorMessage("");
        setBasicAmount(value);
    };
    const handleChangeDA = (value: ISubmitResult) => {
        setErrorMessage("");
        setDaPercent(value);
    };

    const handleChangeHra = (value: ISubmitResult) => {
        setErrorMessage("");
        setHraPercent(value);
    };
    const handleChangeCan = (value: ISubmitResult) => {
        setErrorMessage("");
        setCanPercent(value);
    };

    const handleChangeDate = (value: ISubmitResult) => {
        setErrorMessage("");
        setEffectDate(value);
    };

    const handleChangeArrearDate = (value: ISubmitResult) => {
        setArrearEndDate(value);
    };
    
    const handleCheckArrear = (
        event: SyntheticEvent<Element, Event>,
        checked: boolean
      ) => {
        setIsArriear(checked);
    };
    

    const handleSubmitForm = () => {
        const data = {
            basic_amount: parseFloat(basicAmount.value),
            dearness_allowance_percentage: parseFloat(daPercent.value),
            houserent_allowance_percentage: parseFloat(hraPercent.value),
            canteen_allowance_percentage: parseFloat(canPercent.value),
            effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
            is_active: initialValue?.is_active,
            is_arrear: isArriear,
            arrear_end_date:arrearEndDate.value,
        }
        handleFormData(data)
    }

    return (
        <Card sx={{ boxShadow: "none" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={onHandleClose}>
                        <MdClose />
                    </IconButton>
                }
                title={clickedBtn === "add" ? ADDNEWLABEL : UPDATELABEL}
            />
           {errorMessage ? <span className='error_msg'>{errorMessage}</span>:null}
            <CardContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { mb: 2 },
                    }}
                    className={"formResponsiveHeight"}
                    noValidate={true}
                >
                    <FormTextField
                        type="price"
                        placeholder={WAGEPLACEHOLDER}
                        label={WAGELABEL}
                        Value={basicAmount.value}
                        onChangeText={handleChangeBasicAmount}
                        Required={true}
                        CustomErrorLine={WAGEERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={DAPLACEHOLDER}
                        label={DALABEL}
                        Value={daPercent.value}
                        onChangeText={handleChangeDA}
                        Required={false}
                        CustomErrorLine={DAERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={HRAPLACEHOLDER}
                        label={HRALABEL}
                        Value={hraPercent.value}
                        onChangeText={handleChangeHra}
                        Required={false}
                        CustomErrorLine={HRAERROR}
                    />
                    <FormTextField
                        type="price"
                        placeholder={CANPLACEHOLDER}
                        label={CANLABEL}
                        Value={canPercent.value}
                        onChangeText={handleChangeCan}
                        Required={false}
                        CustomErrorLine={CANERROR}
                    />

                    <FormTextField
                        // hidden={ !isArriear }
                        type="Edate"
                        placeholder={DATEPLACEHOLDER}
                        label={DATELABEL}
                        Value={effectDate.value}
                        onChangeText={handleChangeDate}
                        Required={true}
                        CustomErrorLine={DATEERROR}
                    />
                    <FormTextField
                         hidden={!isArriear}
                        type="Edate"
                        placeholder={"Arrear End Date"}
                        label={"Arrear End Date"}
                        Value={arrearEndDate.value}
                        onChangeText={handleChangeArrearDate}
                        Required={false}
                        CustomErrorLine={DATEERROR}
                    />
                  <FormControlLabel
                        sx={{ mb: 1 }}
                        value={isArriear}
                        control={<Checkbox checked={isArriear} />}
                        label="Is arrear"
                        labelPlacement="end"
                        onChange={handleCheckArrear}
                        />
                </Box>
            </CardContent>
            <CardActions className='d-flex dust justify-content-end'>
                <Button size="large" variant="outlined" onClick={onHandleClose}>{CANCELBTN}</Button>
                <Button size="large" variant="contained" disabled={clickedBtn === "add" ?disable :editDisable} onClick={handleSubmitForm}>{clickedBtn === "add" ? SAVEBTN : UPDATEBTN}</Button>
            </CardActions>
        </Card>
    );
}

export default WageForm;
