import { connect } from 'react-redux'
import { RootState, TypedDispatch } from 'redux/store'
import { fetchAllEsiReport, fetchAllGroups } from '../service/esireportslice'
import EsiReport from '../component/esiReport'
import { RoleFunDTO } from 'pages/auth/signin/service/types'
import { getRole } from 'pages/auth/signin/service/userSlice'
import { EsiReportResponseDTO, GetAllESIReportRequestDTO } from '../service/types'

interface StateProps {
  loading_getGroup: boolean;
  success_getGroup: boolean;
  error_getGroup: string | boolean | undefined;
  
  loading_getEsiReport: boolean
  success_getEsiReport: boolean
  error_getEsiReport: string | boolean | undefined;
  esiReportList: EsiReportResponseDTO |undefined |null
  role_function: RoleFunDTO | undefined
}

interface DispatchProps {
  getAllGroup(): void;
  getEsiReport(data: GetAllESIReportRequestDTO): void
  getRoleFuncn(): void
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error_getGroup: state.musterroll.error_getGroup,
        loading_getGroup: state.musterroll.loading_getGroup,
        success_getGroup: state.musterroll.success_getGroup,
    loading_getEsiReport: state.esireport.loading_getEsiReport,
    success_getEsiReport: state.esireport.success_getEsiReport,
    error_getEsiReport: state.esireport.error_getEsiReport,
    esiReportList: state.esireport.esiReportList,
    role_function: state.userLogin.role_function
  }
}

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    getAllGroup: () => {
      dispatch(fetchAllGroups());
  },
    getEsiReport: (data) => {
      dispatch(fetchAllEsiReport(data))
    },
    getRoleFuncn: () => {
      dispatch(getRole());
    }
  }
}
export interface EsiReportProps extends StateProps, DispatchProps {}
export const EsiReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EsiReport)
