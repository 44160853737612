import { Box, FormControl, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AttendanceChip from "components/common/AttedanceChip/AttendanceChip";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";
import { ArrowLeft, Edit2, Trash } from "iconsax-react";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { AttendanceProps as Iprops } from "../container/attendanceContainer";
import {
  ADDBTN,
  CURRENTPATH,
  CURRENT_SUCCESS,
  CURRENT_SUCCESS_CREATED,
  CURRENT_SUCCESS_UPDATED,
  DELETED,
  DELETEDMESSAGE,
  DELETELABELHEAD,
  DELETELABELSUBHEAD,
  DELETEPERMISSIONNO,
  DELETEPERMISSIONYES,
  HOMEPATH,
  LABELABSENT,
  LABELPRESENT,
  LABELSPECIALABSENT,
  NODATAFOUND,
  TABLEABSENT,
  TABLEACTION,
  TABLEDATE,
  TABLEGROUP,
  TABLEPRESENT,
  TABLESHIFT,
  TABLESPABSENT,
  TBLHEAD,
  UPDATEBTN,
} from "../container/attendanceString";
import { reset } from "../service/attendanceSlice";
import {
  AttedanceListDTO,
  CreateAttendanceDTO,
  GROUPDETAILDT,
  GROUPDROPDOWN,
  GROUPSTATEDT,
  GetAllGroupResponse,
  Report,
  SHIFTDETAILDT,
  SHIFTSTATEDT,
} from "../service/types";
import "./style.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#434343",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff ",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface AttendanceProps extends Iprops {
  attendanceList: AttedanceListDTO[];
  attendanceDetails: any;
  allGroupShifts: GetAllGroupResponse;
}
interface SelectedGroupData {
  key: number;
  id: number;
  value: number;
  label: string;
  is_active: number;
  effective_from: Date;
}
const Attendance = (props: AttendanceProps) => {
  const {
    getAllAttendance,
    createAllAttendance,
    updateAttendance,
    deleteAttendance,
    getAllGroups,
    getAllStaff,
    getAllShift,
    attendanceList,
    loading_getGroup,
    loading_getShift,
    loading_getStaff,
    loading_getAttendance,
    success_getAttendance,
    loading_createAttendance,
    error_createAttendance,
    success_createAttendance,
    loading_updateAttendance,
    error_updateAttendance,
    loading_deleteAttendance,
    success_updateAttendance,
    success_deleteAttendance,
    success_getStaff,
    getAttendanceById,
    attendanceDetails,
    loading_getAttendanceById,
    success_getAttendanceById,
    getAllGroupsShiftByDt,
    loading_getGroupShift,
    success_getGroupShift,
    allGroupShifts,
    allStaffList,
    role_function,
    getRoleFuncn,
    GenerateOffDay,
    details_generateOffDay,
    error_generateOffDay,
    loading_generateOffDay,
    success_generateOffDay,
  } = props;

  const paths = [
    {
      name: HOMEPATH,
      path: "",
    },
    {
      name: CURRENTPATH,
    },
  ];

  const date = new Date();
  const currentDate = JSON.stringify(date).slice(1, 11);

  const [currentType, setCurrentType] = useState<any>();
  const [isLoader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [selectGroupList, setSelectGroupList] = useState<any>({
    value: null,
    error: false,
    success: false,
  });

  const [selectShifts, setSelectShifts] = useState<any>();
  const [clickedBtn, setClickedBtn] = useState("");
  const [rows, setRows] = useState(attendanceList);
  const [currentId, setCurrentId] = useState(null);
  const [errorMessage, setErrorMessage] = useState<boolean | string>();
  const [screen, setScreen] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const draggableTodo = useRef<any>(null);
  const groupName = useRef(null);
  const [initStaffs, setInitStaff] = useState<never[]>([]);
  const [selectYear, setSelectYear] = useState<string | {}>(
    moment(date).format("YYYY-MM-DD")
  );
  const [selectMonth, setSelectMonth] = useState<string | {}>(
    moment(date).format("YYYY-MM-DD")
  );
  const [selectDay, setSelectDay] = useState<string | {}>(
    moment(date).format("YYYY-MM-DD")
  );
  const [sarchShift, setSearchShift] = useState<any>();
  const [masterGrp, setMasterGrp] = useState<GROUPDROPDOWN[]>([]);
  const [masterShft, setMasterShift] = useState<SHIFTDETAILDT[]>([]);
  const [selectedGroupData, setSelectedGroupData] = useState<
    SelectedGroupData[]
  >([]);

  const [tasks, setTasks] = useState<any>({
    present_list: [],
    absent_list: [],
    special_absent: [],
    dragged: {},
  });

  const presentListData = [...tasks.present_list];
  const absentListdata = [...tasks.absent_list];
  const specialAbsentListdata = [...tasks.special_absent];

  let disable =
    startDate === "" ||
    tasks.present_list.length == 0 ||
    selectGroupList.value === null ||
    selectShifts === undefined;

  const dispatch = useDispatch();
  useEffect(() => {
    if (startDate !== "") {
      let value = { date: startDate };
      getAllGroupsShiftByDt(value);
    }
  }, [startDate]);
  useEffect(() => {
    getAllGroups();
    getAllShift();
  }, []);
  useEffect(() => {
    getRoleFuncn();
  }, []);
  useEffect(() => {
    const isLoading =
      loading_createAttendance ||
      loading_getStaff ||
      loading_getGroup ||
      loading_getShift ||
      loading_updateAttendance ||
      loading_deleteAttendance ||
      loading_getAttendance ||
      loading_getAttendanceById ||
      loading_getGroupShift;
    setLoader(isLoading);
  }, [
    loading_createAttendance,
    loading_getStaff,
    loading_getGroup,
    loading_getShift,
    loading_updateAttendance,
    loading_deleteAttendance,
    loading_getAttendance,
    loading_getAttendanceById,
    loading_getGroupShift,
  ]);
  useEffect(() => {
    if (success_createAttendance) {
      setErrorMessage("");
      setSelectGroupList("");
      setSelectShifts("");
      dispatch(reset());
      Swal.fire({
        title: CURRENT_SUCCESS,
        text: CURRENT_SUCCESS_CREATED,
        icon: "success",
      });
      setTasks({
        present_list: [],
        absent_list: [],
        special_absent: [],
        dragged: {},
      });
      fetchData();
      handleClickTable();
    } else if (error_createAttendance) {
      setErrorMessage(error_createAttendance);
    } else if (success_updateAttendance) {
      setErrorMessage("");
      setSelectGroupList("");
      setSelectShifts("");
      dispatch(reset());
      Swal.fire({
        title: CURRENT_SUCCESS,
        text: CURRENT_SUCCESS_UPDATED,
        icon: "success",
      });
      setTasks({
        present_list: [],
        absent_list: [],
        special_absent: [],
        dragged: {},
      });
      fetchData();
      handleClickTable();
    } else if (error_updateAttendance) {
      setErrorMessage(error_updateAttendance);
    } else if (success_deleteAttendance) {
      setErrorMessage("");
      Swal.fire({
        title: DELETED,
        text: DELETEDMESSAGE,
        icon: "success",
      });
      dispatch(reset());
      fetchData();
    }
  }, [
    success_createAttendance,
    error_createAttendance,
    success_updateAttendance,
    error_updateAttendance,
    success_deleteAttendance,
  ]);

  function dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    const diffDay = Math.floor((utc2 - utc1) / _MS_PER_DAY);
    return true ? diffDay < 0 : false;
  }

  const groupLists = useSelector(
    (state: GROUPSTATEDT) => state.groups.groupList
  );
  const masterGroup = groupLists?.map((ele: GROUPDETAILDT) => ({
    key: Math.random() + 2,
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
    effective_from: new Date(ele.effective_from),
  }));

  const masterGroups = masterGroup?.filter((ele) => {
    return (
      ele.is_active === 1 && dateDiffInDays(date, new Date(ele.effective_from))
    );
  });

  const shiftList = useSelector((state: SHIFTSTATEDT) => state.shift.shiftList);
  const masterShifts = shiftList?.map((ele: SHIFTDETAILDT) => ({
    key: Math.random() + 3,
    id: ele.id,
    value: ele.id,
    label: ele.name,
    amount: ele.amount,
    is_active: ele.is_active,
  }));
  const masterShift = masterShifts?.filter((ele: SHIFTDETAILDT) => {
    return ele.is_active === 1;
  });

  useEffect(() => {
    if (success_getGroupShift) {
      if (allGroupShifts) {
        let groupArr = allGroupShifts?.map((ele) => {
          return ele.attendance_groups[0]?.groupId;
        });
        let newGroupArr = groupArr?.join(",").split(",");

        const result = masterGroups.filter((ele) =>
          newGroupArr.every((id: number | string) => id != ele.id)
        );

        setMasterGrp(result);
        let shiftArr = allGroupShifts?.map((ele) => {
          return ele.shift?.id;
        });
        const resultShift = masterShift.filter((ele) =>
          shiftArr.every((id: number | string) => id !== ele.id)
        );
        setMasterShift(resultShift);
      }
    }
  }, [success_getGroupShift]);

  useEffect(() => {
    if (clickedBtn === "add") {
      if (selectGroupList.length > 0) {
        let group_ids: any = [];
        selectGroupList?.map((ele: GROUPDROPDOWN) => {
          group_ids.push(ele.id);
        });
        let groupDisable = group_ids.length !== 0;
        groupDisable && getAllStaff(group_ids);
      }
    }
  }, [selectGroupList]);

  const getStaffList = useSelector((state: any) => state.staff.staffList);
  const staffListallData = getStaffList?.map((ele: any) => {
    return {
      key: Math.random() + 33,
      id: ele.union_serial_number,
      value: ele.union_serial_number,
      name: ele.union_serial_number,
      label: ele.union_serial_number,
      group_id: ele.group_id.id,
    };
  });

  const staffList = [...staffListallData];

  useEffect(() => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page,
    };
    getAllAttendance(filterData);
  }, [rowsPerPage, page, success_generateOffDay]);
  useEffect(() => {
    setRows(attendanceList);
  }, [success_getAttendance]);
  const fetchData = () => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page + 1,
    };
    getAllAttendance(filterData);
  };
  const handleFilter = () => {
    let filterData = {
      year: selectYear !== null ? moment(selectYear).format("YYYY") : null,
      month: selectMonth !== null ? moment(selectMonth).format("MM") : null,
      day: selectDay !== null ? moment(selectDay).format("DD") : null,
      shift_id:
        sarchShift === undefined
          ? null
          : sarchShift === ""
          ? null
          : sarchShift.value,
      limit: rowsPerPage,
      page: page,
    };
    getAllAttendance(filterData);
  };
  const handleClearFilter = () => {
    setSelectYear(date);
    setSelectMonth(date);
    setSelectDay(date);
    setSearchShift("");
    let clearData = {
      year: moment(date).format("YYYY"),
      month: moment(date).format("MM"),
      day: moment(date).format("DD"),
      shift_id: null,
      limit: rowsPerPage,
      page: page,
    };
    getAllAttendance(clearData);
  };

  const handleClickAddScreen = () => {
    setTasks({
      present_list: [],
      absent_list: [],
      special_absent: [],
      dragged: {},
    });
    setClickedBtn("add");
    setScreen(2);
  };
  const handleClickTable = () => {
    setCurrentType(null);
    setStartDate("");
    setSelectGroupList("");
    setSelectShifts("");
    setClickedBtn("add");
    setScreen(1);
  };
  useEffect(() => {
    if (clickedBtn === "add") {
      if (startDate && selectGroupList.length > 0) {
        const allGrpIDs = selectedGroupData.map((ele) => ele.id);
        let present_list: any[] = [];
        allGrpIDs.forEach((groupId) => {
          const filteredByGroupId = staffList.filter(
            (ele) => ele.group_id === groupId
          );
          const sortedFilteredByGroupId = _.sortBy(
            filteredByGroupId,
            (ele) => parseInt(ele.name),
            ["asc"]
          );
          present_list = [...present_list, ...sortedFilteredByGroupId];
        });
        setTasks({
          present_list: present_list,
          absent_list: [...absentListdata],
          special_absent: [...specialAbsentListdata],
          dragged: {},
        });
      }
    }
  }, [startDate, selectGroupList, success_getStaff, selectedGroupData]);
  useEffect(() => {
    if (success_getAttendanceById) {
      if (attendanceDetails) {
        const initialStaffs = attendanceDetails?.report?.map((ele: Report) => {
          return {
            id: ele.staff_USN,
            value: ele.staff_USN,
            name: ele.staff_USN,
            label: ele.staff_USN,
            group_id: ele.group_id,
          };
        });
        setInitStaff(initialStaffs);

        const presentDatsList = attendanceDetails?.report?.filter(
          (item: { status: string }) => {
            return item.status === "P";
          }
        );

        const absentList = attendanceDetails?.report?.filter(
          (item: { status: string }) => {
            return item.status === "A";
          }
        );
        const specialList = attendanceDetails?.report?.filter(
          (item: { status: string }) => {
            return item.status === "S";
          }
        );
        let present_datas = presentDatsList?.map(
          (ele: Report, index: number) => {
            return {
              id: index + 1,
              value: ele.staff_USN,
              name: ele.staff_USN,
              label: ele.staff_USN,
              group_id: ele.group_id,
            };
          }
        );

        const sortfilterdata = _.sortBy(
          present_datas,
          (ele) => parseInt(ele.value),
          ["asc"]
        );
        const sortfilterdatabyGroupID: any = _.sortBy(
          sortfilterdata,
          (ele) => parseInt(ele.group_id),
          ["asc"]
        );

        let absent_datas = absentList?.map((ele: Report, index: number) => {
          return {
            id: index + 1,
            value: ele.staff_USN,
            name: ele.staff_USN,
            label: ele.staff_USN,
            group_id: ele.group_id,
          };
        });

        const sortfilterAbsentdata = _.sortBy(
          absent_datas,
          (ele) => parseInt(ele.value),
          ["asc"]
        );
        const sortfilterAbsentdatabyGroupID: any = _.sortBy(
          sortfilterAbsentdata,
          (ele) => parseInt(ele.group_id),
          ["asc"]
        );

        let special_datas = specialList?.map((ele: Report, index: number) => {
          return {
            id: index + 1,
            value: ele.staff_USN,
            name: ele.staff_USN,
            label: ele.staff_USN,
            group_id: ele.group_id,
          };
        });

        const sortfilterSPAbsentdata = _.sortBy(
          special_datas,
          (ele) => parseInt(ele.value),
          ["asc"]
        );
        const sortfilterSPAbsentdatabyGroupID: any = _.sortBy(
          sortfilterSPAbsentdata,
          (ele) => parseInt(ele.group_id),
          ["asc"]
        );

        setCurrentId(attendanceDetails.id);
        setSelectShifts({
          id: attendanceDetails?.shift?.id,
          value: attendanceDetails?.shift?.id,
          label: attendanceDetails?.shift?.name,
        });
        setStartDate(moment(attendanceDetails.date).format("YYYY-MM-DD"));
        setCurrentType({
          date: attendanceDetails.attendanceDetails,
          shift_id: {
            id: attendanceDetails.shift?.id,
            label: attendanceDetails.shift?.name,
            value: attendanceDetails.shift?.id,
          },
          present_list: present_datas,
          absent_list: absent_datas,
          special_absent: special_datas,
        });
        setTasks({
          present_list: sortfilterdatabyGroupID,
          absent_list: sortfilterAbsentdatabyGroupID,
          special_absent: sortfilterSPAbsentdatabyGroupID,
          dragged: {},
        });
        setClickedBtn("edit");
        setScreen(2);
      }
    }
  }, [success_getAttendanceById]);
  const dragStart = (event: any) => {
    const { target } = event;
    const id = target.id;
    const parentElementId = target.parentElement.id;
    setTimeout(() => {
      target.style.display = "none";
      draggableTodo.current = target;
    }, 0);
    setTasks((prevState: any) => {
      const state: any = { ...prevState };
      const fn = (name: any) => {
        groupName.current = name;
        state.dragged = state[name].find((i: any) => i.id.toString() === id);
      };

      switch (parentElementId) {
        case "todo_div":
          fn("present_list");
          return state;
        case "inProgress_div":
          fn("absent_list");
          return state;
        case "done_div":
          fn("special_absent");
          return state;
        default:
          return state;
      }
    });
  };
  const dragEnd = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    draggableTodo.current.style.display = "block";
  };
  const dragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const dragDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { currentTarget } = event;
    const id = currentTarget.id;

    setTasks((prevState: any) => {
      const state: any = { ...prevState };
      const fn = (name: any) => {
        const { current }: any = groupName;
        const dragged: any = state.dragged;
        const previousParentId = draggableTodo.current.parentElement.id;
        if (previousParentId !== id) {
          state[current] = state[current].filter(
            (i: any) => i.id !== dragged.id
          );
          state[name] = [...state[name], dragged];
        } else {
          draggableTodo.current.style.display = "block";
        }
      };
      switch (id) {
        case "todo_div":
          fn("present_list");
          return state;
        case "inProgress_div":
          fn("absent_list");
          return state;
        case "done_div":
          fn("special_absent");
          return state;
        default:
          return state;
      }
    });
  };
  const handleChangePresent = (event: any) => {
    let triggerData = {
      id: event?.value,
      name: event?.label,
      value: event.label,
      group_id: event.group_id,
      label: event.label,
      key: event.key,
    };

    let presentList = [...tasks.present_list, triggerData];
    const presentIds = presentList.map((id) => id.id);
    const filterPresentList = presentList.filter(
      ({ id }, index) => !presentIds.includes(id, index + 1)
    );
    const filterAbsentList = tasks.absent_list?.filter(
      (item: { id: string }) => item.id !== triggerData.id
    );
    const filterSplAbsentList = tasks.special_absent?.filter(
      (item: { id: string }) => item.id !== triggerData.id
    );
    const data = {
      present_list: filterPresentList,
      absent_list: filterAbsentList,
      special_absent: filterSplAbsentList,
      dragged: triggerData,
    };
    setTasks(data);
  };

  const handleChangeAbsent = (event: any) => {
    let triggerData = {
      id: event.value,
      name: event.label,
      value: event.label,
      group_id: event.group_id,
      label: event.label,
      key: event.key,
    };
    let absentList = [...tasks.absent_list, triggerData];
    const absentIds = absentList.map((id) => id.id);
    const filterPresentList = tasks.present_list.filter(
      (item: any) => item.name !== triggerData.name
    );
    const filterAbsentList = absentList.filter(
      ({ name }, index) => !absentIds.includes(name, index + 1)
    );
    const filterSplAbsentList = tasks.special_absent.filter(
      (item: any) => item.name !== triggerData.name
    );

    const data = {
      present_list: filterPresentList,
      absent_list: filterAbsentList,
      special_absent: filterSplAbsentList,
      dragged: triggerData,
    };
    setTasks(data);
  };
  const handleChangeSplAbsent = (event: any) => {
    let triggerData = {
      id: event.value,
      name: event.label,
      value: event.label,
      label: event.label,
      key: event.key,
      group_id: event.group_id,
    };
    let splAbsentList = [...tasks.special_absent, triggerData];
    const splAbsentIds = splAbsentList.map((id) => id.id);
    const filterPresentList = tasks.present_list.filter(
      (item: any) => item.name !== triggerData.name
    );
    const filterAbsentList = tasks.absent_list?.filter(
      (item: { id: number }) => item.id !== triggerData.id
    );
    const filterSplAbsentList = splAbsentList.filter(
      ({ name }, index) => !splAbsentIds.includes(name, index + 1)
    );

    const data = {
      present_list: filterPresentList,
      absent_list: filterAbsentList,
      special_absent: filterSplAbsentList,
      dragged: triggerData,
    };
    setTasks(data);
  };
  const handleChangeYear = (event: any) => {
    setSelectYear(event);
    setSelectMonth(event);
    setSelectDay(event);
  };
  const handleChangeMonth = (event: any) => {
    setSelectMonth(event);
    setSelectDay(event);
  };
  const handleChangeDates = (event: any) => {
    setSelectDay(event);
  };
  const handleSearchShift = (value: any) => {
    setSearchShift(value);
  };
  const handleChangeDate = (event: any) => {
    setTasks({
      present_list: [],
      absent_list: [],
      special_absent: [],
      dragged: {},
    });
    setStartDate(event.target.value);
  };

  const handleChangeGroups = (data: any) => {
    // if absent list data is present in present list data don't empytu absent lis else empty absent list
    setSelectedGroupData(data);
    if (presentListData) {
      const allGroupId = data.map((ele: { id: number }) => ele.id);
      const filteredAbsentList = absentListdata.filter((ele) =>
        allGroupId.includes(ele.group_id)
      );
      const filteredSpclAbsentList = specialAbsentListdata.filter((ele) =>
        allGroupId.includes(ele.group_id)
      );

      setTasks({
        present_list: [],
        absent_list: filteredAbsentList ? [...filteredAbsentList] : [],
        special_absent: [...filteredSpclAbsentList],
        dragged: {},
      });
    } else {
      setTasks({
        present_list: [],
        absent_list: [],
        special_absent: [],
        dragged: {},
      });
    }
    setSelectGroupList(data);
  };
  const handleChangeShift = (data: any) => {
    setSelectShifts(data);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeEdit = (id: any) => {
    getAttendanceById(id);
  };

  const handleSaveAttendance = () => {
    const presentlist = tasks.present_list.map(
      (e: { value: string; group_id: string }) => {
        return {
          USN: e.value,
          group_id: e.group_id,
        };
      }
    );
    const absentlist = tasks.absent_list.map(
      (e: { value: string; group_id: string }) => {
        return {
          USN: e.value,
          group_id: e.group_id,
        };
      }
    );
    const specialAbsentlist = tasks.special_absent.map(
      (e: { value: string; group_id: string }) => {
        return {
          USN: e.value,
          group_id: e.group_id,
        };
      }
    );
    const submit_attendance: CreateAttendanceDTO = {
      shift_id: selectShifts.value,
      date: startDate,
      present: presentlist,
      absent: absentlist,
      special_absent: specialAbsentlist,
    };
    if (clickedBtn === "add") {
      createAllAttendance(submit_attendance);
    } else {
      const presents_list = currentType?.present_list?.map((e: any) => e.value);
      const absents_list = currentType?.absent_list?.map((e: any) => e.value);
      const specialAbsents_list = currentType?.special_absent?.map(
        (e: { name: string }) => e.name
      );
      var oldPresentData = presents_list;
      var newPresentData = submit_attendance?.present?.map(
        (ele: { USN: string }) => ele.USN
      );
      var oldAbsentData = absents_list;
      var newAbsentData = submit_attendance.absent?.map((ele) => ele.USN);

      var oldSplData = specialAbsents_list;
      var newSplData = submit_attendance.special_absent?.map((ele) => ele.USN);
      const preData = _.differenceWith(
        newPresentData,
        oldPresentData,
        _.isEqual
      );
      const absData = _.differenceWith(newAbsentData, oldAbsentData, _.isEqual);
      const splData = _.differenceWith(newSplData, oldSplData, _.isEqual);
      const update_body = {
        id: currentId,
        data: {
          shift_id: currentType.shift_id.id,
          date: startDate,
          present: preData,
          absent: absData,
          special_absent: splData,
        },
      };
      updateAttendance(update_body);
    }
  };
  const handleActionDelete = (id: number) => {
    deleteAttendance(id);
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const handleChangeDelete = (id: any) => {
    swalWithBootstrapButtons
      .fire({
        title: DELETELABELHEAD,
        text: DELETELABELSUBHEAD,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: DELETEPERMISSIONYES,
        cancelButtonText: DELETEPERMISSIONNO,
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleActionDelete(id);
        }
      });
  };
  const presentandabesnt = [...tasks.present_list, ...tasks.absent_list];
  const presentandspecialabesnt = [
    ...tasks.present_list,
    ...tasks.special_absent,
  ];
  const absentAndSpecialabsent = [
    ...tasks.absent_list,
    ...tasks.special_absent,
  ];
  console.log(rows);
  const isEmptyArray = rows.length === 0;

  // Check if there's any object with shift_id equal to 0
  const hasShiftIdZero = rows.some((item) => item.shift_id === 0);

  // Check if there are no shift_id values of 1, 2, or 3
  const hasShiftIds123 =
    rows.some((item) => item.shift_id === 1) &&
    rows.some((item) => item.shift_id === 2) &&
    rows.some((item) => item.shift_id === 3);
  console.log(isEmptyArray);
  console.log(hasShiftIdZero);
  console.log(hasShiftIds123);
  // Determine if the button should be disabled
  const isButtonDisabled = isEmptyArray || hasShiftIdZero || !hasShiftIds123;

  const handleGenerateOffDay = useCallback(() => {
    GenerateOffDay(moment(selectDay).format("YYYY-MM-DD"));
  }, [GenerateOffDay, selectDay]);

  return (
    <>
      {isLoader && <Loader />}
      <div className="tasks">
        <Breadcrumb pathList={paths} />

        {screen === 1 ? (
          <div>
            <div className="header-toolbar row mb-4">
              <div className="col-lg-12 col-md-8 col-sm-8 col-xs-12">
                <FormControl size="small">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="date_style"
                      inputFormat="yyyy"
                      views={["year"]}
                      label="Year"
                      value={selectYear}
                      onChange={handleChangeYear}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl size="small">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="date_style"
                      inputFormat="MM"
                      views={["month"]}
                      label="Month"
                      value={selectMonth}
                      onChange={handleChangeMonth}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl size="small">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="date_style"
                      inputFormat="dd"
                      views={["day"]}
                      label="Date"
                      value={selectDay}
                      defaultCalendarMonth={selectMonth}
                      onChange={handleChangeDates}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl
                  size="small"
                  className="shift-btn me-2"
                  style={{ width: "161px" }}
                >
                  <Select
                    className="basic-single"
                    classNamePrefix="Select shift"
                    defaultValue={"Shift "}
                    isSearchable={true}
                    name="color"
                    options={masterShift}
                    onChange={handleSearchShift}
                    value={sarchShift}
                    placeholder={"Select shift"}
                  />
                </FormControl>
                <button onClick={handleFilter} className="btn btn-success me-2">
                  Search
                </button>
                <button
                  onClick={handleClearFilter}
                  className="btn btn-secondary me-2"
                >
                  Clear
                </button>
                {isButtonDisabled ? (
                  <button className="btn btn-muted" disabled>
                    Generate Off Day
                  </button>
                ) : (
                  <button
                    className="btn btn-info"
                    onClick={handleGenerateOffDay}
                    disabled={loading_generateOffDay}
                  >
                    {loading_generateOffDay ? (
                      <>Generating...</>
                    ) : (
                      "Generate Off Day"
                    )}
                  </button>
                )}
              </div>
              {/* <div className='col-lg-2 col-md-4 col-sm-4 col-xs-12 text-end'>
                {role_function?.Attendance.Create_New_Attendance && <Button
                  variant="contained"
                  className="attendance-btn"
                  onClick={handleClickAddScreen}
                >
                  {'ADD ATTENDANCE'}
                </Button>}
              </div> */}
            </div>

            <div>
              <Box>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>{TABLEDATE}</StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLEGROUP}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLESHIFT}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLEPRESENT}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLEABSENT}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLESPABSENT}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {TABLEACTION}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.length > 0 ? (
                        rows
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, i: number) => {
                            return (
                              <>
                                <StyledTableRow key={Math.random() + 1}>
                                  <StyledTableCell component="th" scope="row">
                                    {item.date}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item.attendance_calcreport[0]?.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item.shift?.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item.attendance_calcreport[0]?.Present}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item.attendance_calcreport[0]?.Absent}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {item.attendance_calcreport[0]?.Special}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {role_function?.Attendance
                                      ?.Update_Attendance_Detail && (
                                      <span
                                        onClick={() =>
                                          handleChangeEdit(item.id)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Edit2
                                          size="22"
                                          color="#565e64"
                                          variant="Outline"
                                        />
                                      </span>
                                    )}
                                    {role_function?.Attendance
                                      .Delete_Attendance_Detail &&
                                      !item.shift?.name && (
                                        <span
                                          onClick={() => {
                                            handleChangeDelete(item.id);
                                            // console.log(item.shift?.name);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <Trash
                                            size="22"
                                            color="#ee2128"
                                            variant="Outline"
                                          />
                                        </span>
                                      )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            {NODATAFOUND}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    className="pagination-table-bg"
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={rows?.length > 0 ? rows?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Box>
            </div>
          </div>
        ) : (
          <div className="tasks_container">
            <div className="header-bar">
              <div>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                  {TBLHEAD}
                </Typography>
              </div>

              <div className="header-toolbar">
                <TextField
                  disabled={clickedBtn === "edit" ? true : false}
                  size="small"
                  className="date-input"
                  id="outlined"
                  type="date"
                  defaultValue={startDate}
                  onChange={handleChangeDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: moment(date).format("YYYY-MM-DD"),
                  }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="end">
                  //       <Calendar size="15" color="black" />
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
                <FormControl
                  size="small"
                  className="shift-btn"
                  style={{
                    display: clickedBtn === "edit" ? "none" : "block",
                  }}
                >
                  <Select
                    isDisabled={clickedBtn === "edit" ? true : false}
                    defaultValue={selectGroupList.value}
                    placeholder={"Select Group"}
                    isMulti
                    name="colors"
                    options={masterGrp}
                    className="basic-multi-select"
                    classNamePrefix="Select groups"
                    onChange={handleChangeGroups}
                  />
                </FormControl>

                <FormControl size="small" className="shift-btn">
                  <Select
                    className="basic-single"
                    classNamePrefix="Select shift"
                    placeholder={"Select Shift"}
                    isSearchable={true}
                    name="color"
                    options={masterShft}
                    onChange={handleChangeShift}
                    isDisabled={clickedBtn === "edit" ? true : false}
                    value={selectShifts}
                  />
                </FormControl>
                <div>
                  <Button
                    // disabled={disable}
                    variant="contained"
                    className="attendance-btn"
                    onClick={handleSaveAttendance}
                  >
                    {clickedBtn === "add" ? ADDBTN : UPDATEBTN}
                  </Button>
                </div>

                <div>
                  <Button className="back-btn" onClick={handleClickTable}>
                    <ArrowLeft size="22" color="#484848" variant="Outline" />
                  </Button>
                </div>
              </div>
            </div>

            <span className="error_msg">{errorMessage}</span>
            <div className="tasks-cont_body">
              <div
                className="p-2 mb-3"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h6">{LABELPRESENT}</Typography>

                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                    boxShadow: "none",
                  }}
                  data-testid="dustbin"
                  component="ul"
                >
                  <div
                    id="todo_div"
                    className="tasks-body_child"
                    onDragOver={dragOver}
                    onDropCapture={dragDrop}
                  >
                    <div className="tasks-child_status">2</div>
                    {presentListData &&
                      presentListData?.map((todo: any) => {
                        return (
                          <div
                            key={todo.key}
                            className="tasks-bd_content m-1"
                            draggable={true}
                            onDragStart={dragStart}
                            onDragEnd={dragEnd}
                            id={todo.id}
                          >
                            <AttendanceChip
                              style={{ background: "#519C66", color: "#fff" }}
                              id={todo.id}
                              label={todo.name}
                            />
                          </div>
                        );
                      })}
                  </div>
                </Paper>
                <div style={{ margin: "5px" }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    // defaultValue={null}
                    closeMenuOnSelect={false}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={0}
                    options={
                      clickedBtn === "add" ? absentAndSpecialabsent : initStaffs
                    }
                    onChange={handleChangePresent}
                  />
                </div>
              </div>

              <div
                className="p-2 mb-3"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h6">{LABELABSENT}</Typography>

                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                    boxShadow: "none",
                  }}
                  data-testid="dustbin"
                  component="ul"
                >
                  <div
                    id="inProgress_div"
                    className="tasks-body_child "
                    onDragOver={dragOver}
                    onDropCapture={dragDrop}
                  >
                    <div className="tasks-bd_title">
                      <div className="tasks-child_status">1</div>
                    </div>
                    {absentListdata &&
                      absentListdata?.map((inprogress: any) => {
                        return (
                          <div
                            key={inprogress.id}
                            className="tasks-bd_content m-1"
                            draggable={true}
                            onDragStart={dragStart}
                            onDragEnd={dragEnd}
                            id={inprogress.id && inprogress.id}
                          >
                            <AttendanceChip
                              style={{ background: "#CC5F5F", color: "#fff" }}
                              id={inprogress.id}
                              label={inprogress.name}
                            />
                          </div>
                        );
                      })}
                  </div>
                </Paper>
                <div style={{ margin: "5px" }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={null}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    closeMenuOnSelect={false}
                    options={
                      clickedBtn === "add"
                        ? presentandspecialabesnt
                        : initStaffs
                    }
                    value={0}
                    onChange={handleChangeAbsent}
                  />
                </div>
              </div>
              <div
                className="p-2 mb-3"
                style={{
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h6">{LABELSPECIALABSENT}</Typography>

                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                    boxShadow: "none",
                  }}
                  data-testid="dustbin"
                  component="ul"
                >
                  <div
                    id="done_div"
                    className="tasks-body_child"
                    onDragOver={dragOver}
                    onDropCapture={dragDrop}
                  >
                    <div className="tasks-bd_title">
                      <div className="tasks-child_status">5</div>
                    </div>
                    {specialAbsentListdata &&
                      specialAbsentListdata?.map((done: any) => {
                        return (
                          <div
                            key={done.id}
                            className="tasks-bd_content m-1"
                            draggable={true}
                            onDragStart={dragStart}
                            onDragEnd={dragEnd}
                            id={done.id}
                          >
                            <AttendanceChip
                              style={{ background: "#FFCC91", color: "#fff" }}
                              id={done.id}
                              label={done.name}
                            />
                          </div>
                        );
                      })}
                  </div>
                </Paper>
                <div style={{ margin: "5px" }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={null}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    value={0}
                    name="color"
                    options={
                      clickedBtn === "add" ? presentandabesnt : initStaffs
                    }
                    onChange={handleChangeSplAbsent}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Attendance;
