import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";

import {
  Button,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  tableCellClasses,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Loader from "components/common/Loader/Loader";
import moment from "moment";

import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { SummaryReportsProps } from "../container/summaryContainer";
import { ReportsRes, SummaryReport } from "../services/types";

const paths = [
  {
    name: "Home",
    path: "",
  },
  {
    name: "Summary Reports",
  },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#434343",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff ",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function SummaryReports(props: SummaryReportsProps) {
  const {
    SummaryReportRequest,
    error_summaryReport,
    loading_summaryReport,
    success_summaryReport,
    summaryReportDetails,
    Reset,
  } = props;
  const date = new Date();
  const dispatch = useDispatch();

  const currentDate = moment();
  const [screen, setScreen] = useState(1);
  const [allData, setAllData] = useState<ReportsRes | null>(
    summaryReportDetails
  );
  // const [selectGroup, setSelectGroup] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(date).subtract(1, "month").format("YYYY-MM-DD")
  );
  const maxSelectableMonth = currentDate.subtract(1, "month");
  const handleChangeDate = (value: any) => {
    setStartDate(value);
    console.log(value);
  };
  const handleReportGenerate = useCallback(() => {
    const date = new Date(startDate);
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    SummaryReportRequest({ month: month, year: year });
  }, [SummaryReportRequest, startDate]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    Reset();
    setStartDate(moment(date).subtract(1, "month").format("YYYY-MM-DD"));
    setScreen(1);
  };

  useEffect(() => {
    const isLoading = loading_summaryReport;
    setLoader(isLoading);
    if (success_summaryReport) {
      setScreen(2);
      setAllData(summaryReportDetails);
      // const pdfUrl = `${BASE_URL}/${summaryReportDetails?.Pdf}`;
      const pdfUrl = `${summaryReportDetails?.Pdf}`;
      // Create a temporary link element
      const link: any = document.createElement("a");
      link.href = summaryReportDetails && pdfUrl;
      link.target = "_blank"; // Open the PDF in a new tab/window

      // // Trigger the download
      // selectGroup === "All" && link.click();
      // selectGroup === "All" ? setScreen(1) : setScreen(2);
    }
  }, [success_summaryReport, loading_summaryReport, summaryReportDetails]);
  return (
    <>
      {loading_summaryReport && <Loader />}
      <Breadcrumb pathList={paths} />
      <div className="">
        {screen === 1 ? (
          <>
            <div className="generate_paper">
              <ToastContainer />

              <div className="form_div_generate">
                <FormControl size="small" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="MM/yyyy"
                      views={["year", "month"]}
                      label="Year and Month"
                      maxDate={maxSelectableMonth.toDate()}
                      value={startDate}
                      onChange={handleChangeDate}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>

              <div className="form_btn_generate">
                <div className="">
                  <Button
                    variant="contained"
                    className="report-btn"
                    onClick={handleReportGenerate}
                    disabled={loading_summaryReport}
                  >
                    {loading_summaryReport
                      ? "Generating..."
                      : "Generate Report"}
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-100">
              <div className="d-flex justify-content-between mb-2">
                <div>{"Reports"}</div>
                <div>
                  <a target="_blank" href={`${allData?.Pdf}`}>
                    <button className="CSVdownload">{"Download PDF"}</button>
                  </a>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleClose}
                  >
                    {"Close"}
                  </button>
                </div>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table aria-label="customized table table-striped">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">
                          {"SL.No"}
                        </StyledTableCell> */}
                        <StyledTableCell align="left">
                          {"Month"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Shift"}
                        </StyledTableCell>
                        <StyledTableCell align="left">{"DTW"}</StyledTableCell>
                        <StyledTableCell align="left">
                          {"Stack"}
                        </StyledTableCell>
                        <StyledTableCell align="left">{"STW"}</StyledTableCell>
                        <StyledTableCell align="left">
                          {"Restack"}
                        </StyledTableCell>
                        <StyledTableCell align="left">{"STT"}</StyledTableCell>
                        <StyledTableCell align="left">
                          {"Extra Bag"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 1"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 2"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 3"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 4"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 5"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 6"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {"Group 7"}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allData?.summaryReport.length !== 0 ? (
                        allData?.summaryReport
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row: SummaryReport, index: number) => (
                            <StyledTableRow>
                              {/* <StyledTableCell align="left">
                                {index + 1}
                              </StyledTableCell> */}
                              <StyledTableCell align="left">
                                {row.monthDate ? row.monthDate : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.shiftName ? row.shiftName : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_DTW ? row.Sum_DTW : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_STACK ? row.Sum_STACK : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_STW ? row.Sum_STW : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_RESTACK ? row.Sum_RESTACK : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_STT ? row.Sum_STT : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.Sum_ExtraBag ? row.Sum_ExtraBag : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_1 ? row.group_1 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_2 ? row.group_2 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_3 ? row.group_3 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_4 ? row.group_4 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_5 ? row.group_5 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_6 ? row.group_6 : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.group_7 ? row.group_7 : "N/A"}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                      ) : (
                        <StyledTableCell
                          className="NoRecordFound"
                          align="center"
                          colSpan={12}
                        >
                          {"No Data Found"}
                        </StyledTableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <div className="tpagination">
                    <TablePagination
                      sx={{ width: "100%" }}
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={10}
                      count={allData ? allData?.summaryReport.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </TableContainer>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
