import {
  fetchAllGroupShift,
  fetchAllGroups,
} from "pages/app/attendance/service/attendanceSlice";
import {
  AllStaffDetailsDTO,
  GETGROUPSHIFTDTO,
  GetAllGroupResponse,
  GetAllStaffRequestDTO,
} from "pages/app/attendance/service/types";
import { RoleFunDTO } from "pages/auth/signin/service/types";
import { getRole } from "pages/auth/signin/service/userSlice";
import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Worklog from "../component/worklog";
import {
  CREATEUPDATEALLWOKLOGPARAMDTO,
  FETCHWORKLOGPARAMSDTO,
  SHIFTBODYDTO,
  STAFFBODYDTO,
  ShiftListDTO,
  allShiftListDTO,
  worklogListDTO,
} from "../service/types";
import {
  fetchAllOperation,
  fetchAllShift,
  fetchAllStaff,
  fetchCreateDraftWorklog,
  fetchCreateWorklog,
  fetchUpdateStatusWorklog,
  fetchUpdateWorklog,
  fetchWorklogDetailsById,
  fetchWorklogShift,
  fetchWorklogStaff,
  fetchWorklogs,
  worklogDelete,
} from "../service/worklogSlice";

interface StateProps {
  loading_getShift: boolean;
  success_getShift: boolean;
  error_getShift: string | boolean | undefined;
  loading_getAllShift: boolean;
  success_getAllShift: boolean;
  error_getAllShift: string | boolean | undefined;
  loading_getStaff: boolean;
  success_getStaff: boolean;
  error_getStaff: string | boolean | undefined;
  loading_getOperation: boolean;
  success_getOperation: boolean;
  error_getOperation: string | boolean | undefined;
  loading_getWorklog: boolean;
  success_getWorklog: boolean;
  error_getWorklog: string | boolean | undefined;
  loading_createtWorklog: boolean;
  success_createtWorklog: boolean;
  error_createtWorklog: string | boolean | undefined;
  loading_createtDraftWorklog: boolean;
  success_createtDraftWorklog: boolean;
  error_createtDraftWorklog: string | boolean | undefined;
  loading_updatetWorklog: boolean;
  success_updatetWorklog: boolean;
  error_updatetWorklog: string | boolean | undefined;
  loading_updatetStatusWorklog: boolean;
  success_updatetStatusWorklog: boolean;
  error_updatetStatusWorklog: string | boolean | undefined;

  loading_getAttendanceReport: boolean;
  success_getAttendanceReport: boolean;
  error_getAttendanceReport: string | boolean | undefined;

  loading_getGroupShift: boolean;
  success_getGroupShift: boolean;
  error_getGroupShift: string | boolean | undefined;
  allGroupShifts: GetAllGroupResponse;
  worklogList: worklogListDTO[];
  shiftList: ShiftListDTO[];
  operationList: any;
  allShiftList: allShiftListDTO[];
  role_function: RoleFunDTO | undefined;
  allStaffList: AllStaffDetailsDTO;
  worklogDetailsById: any;
  loading_worklogDelete: boolean;
  success_worklogDelete: boolean;
  error_worklogDelete: boolean;
}
interface DispatchProps {
  getShiftByDate(body: SHIFTBODYDTO): void;
  getAllStaffByShift(body: STAFFBODYDTO): void;
  getAllOperation(): void;
  getAllShift(): void;
  getWorklogs(data: FETCHWORKLOGPARAMSDTO): void;
  createAllWorklog(data: CREATEUPDATEALLWOKLOGPARAMDTO): void;
  createDraftAllWorklog(data: CREATEUPDATEALLWOKLOGPARAMDTO): void;
  updateAllWorklog(data: CREATEUPDATEALLWOKLOGPARAMDTO): void;
  updateStatusWorklog(id: number): void;
  getRoleFuncn(): void;
  getAllGroups(): void;
  getAllGroupsShiftByDt(value: GETGROUPSHIFTDTO): void;
  getAllStaff(id: GetAllStaffRequestDTO): void;
  getWorklogsById(id: number): void;
  deleteWorklogsById(id: number): void;
}
const mapStateToProps = (state: RootState): StateProps => {
  return {
    loading_getShift: state.worklog.loading_getShift,
    success_getShift: state.worklog.success_getShift,
    error_getShift: state.worklog.loading_getShift,
    loading_getAllShift: state.worklog.loading_getAllShift,
    success_getAllShift: state.worklog.success_getAllShift,
    error_getAllShift: state.worklog.error_getAllShift,
    loading_getStaff: state.worklog.loading_getStaff,
    success_getStaff: state.worklog.success_getStaff,
    error_getStaff: state.worklog.error_getStaff,
    loading_getOperation: state.worklog.loading_getOperation,
    success_getOperation: state.worklog.success_getOperation,
    error_getOperation: state.worklog.error_getOperation,
    loading_getWorklog: state.worklog.loading_getWorklog,
    success_getWorklog: state.worklog.success_getWorklog,
    error_getWorklog: state.worklog.error_getWorklog,
    loading_createtWorklog: state.worklog.loading_createtWorklog,
    success_createtWorklog: state.worklog.success_createtWorklog,
    error_createtWorklog: state.worklog.error_createtWorklog,
    loading_createtDraftWorklog: state.worklog.loading_createtDraftWorklog,
    success_createtDraftWorklog: state.worklog.success_createtDraftWorklog,
    error_createtDraftWorklog: state.worklog.error_createtDraftWorklog,
    loading_updatetWorklog: state.worklog.loading_updatetWorklog,
    success_updatetWorklog: state.worklog.success_updatetWorklog,
    error_updatetWorklog: state.worklog.error_updatetWorklog,

    loading_getGroupShift: state.attendance.loading_getGroupShift,
    error_getGroupShift: state.attendance.error_getGroupShift,
    success_getGroupShift: state.attendance.success_getGroupShift,

    loading_updatetStatusWorklog: state.worklog.loading_updatetStatusWorklog,
    success_updatetStatusWorklog: state.worklog.success_updatetStatusWorklog,
    error_updatetStatusWorklog: state.worklog.error_updatetStatusWorklog,

    loading_getAttendanceReport: state.worklog.loading_getAttendanceReport,
    success_getAttendanceReport: state.worklog.success_getAttendanceReport,
    error_getAttendanceReport: state.worklog.error_getAttendanceReport,

    worklogList: state.worklog.worklogList,
    shiftList: state.worklog.shiftList,
    operationList: state.worklog.operationList,
    allShiftList: state.worklog.allShiftList,
    role_function: state.userLogin.role_function,
    allGroupShifts: state.attendance.allGroupShifts,
    allStaffList: state.worklog.allStaffList,
    worklogDetailsById: state.worklog.worklogDetailsById,
    loading_worklogDelete: state.worklog.loading_worklogDelete,
    success_worklogDelete: state.worklog.success_worklogDelete,
    error_worklogDelete: state.worklog.error_worklogDelete,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    getShiftByDate: (body) => {
      dispatch(fetchWorklogShift(body));
    },
    getAllShift: () => {
      dispatch(fetchAllShift());
    },
    getAllStaffByShift: (body) => {
      dispatch(fetchWorklogStaff(body));
    },
    getAllOperation: () => {
      dispatch(fetchAllOperation());
    },
    createAllWorklog: (data) => {
      dispatch(fetchCreateWorklog(data));
    },
    createDraftAllWorklog: (data) => {
      dispatch(fetchCreateDraftWorklog(data));
    },
    updateAllWorklog: (data) => {
      dispatch(fetchUpdateWorklog(data));
    },
    updateStatusWorklog: (id) => {
      dispatch(fetchUpdateStatusWorklog(id));
    },
    getWorklogs: (data) => {
      dispatch(fetchWorklogs(data));
    },
    getRoleFuncn: () => {
      dispatch(getRole());
    },
    getAllGroups: () => {
      dispatch(fetchAllGroups());
    },
    getAllStaff: (id) => {
      dispatch(fetchAllStaff(id));
    },
    getAllGroupsShiftByDt: (value) => {
      dispatch(fetchAllGroupShift(value));
    },
    getWorklogsById: (id: any) => {
      dispatch(fetchWorklogDetailsById(id));
    },
    deleteWorklogsById(id) {
      dispatch(worklogDelete(id));
    },
  };
};
export interface WorklogProps extends StateProps, DispatchProps {}
export const WorklogComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Worklog);
