import strings from "translation";

export const ADDNEWLABEL = "Add New Material";
export const UPDATELABEL = "Update Material";

export const NAMEPLACEHOLDER = "Enter Material Name";
export const NAMELABEL = "Material Name";
export const NAMEERROR = "Special characters are not allowed";

export const CODEPLACEHOLDER = "Enter Material Code";
export const CODELABEL = "Material Code";
export const CODEERROR = "Special characters are not allowed";

export const RATEPLACEHOLDER = "Enter BCNHL Bonus Rate";
export const RATELABEL = "BCNHL Bonus Rate";
export const RATEERROR = "Special characters and alphabets are not allowed";

export const DATEPLACEHOLDER = strings["FORM.MATERIAL.DATEPLACEHOLDER"];
export const DATELABEL = strings["FORM.MATERIAL.DATELABEL"];
export const DATEERROR = strings["FORM.MATERIAL.DATEERROR"];

export const SAVEBTN = "Save";
export const CANCELBTN = "Cancel";
export const UPDATEBTN = "Update"