import { connect } from 'react-redux';
import { RootState, TypedDispatch } from '../../../../redux/store';
import BankReport from '../component/bankReport';
import { fetchBankReports, fetchAllBanks, fetchAllGroups } from '../service/bankReportSlice';
import { GETBANKDT } from 'pages/app/bank/service/types';
import { getRole } from 'pages/auth/signin/service/userSlice';
import { RoleFunDTO } from 'pages/auth/signin/service/types';
import { BankListResponseDTO, BankReportDTO, BankReportResponseDTO } from '../service/types';

interface StateProps {
    loading_getGroup: boolean;
    success_getGroup: boolean;
    error_getGroup: string | boolean | undefined;

    error_getBank: string | boolean | undefined;
    loading_getBank: boolean;
    success_getBank: boolean;
    error_getBankReport: string | boolean | undefined;
    loading_getBankReport: boolean;
    success_getBankReport: boolean;
    banksList:  BankListResponseDTO;
    role_function: RoleFunDTO | undefined
    bankReportList?: any;
    
}

interface DispatchProps {
    getAllGroup(): void;
    getAllBanks(): void;
    getAllBankReport(body: any): void;
    getRoleFuncn(): void
}

const mapStateToProps = (state: any): StateProps => {
    return {
        error_getGroup: state.banks.error_getGroup,
        loading_getGroup: state.banks.loading_getGroup,
        success_getGroup: state.banks.success_getGroup,
    error_getBank: state.banks.error_getBank,
    loading_getBank: state.banks.loading_getBank,
    success_getBank: state.banks.success_getBank,
    banksList: state.banks.banksList,
    error_getBankReport: state.banksreports.error_getBankReport,
    loading_getBankReport: state.banksreports.loading_getBankReport,
    success_getBankReport: state.banksreports.success_getBankReport,

        bankReportList: state.banksreports.bankReportList,
        role_function: state.userLogin.role_function
    };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
    return {
        getAllGroup: () => {
            dispatch(fetchAllGroups());
        },
        getAllBanks: () => {
            dispatch(fetchAllBanks());
        },
        getAllBankReport: (data) => {
            dispatch(fetchBankReports(data));
        },
        getRoleFuncn: () => {
            dispatch(getRole());
          }
    };
};

export interface BankReportProps extends StateProps, DispatchProps {}
export const BankReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BankReport);
