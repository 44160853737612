import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { FocusEvent, useState } from "react";
import { ERR_BLANK, ERR_EMAIL } from "./textFieldString";
import { InputProps } from "./types";

export interface ISubmitResult {
  value: string | number;
  error?: boolean;
  success?: boolean;
}

const FormTextField = (props: InputProps) => {
  const {
    type,
    label,
    placeholder,
    Value,
    onChangeText,
    Required,
    CustomErrorLine,
    hidden,
    isError,
    multiline,
    Ctype
  } = props;

  const [name, setName] = useState<ISubmitResult>({
    value: Value ? Value : "",
    error: false,
    success: false,
  });
  const [error, setError] = useState("");

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    const data = text.trimStart();
    if (Required) {
      if (type === "email") {
        const emailTest =
          /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+(?:.[a-zA-Z0-9-]+)*$/;
        if (data.length > 0) {
          if (!emailTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "text") {
        const textTest = /^[a-zA-Z ]*$/gm;
        if (data.length > 0) {
          if (!textTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "bank_name") {
        const bankName = /^[a-zA-Z, ]{0,50}$/gm;
        if (data.length > 0) {
          if (!bankName.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "onlyAlphaMax_50") {
        const onlyAlphaMax_50 = /^[a-zA-Z ]{0,50}$/gm;
        if (data.length > 0) {
          if (!onlyAlphaMax_50.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
        
      else if (type === "userId") {
        const userId = /^[a-zA-Z0-9_]{4,}$/gm;
        if (data.length > 0) {
          if (!userId.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "textarea") {
        if (data.length > 0) {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "alpha") {
        const alphaTest = /^[a-zA-Z0-9 ]*$/gm;
        if (data.length > 0) {
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "IFSC") {
        const IFSC = /^[a-zA-Z0-9]{11}$/gm;
        if (data.length > 0) {
          if (!IFSC.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "aadharno") {
        const aadharno = /^[0-9]{12}$/gm;
        if (data.length > 0) {
          if (!aadharno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "uanno") {
        const uanno = /^[0-9]{12,14}$/gm;
        if (data.length > 0) {
          if (!uanno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "AcNo") {
        const AcNo = /^[0-9]{9,17}$/gm;
        if (data.length > 0) {
          if (!AcNo.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "price") {
        const price =/^[-+]?(\d+(\.\d*)?|\.\d+)$/;
        if (data.length > 0) {
          if (!price.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      
      else if (type === "num") {
        const alphaTest = /^[0-9]*$/gm;
        if (data.length > 0) {
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "percentage") {
        const alphaTest = /^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$/gm;
        if (data.length > 0) {
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "date" || "Edate") {
        if (data) {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "website") {
        const websiteRegex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        if (data.length > 0) {
          if (!websiteRegex.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
      
      
      else if (type === "unionno") {
        const unionno = /^[a-zA-Z0-9]{0,20}$/gm
        if (data.length > 0) {
          if (!unionno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError(ERR_BLANK);
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        }
      }
    } else if (!Required) {
      if (type === "email") {
        if (data.length > 0) {
          const emailTest =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+(?:.[a-zA-Z0-9-]+)*$/;
          if (!emailTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "text") {
        if (data.length > 0) {
          const textTest = /^[a-zA-Z ]*$/gm;
          if (!textTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
        
      else if (type === "bank_name") {
        if (data.length > 0) {
          const bankName = /^[a-zA-Z, ]{0,50}$/gm;
          if (!bankName.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "onlyAlphaMax_50") {
        if (data.length > 0) {
          const onlyAlphaMax_50 = /^[a-zA-Z ]{0,50}$/gm;
          if (!onlyAlphaMax_50.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      
      else if (type === "userId") {
        if (data.length > 0) {
          const userId = /^[a-zA-Z0-9_]{4,}$/gm;
          if (!userId.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
       else if (type === "textarea") {
        if (data.length > 0) {
          const textRegex = /[\w[\]`!@#$%&*()={}:;<>+'-]*/gm;
          if (!textRegex.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "alpha") {
        if (data.length > 0) {
          const alphaTest = /^[a-zA-Z0-9 ]*$/gm;
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      
      else if (type === "IFSC") {
        if (data.length > 0) {
          const IFSC = /^[a-zA-Z0-9]{11}$/gm;
          if (!IFSC.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
        
      else if (type === "aadharno") {
        if (data.length > 0) {
          const aadharno = /^[0-9]{12}$/gm;
          if (!aadharno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "uanno") {
        if (data.length > 0) {
          const uanno = /^[0-9]{12,14}$/gm;
          if (!uanno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "AcNo") {
        if (data.length > 0) {
          const AcNo = /^[0-9]{9,17}$/gm;
          if (!AcNo.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "price") {
        if (data.length > 0) {
          const price =/^[-+]?(\d+(\.\d*)?|\.\d+)$/;
          if (!price.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
      else if (type === "num") {
        if (data.length > 0) {
          const alphaTest = /^[0-9]*$/gm;
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "percentage") {
        if (data.length > 0) {
          const alphaTest = /^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$/gm;
          if (!alphaTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }  else if (type === "date" || "Edate") {
        if (data) {
          const dateTest = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/gm;
          if (!dateTest.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "website") {
        if (data.length > 0) {
          const websiteRegex =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
          if (!websiteRegex.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
        
     
      else if (type === "unionno") {
        if (data.length > 0) {
          const unionno = /^[a-zA-Z0-9]{0,20}$/gm
          if (!unionno.test(String(data).toLowerCase())) {
            const value = {
              value: data,
              success: false,
            };
            onChangeText(value);
            setName(value);
          } else {
            setError("");
            const value = {
              value: data,
              success: true,
            };
            onChangeText(value);
            setName(value);
          }
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
    }
  };

  const handleBlurText = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const text = e.target.value;
    const data = text.trimStart();
    if (Required) {
      if (type === "email") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const emailTest =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+(?:.[a-zA-Z0-9-]+)*$/;
          if (!emailTest.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      } else if (type === "text") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const textSRegex = /^[a-zA-Z ]*$/gm;
          if (!textSRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "bank_name") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const bankName = /^[a-zA-Z, ]{0,50}$/gm;
          if (!bankName.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "onlyAlphaMax_50") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const onlyAlphaMax_50 = /^[a-zA-Z ]{0,50}$/gm;
          if (!onlyAlphaMax_50.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
        
      else if (type === "userId") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const userId = /^[a-zA-Z0-9_]{4,}$/gm;
          if (!userId.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
        
      else if (type === "textarea") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const textSRegex = /[\w[\]`!@#$%&*()={}:;<>+'-]*/gm;
          if (!textSRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      } else if (type === "alpha") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const alphaRegex = /^[a-zA-Z0-9 ]*$/gm;
          if (!alphaRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "IFSC") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const IFSC = /^[a-zA-Z0-9]{11}$/gm;
          if (!IFSC.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "aadharno") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const aadharno = /^[0-9]{12}$/gm;
          if (!aadharno.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "uanno") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const uanno = /^[0-9]{12,14}$/gm;
          if (!uanno.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "AcNo") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const AcNo = /^[0-9]{9,17}$/gm;
          if (!AcNo.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
      else if (type === "price") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const price =/^[-+]?(\d+(\.\d*)?|\.\d+)$/;
          if (!price.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      } 
      else if (type === "num") {


        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const alphaRegex = /^[0-9 ]*$/gm;
          if (!alphaRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      } else if (type === "percentage") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const alphaRegex = /^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$/gm;
          if (!alphaRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : "Error");
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }  else if (type === "date" || "Edate") {
        if (!data) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "website") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const websiteRegex =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
          if (!websiteRegex.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      } 
      
      
      else if (type === "unionno") {
        if (data.length <= 0) {
          setError(ERR_BLANK);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          const unionno = /^[a-zA-Z0-9]{0,20}$/gm
          if (!unionno.test(String(data).toLowerCase())) {
            setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
            const value = {
              ...name,
              error: true,
              success: false,
            };
            setName(value);
            onChangeText(value);
          } else {
            setError("");
            const value = {
              ...name,
              error: false,
              success: true,
            };
            setName(value);
            onChangeText(value);
          }
        }
      }
    } else if (!Required && data.length > 0) {
      if (type === "email") {
        const emailTest =
          /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+(?:.[a-zA-Z0-9-]+)*$/;
        if (!emailTest.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "text") {
        const textSRegex = /^[a-zA-Z ]*$/gm;
        if (!textSRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
      else if (type === "bank_name") {
        const bankName = /^[a-zA-Z, ]{0,50}$/gm;
        if (!bankName.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
      else if (type === "onlyAlphaMax_50") {
        const onlyAlphaMax_50 = /^[a-zA-Z ]{0,50}$/gm;
        if (!onlyAlphaMax_50.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
      
      else if (type === "userId") {
        const userId = /^[a-zA-Z0-9_]{4,}$/gm;
        if (!userId.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
        
      else if (type === "textarea") {
        const textSRegex = /[\w[\]`!@#$%&*()={}:;<>+'-]*/gm;
        if (!textSRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "alpha") {
        const alphaRegex = /^[a-zA-Z0-9 ]*$/gm;
        if (!alphaRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
      else if (type === "IFSC") {
        const IFSC = /^[a-zA-Z0-9]{11}$/gm;
        if (!IFSC.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
      else if (type === "aadharno") {
        const aadharno = /^[0-9]{12}$/gm;
        if (!aadharno.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
        
      else if (type === "uanno") {
        const uanno = /^[0-9]{12,14}$/gm;
        if (!uanno.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
        
      else if (type === "AcNo") {
        const AcNo = /^[0-9]{9,17}$/gm;
        if (!AcNo.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
        
      else if (type === "price") {
        const price =/^[-+]?(\d+(\.\d*)?|\.\d+)$/;
        if (!price.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
        
      else if (type === "num") {
        const alphaRegex = /^[0-9 ]*$/gm;
        if (!alphaRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "percentage") {
        const alphaRegex = /^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$/gm;
        if (!alphaRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "date" || "Edate") {
        const dateTest = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/gm;
        if (!dateTest.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : "Error");
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } else if (type === "website") {
        const websiteRegex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        if (!websiteRegex.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      } 
      
    else if (type === "unionno") {
        const unionno = /^[a-zA-Z0-9]{0,20}$/gm
        if (!unionno.test(String(data).toLowerCase())) {
          setError(CustomErrorLine ? CustomErrorLine : ERR_EMAIL);
          const value = {
            ...name,
            error: true,
            success: false,
          };
          setName(value);
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          setName(value);
          onChangeText(value);
        }
      }
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      {type === "Edate" ? (
        <TextField
        hidden={hidden}
          fullWidth
          id="date"
          label={label}
          type="date"
          value={Value ? Value : name.value}
          inputProps={{ min: "2023-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          required={Required}
          onChange={handleChangeText}
          onBlur={(e) => {
            handleBlurText(e);
          }}
          helperText={
            error && (
              <span className="d-block text-end" style={{ color: "#FF0000" }}>
                {error}
              </span>
            )
          }
          size="medium"
        />
      ) : type === "date" ? (
          <TextField
          hidden={hidden}
          fullWidth
          id="date"
          label={label}
          type="date"
          value={Value ? Value : name.value}
          InputLabelProps={{
            shrink: true,
          }}
          required={Required}
          onChange={handleChangeText}
          onBlur={(e) => {
            handleBlurText(e);
          }}
          helperText={
            error && (
              <span className="d-block text-end" style={{ color: "#FF0000" }}>
                {error}
              </span>
            )
          }
          size="medium"
        />
      ) : Ctype ?(
        <TextField
          hidden={hidden}
          fullWidth
          label={label}
          id="fullWidth"
          placeholder={placeholder}
          type={type}
          required={Required}
          value={Value ? Value : name.value}
          onChange={handleChangeText}
          // onBlur={(e) => {
          //   handleBlurText(e);
          // }}
          helperText={
            error &&
            isError && (
              <span className="d-block text-end" style={{ color: "#FF0000" }}>
                {error}
              </span>
            )
          }
          size="medium"
        />
      ) :
      (
        <TextField
          hidden={hidden}
          fullWidth
          label={label}
          id="fullWidth"
          placeholder={placeholder}
              type={type}
              multiline={multiline}
          required={Required}
          value={Value ? Value : name.value}
          onChange={handleChangeText}
          onBlur={(e) => {
            handleBlurText(e);
          }}
         error={Boolean(error)}
        helperText={error}
          size="medium"
        />
      )}
    </Box>
  );
};

export default FormTextField;
