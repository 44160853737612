import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select as SelectDropdown,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Loader from "components/common/Loader/Loader";
import moment from "moment";
import { NODATA } from "pages/app/attendanceReport/container/attendanceReportString";
import {
  DNLDPDF,
  GENERATE,
} from "pages/app/epfReport/container/epfReportString";
import { GROUPSTATEDT } from "pages/app/staff/service/types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { CURRENTPATH, HOMEPATH } from "../container/wageSlipString";
import { wageSlipProps } from "../container/wageslipContainer";
import "./styles.scss";

const paths = [
  {
    name: HOMEPATH,
    path: "",
  },
  {
    name: CURRENTPATH,
  },
];

const WageSlip = (props: wageSlipProps) => {
  const {
    getAllGroup,
    allWageReport,
    getAllWageSlip,
    loading_getWageReport,
    success_getWageReport,
    getAllOperation,
    getRoleFuncn,
    error_getWageReport,
  } = props;

  const date = new Date();
  const [screen, setScreen] = useState(1);
  const [startDate, setStartDate] = useState(
    moment(date).subtract(1, "month").format("YYYY-MM-DD")
  );
  const [selectGroup, setSelectGroup] = useState("");
  const [selectStaff, setSelectStaff] = useState("");
  const [allData, setAllData] = useState<any>(allWageReport);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoader, setLoader] = useState(false);
  const handleChangeDate = (value: any) => {
    setStartDate(value);
  };

  useEffect(() => {
    getAllGroup();
  }, []);

  useEffect(() => {
    getAllOperation();
  }, []);

  useEffect(() => {
    getRoleFuncn();
  }, []);

  useEffect(() => {
    const isLoading = loading_getWageReport;
    setLoader(isLoading);
    if (success_getWageReport) {
      setAllData(allWageReport);
      // const pdfUrl = `${BASE_URL}/${allWageReport?.Pdf}`;
      const pdfUrl = `${allWageReport?.Pdf}`;
      // Create a temporary link element
      const link: any = document.createElement("a");
      link.href = allWageReport && pdfUrl;
      link.target = "_blank"; // Open the PDF in a new tab/window

      // Trigger the download
      selectGroup === "All" && link.click();
      selectGroup === "All" ? setScreen(1) : setScreen(2);
    }
  }, [loading_getWageReport, success_getWageReport]);

  const groupLists = useSelector(
    (state: GROUPSTATEDT) => state.groups.groupList
  );

  const masterGroups = groupLists?.map((ele) => ({
    id: ele.id,
    value: ele.id,
    label: ele.name,
    is_active: ele.is_active,
  }));

  const filterGroups = masterGroups?.filter((ele) => {
    return ele.is_active === 1;
  });

  const handleReportGenerate = () => {
    const selectYear = moment(startDate).format("YYYY");
    const selectMonth = moment(startDate).format("MM");
    const data = {
      month: selectMonth,
      year: selectYear,
      group_id: selectGroup,
    };

    getAllWageSlip(data);
    // setScreen(2)
  };

  const handleReportGenerateAllReport = () => {
    const selectYear = moment(startDate).format("YYYY");
    const selectMonth = moment(startDate).format("MM");
    const data = {
      month: selectMonth,
      year: selectYear,
      group_id: selectGroup,
    };

    getAllWageSlip(data);
  };

  const handleClose = () => {
    setStartDate(moment(date).subtract(1, "month").format("YYYY-MM-DD"));
    setSelectGroup("");
    setSelectStaff("");
    setScreen(1);
  };
  let reportDisable = selectGroup === "";

  const currentDate = moment();
  const maxSelectableMonth = currentDate.subtract(1, "month");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#434343",
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff ",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
      {isLoader && <Loader />}
      <Breadcrumb pathList={paths} />

      <div>
        {screen === 1 ? (
          <div className="generate_paper">
            <ToastContainer />

            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MM/yyyy"
                    views={["year", "month"]}
                    label="Year and Month"
                    maxDate={maxSelectableMonth.toDate()}
                    value={startDate}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>

            <div className="form_div_generate">
              <FormControl size="small" fullWidth>
                <SelectDropdown
                  fullWidth
                  value={selectGroup}
                  onChange={(event) => setSelectGroup(event.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  renderValue={
                    selectGroup !== "" ? undefined : () => "Select Group"
                  }
                >
                  {filterGroups && (
                    <MenuItem key={Math.random() + 22} value={"All"}>
                      All
                    </MenuItem>
                  )}

                  {filterGroups &&
                    filterGroups.map((ele) => (
                      <MenuItem key={ele.id} value={ele.value}>
                        {ele.label}
                      </MenuItem>
                    ))}
                </SelectDropdown>
              </FormControl>
            </div>
            <div className="form_btn_generate">
              <div className="">
                {selectGroup === "All" ? (
                  // <a target="_blank" href={`${BASE_URL}/${allData.Pdf}`}>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerateAllReport}
                  >
                    {GENERATE}
                  </Button>
                ) : (
                  // </a>
                  <Button
                    variant="contained"
                    className="report-btn"
                    disabled={reportDisable}
                    onClick={handleReportGenerate}
                  >
                    {GENERATE}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="w-100">
            <div className="header-bar">
              <div>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                >
                  Reports
                </Typography>
              </div>
              <div>
                <a target="_blank" href={`${allData.Pdf}`}>
                  <button className="CSVdownload">{DNLDPDF}</button>
                </a>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
            <div className="mb-4">
              <Paper>
                <TableContainer>
                  <Table aria-label="customized table table-striped">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>NAME</StyledTableCell>
                        <StyledTableCell>DUTY</StyledTableCell>
                        <StyledTableCell>OFF DAY</StyledTableCell>
                        <StyledTableCell>PFNHFH DUTY</StyledTableCell>
                        <StyledTableCell>TOTAL DUTY</StyledTableCell>
                        <StyledTableCell>BASIC WAGES</StyledTableCell>
                        <StyledTableCell>D.A @29.96%</StyledTableCell>
                        <StyledTableCell>H.R.A ALLOWANCE</StyledTableCell>
                        <StyledTableCell>CANTEEN ALLOWANCE</StyledTableCell>
                        <StyledTableCell>SHIFT ALLOWANCE</StyledTableCell>
                        <StyledTableCell>NIGHT ALLOWANCE</StyledTableCell>
                        <StyledTableCell>NHFH AMNT</StyledTableCell>
                        <StyledTableCell>EXTRABAG COST</StyledTableCell>
                        <StyledTableCell>TOTAL WAGES</StyledTableCell>
                        <StyledTableCell>E.P.F</StyledTableCell>
                        <StyledTableCell>E.S.I</StyledTableCell>
                        <StyledTableCell>I.T</StyledTableCell>
                        <StyledTableCell>W.ADV.</StyledTableCell>
                        <StyledTableCell>OTHER</StyledTableCell>
                        <StyledTableCell>TOT. DED</StyledTableCell>
                        <StyledTableCell>NET AMOUNT PAYABLE</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tableBodyData">
                      {Object.values(allData?.salaryReport).length ? (
                        Object.values(allData?.salaryReport)
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((key: any, index: number) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="left">
                                  {key.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.presentDays}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.OffEligibleDays}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.holidayWorkCount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.paidDays}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.Basic_AmountTotal}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.DA_AmountTotal}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.HRA_AmountTotal}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.CA_AmountTotal}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.dayShiftAllowance}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.nightShiftAllowance}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.holidayAllowenceTotalAount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.TotalIncenitveAmount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.grossAmount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.epfAmount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.esiAmount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.st}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.essnt}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.st}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.epfAmount + key.esiAmount}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {key.netPayableAmount}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })
                      ) : (
                        <StyledTableCell
                          className="NoRecordFound"
                          align="center"
                          colSpan={10}
                        >
                          {NODATA}
                        </StyledTableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TableContainer>
                <div className="tpagination">
                  <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={10}
                    count={allData.salaryReport.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </TableContainer>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WageSlip;
