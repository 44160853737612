import React, { SyntheticEvent, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { MdClose } from 'react-icons/md';
import { FormProps, FormReviseProps, ISubmitResult } from './types';
import FormTextField from 'components/common/textField/textField';
import { SAVEBTN, UPDATEBTN, PRICEERROR, PRICELABEL, PRICEPLACEHOLDER, NAMEPLACEHOLDER, NAMELABEL, NAMEERROR, CANCELBTN, DATEPLACEHOLDER, DATELABEL, DATEERROR, REVISELABEL } from './shiftFormString';
import "../style.scss"
import moment from 'moment';

const ReviseShiftForm = (props: FormReviseProps) => {
    const {
        initialValue = {},
        // initValAdd,
        handleFormData,
        onHandleClose,
        errorMessage,
        clickedBtn,
        setErrorMessage
    } = props; 

    const [amount, setAmount] = useState<any>({
        value: initialValue.subRows ? initialValue.subRows.upcoming_date[0].amount :0,
        error: false,
        success: false,
    });
    const [effectDate, setEffectDate] = useState<ISubmitResult>({
        value: initialValue.subRows ? initialValue.subRows.upcoming_date[0].effective_from :"",
        error: false,
        success: false,
    });
 
    const [arrearEndDate, setArrearEndDate] = useState<ISubmitResult>({
        value: initialValue.arrear_end_date
          ? moment(initialValue.arrear_end_date).format("YYYY-MM-DD")
          : "",
        error: false,
        success: false,
    });
    
      const [isArriear, setIsArriear] = React.useState<boolean>(
        initialValue ? (initialValue.is_arrear  === 1 ? true : false) : false
    );
    
    const handleChangeAmount = (value: ISubmitResult) => {
        setErrorMessage("");
        setAmount(value)
    }

    const handleChangeEffectDate = (value: ISubmitResult) => {
        setErrorMessage("");
        setEffectDate(value)
    }
    const handleCheckArrear = (
        event: SyntheticEvent<Element, Event>,
        checked: boolean
      ) => {
        setErrorMessage("");
        setIsArriear(checked);
      };
    
    const handleChangeArrearDate = (value: ISubmitResult) => {
        setErrorMessage("");
        setArrearEndDate(value);
      };

    let initarrearEndDate = initialValue.arrear_end_date? initialValue.arrear_end_date : ""
    let initArriear = initialValue?.is_arrear === 1 ? true : false;
    

    let disable =
        amount.error ||
        effectDate.error ||
        amount.value === "" ||
        effectDate.value === "" ||
        (amount.success === false &&
            effectDate.success === false) 

    const handleSubmitForm = () => { 
        const data = {
            id: initialValue.subRows ? initialValue.subRows.upcoming_date[0].id:null,
            amount: parseFloat(amount.value),
            effective_from: moment(effectDate.value).format('YYYY-MM-DD'),
            is_arrear: isArriear,
            arrear_end_date:arrearEndDate.value,
        }
        handleFormData(data)
    }

    return (
        <Card sx={{ boxShadow: "none" }}>
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={onHandleClose}>
                        <MdClose />
                    </IconButton>
                }
                title={REVISELABEL}
            />
            {errorMessage?<span className='error_msg'>{errorMessage}</span>:null}
            <CardContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { mb: 2 },
                    }}
                    className={"formResponsiveHeight"}
                    noValidate={true}
                >
                    {errorMessage?<span className='error_msg'>{errorMessage}</span>:null}
                    <FormTextField
                        type="price"
                        placeholder={PRICEPLACEHOLDER}
                        label={PRICELABEL}
                        Value={amount.value}
                        onChangeText={handleChangeAmount}
                        Required={true}
                        CustomErrorLine={PRICEERROR}
                    />
                    <FormTextField
                        //  hidden={clickedBtn === "edit" ?false: !isArriear }
                        type="Edate"
                        isEffective={true}
                        placeholder={DATEPLACEHOLDER}
                        label={DATELABEL}
                        Value={effectDate.value}
                        onChangeText={handleChangeEffectDate}
                        Required={true}
                        CustomErrorLine={DATEERROR}
                    />
                    {clickedBtn === "edit" ?null: <FormTextField
                        type="Edate"
                        hidden={!isArriear}
                        placeholder={"Arrear End Date"}
                        label={"Arrear End Date"}
                        Value={arrearEndDate.value}
                        onChangeText={handleChangeArrearDate}
                        Required={false}
                        CustomErrorLine={DATEERROR}
                    />}
                    {clickedBtn === "edit" ?null :  <FormControlLabel
                        sx={{ mb: 1 }}
                        value={isArriear}
                        control={<Checkbox checked={isArriear} />}
                        label="Is arrear"
                        labelPlacement="end"
                        onChange={handleCheckArrear}
                    />}
                </Box>
            </CardContent>
            <CardActions className='d-flex justify-content-end'>
                <Button size="large" variant="outlined" onClick={onHandleClose}>{CANCELBTN}</Button>
                <Button size="large" variant="contained" disabled={disable}  onClick={handleSubmitForm}>{SAVEBTN}</Button>
            </CardActions>
            
        </Card>
    );
}

export default ReviseShiftForm;
